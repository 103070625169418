import styled from 'styled-components'
import { Select, Input } from 'antd'

export const ModalHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ChartingEntryFormItem = styled.div`
    padding: 10px 20px;
`

export const Title = styled.div`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
`

export const StyledSelect = styled(Select)`
    width: 100%;
`

export const StyledTextArea = styled(Input.TextArea)`
    height: 100%;
`

export const CompiledChartingEntryContainer = styled.div`
    background-color: #fff;
    color: rgb(0, 0, 0, 0.65);
`

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
`

export const ButtonGroupRight = styled.div`
    display: flex;
    gap: 5px;
`