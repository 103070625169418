import React from 'react'

import {
    ExperimentOutlined,
    EyeOutlined
} from '@ant-design/icons'

import { TelemedicineCategoryEnum } from '../../../../../../../../types/enums'

import { useCaseViewContext } from '../../../../../../context/CaseViewContext'
import { StyledButton } from '../../../../../Button'

export const Labs = ({
    setShowLabOrderModal
}) => {
    const { caseDetails } = useCaseViewContext()
    const activeRequest = caseDetails?.requests?.at(-1)
    const TREATMENT_REQUIRES_LABS = caseDetails?.category.name.toLowerCase() === TelemedicineCategoryEnum.TESTOSTERONE

    if (!TREATMENT_REQUIRES_LABS) return null

    const labsStatus = activeRequest?.status === 'pending' ? 'completed' : activeRequest?.status || 'unknown'

    return (
        <div style={{ 
            width: '100%', 
            padding: '20px',
            marginBottom: '20px', 
            background: 'rgba(204, 125, 203, 0.15)', 
            color: '#000',
            borderRadius: '5px', 
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
        }}
    >
        <div style={{ width: '100%', display: 'flex'}}>
            <div style={{ display: 'flex', width: '100%'}}>
                <div style={{ width: 'calc(100% - 100px)' }}>
                    <strong style={{ color: 'rgba(204, 125, 203, 1)', fontSize: '1.1rem' }}>Metabolic Lab Test</strong>
                </div>
                <div style={{ width: '100px', display: 'flex', justifyContent: 'flex-end', gap: '3px', }}>
                    <StyledButton 
                        onClick={() => setShowLabOrderModal(true)}
                        size='small' 
                        Icon={ExperimentOutlined} 
                        color='rgba(204, 125, 203, 1)'
                        tooltip="Order labs"
                    />
                </div>
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px'}}>
            <span><strong>Description:</strong> Testosterone Replacement Lab Panel</span>
            <span><strong>Status:</strong> {labsStatus}</span>
        </div>
    </div>
    )
}