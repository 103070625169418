import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useTickets } from '../../hooks/useTickets'
import { PhysicianTagEnum } from '../../../../../../../../types/enums'

import prescribeMedicine from '../../../../../../../../redux/prescribeMedicine'

import {
    Modal,
    Typography,
    Button,
    Form,
    message
} from "antd"

import { ReviewPrescriptions } from './components/ReviewPrescriptions';

import { useCaseViewContext } from '../../../../../../context/CaseViewContext'

const { Title } = Typography

export const CompleteConsultationModal = ({
    unTemplatizedPrescriptions
}) => {
    const dispatch = useDispatch();

    const { 
        caseDetails, 
        fetchCaseDetails, 
        provider  
    } = useCaseViewContext()

    const {
        createTickets
    } = useTickets({ caseId: caseDetails?._id, unTemplatizedPrescriptions })

    const [form] = Form.useForm()

    const { 
        prescriptions,
        pharmacy,
        consultationCompleted,
        isNewDefaultPharmacy,
        useTFA,
        providerOTPCode,
        providerPin,
        visitNote,
        readyToComplete,
    } = useSelector(state => state.prescribeMedicine)

    const { physicianDetails } = useSelector(state => state.physicianDetails)

    const wasPrescribed = Object.values(prescriptions || {}).length > 0
    const hasControlledSubstance = Object.values(prescriptions || {}).find(medication => medication.schedule && medication.schedule !== "0")
    
    useEffect(() => {
        if (prescriptions) {
            dispatch(prescribeMedicine.actions.evaluateReadyToComplete(physicianDetails))
        }
        return () => form.resetFields()
    }, [prescriptions])

    const handleSubmit = async () => {
        createTickets()
        const vals = form.getFieldsValue(true)

        const payload = { 
            prescriptions: Object.values(prescriptions),
            visitNote,
            needsReview: provider?.tags?.includes(PhysicianTagEnum.NEEDS_REVIEW),
            isNewDefaultPharmacy,
            pharmacy,
            pin: providerPin,
            useTFA,
            providerOTPCode,
            ...vals
        }

        try {
            await dispatch(prescribeMedicine.actions.completeConsultation(caseDetails._id, payload))
            message.success('Consultation completed successfully.')
        } catch (e) {
            console.log(e)
            // check for auth error here. don't reload. offer provider additional attempt to correct pin or tfa
            message.error(`Error completing consultation: ${e.message}`)
        } finally {
            fetchCaseDetails()
        }
    }

    const handleCancel = () => {
        dispatch(prescribeMedicine.actions.setProviderPin(null))
        dispatch(prescribeMedicine.actions.setConsultationCompleted(false))
    }

    const completionFooter = [
        <Button 
            key="complete" 
            onClick={handleSubmit} 
            type="primary"
            disabled={!readyToComplete}
        >
                Complete Consultation
        </Button>,
    ]

    if (!consultationCompleted) return null

    return (
        <Modal
            title={<Title level={3}>Complete Consultation { wasPrescribed ? 'With' : 'Without'} Prescription{prescriptions?.length > 1 ? 's' : ''}</Title>}
            visible={consultationCompleted}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText='Complete'
            width={900}
            footer={completionFooter}
        >
            <ReviewPrescriptions 
                hasControlledSubstance={hasControlledSubstance}
                form={form}
            />
        </Modal>
    )
}