import React from 'react'
import moment from 'moment-timezone'
import { DeleteOutlined } from '@ant-design/icons'

import { StyledButton } from '../../../../../Button'

import { useDispenseUnitContext } from '../../context/DispenseUniteContext'

export const Prescription = ({
    prescription,
    selectPrescriptionForCancelation
}) => {
    const { getDispenseUnitById } = useDispenseUnitContext()

    const cancelled = ["CancelPending", "Cancel", "Canceled"].includes(prescription.medicationStatus)
    const background = cancelled ? 'rgba(255, 0, 0, .2)' : 'rgb(221, 221, 221)'
    
    return (
        <div style={{
            width: '100%',
            padding: '20px',
            marginBottom: '20px',
            background,
            color: '#000',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <strong>{prescription.name}</strong>
                    {
                        ![
                            'Discontinued',
                            'Inactive',
                            'CancelRequested',
                            'CancelPending',
                            'CancelDenied'
                        ].includes(prescription.medicationStatus) &&
                        prescription.pharmacyStatus !== 'Entered' &&
                        (
                            <StyledButton
                                size='small'
                                Icon={DeleteOutlined}
                                tooltip={'Request Rx Cancelation'}
                                color='red'
                                onClick={() => selectPrescriptionForCancelation(prescription)}
                            />
                        )
                    }
                </div>
                <div style={{ fontSize: '12px', marginBottom: '5px'}}>Written Date: {moment(prescription.signedAt).format('M/DD/YYYY h:MM a')}</div>
            </div>
            
            <div style={{ display: 'flex', gap: '20px', marginBottom: '5px' }}>
                <span>{prescription.quantity} {getDispenseUnitById(prescription.dispenseUnitId)?.Name}(s)</span>
                <span>Refills: {prescription.refills}</span>
                <span>Supply: {prescription.daysSupply} days</span>
            </div>
            <div><strong>Directions:</strong> {prescription.directions}</div>
            <div><strong>Pharmacy Notes:</strong> {prescription.pharmacyNotes || "None"}</div>
            <div><strong>Pharmacy Status:</strong> {prescription.pharmacyStatus}</div>
            <div>
                <strong>Medication Status: </strong> 
                <span 
                    style={{ 
                        color: cancelled ? 'red' : 'black',
                        fontWeight: cancelled ? 'bold' : 'normal' 
                    }}
                >
                    {prescription.medicationStatus}
                </span>
            </div>
        </div>
    )
}