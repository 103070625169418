import React, { useState } from "react"

import { 
    ThumbnailContainer,
    ThumbnailImage,
    FullSizeImage
 } from "../../../../Styles"

 export const Thumbnail = ({ src, alt, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event) => {
        const { clientX, clientY } = event;
        setPosition({ x: clientX - 1100, y: clientY - 300 });
    };

    return (
        <ThumbnailContainer key={src}>
            <ThumbnailImage 
                alt={`${alt} thumbnail`} 
                src={src} 
                onMouseEnter={() => setIsHovered(true)} 
                onMouseLeave={() => setIsHovered(false)}
                onMouseMove={handleMouseMove}
                onClick={onClick}
            />
            { isHovered && (
                <FullSizeImage 
                    alt={`${alt} full size`} 
                    src={src} 
                    top={`${position.y - 950}px`}
                    left={`${position.x}px`}
                />
            )}
        </ThumbnailContainer>
    )
}