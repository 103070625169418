import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Handlebars from 'handlebars';

import { message } from 'antd'

import ProviderAPI from '../../../../../../../axios/physician/PhysicianApi'
import TemplatesAPI from '../../../../../../../axios/templates/TemplateAPI'

import { TemplateTypeEnum } from '../../../../../../../types/enums';
import { useCaseViewContext } from '../../../../../context/CaseViewContext'

export const useCharting = ({ state, setState }) => {
    const { caseDetails, patient, activeRequest } = useCaseViewContext()
    const queuedPrescriptions = useSelector(state => state.prescribeMedicine?.prescriptions || {})

    const [chartingState, setChartingState] = useState({
        loading: false,
        templatesLoading: false,
        queuedPrescriptions,
        selectedPrescription: null,
        entries: [],
        rawEntry: '',
        compiledEntry: '',
        templates: [],
        selectedTemplate: null
    })

    const addChartingEntry = async (entry) => {
        try {
            setChartingState(prev => ({
                ...prev,
                loading: true
            }))

            const { data } = await ProviderAPI.addChartingEntryToCaseReqest({ requestId: activeRequest._id, ...entry })

            if (data.error) {
                throw new Error("Error adding charting entry")
            }

            setChartingState(prev => ({
                ...prev,
                entries: [{ ...data.entry, updatedAt: new Date().toISOString() }, ...chartingState.entries]
            }))

            setState(prev => ({
                ...prev,
                selectedItem: null,
                modalMode: null
            }))

            resetChartingEntry()
            message.success("Successfully added charting entry");
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        } finally {
            setChartingState(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const editChartingEntry = async entry => {
        try {
            setChartingState(prev => ({
                ...prev,
                loading: true
            }))

            const { data } = await ProviderAPI.editRequestChartingEntry({ entry })

            if (data.error) {
                throw new Error()
            }

            const entries = chartingState.entries.map(item => {
                if (item._id === entry._id) {
                    return data.entry
                }
                return item
            })

            setChartingState(prev => ({
                ...prev,
                entries,
                loading: false
            }))

            setState(prev => ({
                ...prev,
                selectedItem: null,
                modalMode: null
            }))

            resetChartingEntry()
            message.success("Successfully updated charting entry");
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        } finally {
            setChartingState(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const deleteChartingEntry = async entryId => {
        try {
            setChartingState(prev => ({
                ...prev,
                loading: true
            }))

            const { data } = await ProviderAPI.deleteChartingEntryFromCaseReqest(entryId)

            if (data.error) {
                throw new Error()
            }


            setChartingState(prev => ({
                ...prev,
                entries: chartingState.entries.filter(entry => entry._id !== entryId),
                selectedTemplate: null
            }))

            setState(prev => ({
                ...prev,
                selectedItem: null,
                modalMode: null
            }))

            message.success("Charting entry deleted");
        } catch (e) {
            message.error("Unable to delete charting entry")
        } finally {

            setChartingState(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const getChartingTemplates = async () => {
        try {
            setChartingState(prev => ({
                ...prev,
                templatesLoading: true
            }))

            const { data } = await TemplatesAPI.getMany({
                type: TemplateTypeEnum.VISIT,
                teleMedicineCategoryIds: [caseDetails.category._id]
            })

            const { templates } = data

            setChartingState(prev => ({
                ...prev,
                templates
            }))

        } catch (e) {
            message.error("Unable to fetch charting templates")
        } finally {
            setChartingState(prev => ({
                ...prev,
                templatesLoading: false
            }))
        }
    }

    const aggregateChartingEntries = () => {
        const flattenedChartingEntries = caseDetails?.requests?.reduce((chartingEntries, request) => {
            return chartingEntries.concat(request.chartingEntries || []);
        }, []);

        setChartingState(prev => ({
            ...prev,
            entries: flattenedChartingEntries
                .filter(entry => !entry.deleted)
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        }))
    }

    const compileHandlebars = (str) => {
        try {
            const prescription = queuedPrescriptions[chartingState.selectedPrescription]
            const handlebarsTemplate = Handlebars.compile(str || '');
            return handlebarsTemplate({ patient, prescription })
        } catch (e) {
            return "Template contains errors. Please edit template."
        }
    }

    const handleTextChange = ({ target }) => {
        setChartingState(prev => ({
            ...prev,
            rawEntry: target.value,
            compiledEntry: compileHandlebars(target.value || '')
        }))
    }

    const selectTemplate = message => {
        setChartingState(prev => ({
            ...prev,
            selectedTemplate: message,
            rawEntry: message,
            compiledEntry: compileHandlebars(message)
        }))
    }

    const selectPrescription = selectedPrescription => {
        setChartingState(prev => ({
            ...prev,
            selectedPrescription
        }))
    }

    const resetChartingEntry = () => {
        setChartingState(prev => ({
            ...prev,
            selectedTemplate: null,
            rawEntry: '',
            compiledEntry: ''
        }))
    }

    useEffect(() => {
        if (state.contentType === 'charting') {
            setChartingState(prev => ({
                ...prev,
                rawEntry: state.selectedItem?.note,
                compiledEntry: state.selectedItem?.note
            }))
        }
    }, [state.selectedItem])

    useEffect(() => {
        if (caseDetails && activeRequest) {
            aggregateChartingEntries()
            getChartingTemplates()
        }
        return () => resetChartingEntry()
    }, [caseDetails, activeRequest])

    useEffect(() => {
        if (Object.values(queuedPrescriptions || {}).length >  0) {    
            setChartingState(prev => ({
                ...prev,
                queuedPrescriptions
            }))

            if (Object.values(queuedPrescriptions).length === 1) {
                setChartingState(prev => ({
                    ...prev,
                    selectedPrescription: Object.values(queuedPrescriptions)[0]._id
                }))
            }
        }
    }, [queuedPrescriptions])

    return {
        chartingState,
        selectTemplate,
        selectPrescription,
        resetChartingEntry,
        handleTextChange,
        editChartingEntry,
        addChartingEntry,
        deleteChartingEntry
    }
}