import React, { useState, forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Radio,
  Typography,
  Modal,
  Form,
  Select,
  Spin,
  message,
  Checkbox,
} from "antd";

import {
  LoadingOutlined,
  StarFilled,
} from "@ant-design/icons";
import { AntTinyWrapper } from "../../components/AntTinyWrapper";

import incompleteCases from "../../redux/incompleteCases"

import PhysicianAPI from "../../axios/physician/PhysicianApi";
import Handlebars from "handlebars";
import { CaseStatusEnum, REQUIRE_INFO_STATUS_NAMES, FILTER } from '../../types/enums'
import { clearFromAllExtraMessageTemplates } from "../../utils/clearFromAllExtraMessageTemplates";
import { useGetFilteredTemplates } from "../../hooks/useGetFilteredTemplates";
import { useCaseDetails } from "../../hooks/useCaseDetails";

const { Option } = Select;
const { Text } = Typography;

const FORM_FIELD = {
  CASE_TEMPLATE: "Case Template",
  EXTRA_MESSAGE_TEMPLATE: "Extra Message Template",
  PATIENT_EXPLANATION_MESSAGE: "Patient Explanation Message",
}

const TemplateSelect = forwardRef(({ templates, customMessageText, compileHandlebars, ...props }, ref) => {
  return (
    <Select
      ref={ref}
      size="large"
      filterOption={(input, { key, value }) => (key || value).toLowerCase().includes(input.toLowerCase())}
      showSearch
      {...props}
    >
      {templates.map((template, index) => (
          <Option
            key={`${template.title}-${index}`}
            value={compileHandlebars(template.message)}
          >
            {template.isFavorite && <StarFilled style={{ color: "#7BAD7E", marginRight: 4 }} />}
            {template.title}
          </Option>
        ))}
      <Option key="custom-message" value={""}>{customMessageText}</Option>
    </Select>
  )
})

const CaseUpdateStatus = ({
  actionCaseStatus,
  setModalVisibility,
  caseCategoryId,
  caseId,
  actions,
  physicianDetails,
  physicianNeedsReview
}) => {
  const [form] = Form.useForm();

  const [isExtraMessageTemplate, setIsExtraMessageTemplate] = useState(true)

  const [isLoadingUpdateCaseStatus, setIsLoadingUpdateCaseStatus] = useState(false);

  const [requireInformationStatus, setRequireInformationStatus] = useState(actionCaseStatus);
  const [sendInformationCheckBox, setSendInformationCheckBox] = useState(actionCaseStatus !== CaseStatusEnum.REFER);
  const [rejectionReason, setRejectionReason] = useState(null)
  const [isExplanationMessageEmpty, setIsExplanationMessageEmpty] = useState(true)

  const { caseDetails, caseDetailsLoading, fetchCaseDetails } = useCaseDetails(caseId)

  const {
    isLoading: isLoadingTemplates,
    templateFilter,
    setTemplateFilter,
    extraMessageTemplates,
    filteredCaseTemplates,
    filteredExtraMessageTemplates,
  } = useGetFilteredTemplates({ caseCategoryId, caseStatus: requireInformationStatus })

  const isRefer = (requireInformationStatus === CaseStatusEnum.REFER)

  const compileHandlebars = (message) => {
    const handlebarsTemplate = Handlebars.compile(message);
    return handlebarsTemplate({
      case: caseDetails,
      physician: physicianDetails,
      patient: caseDetails.patient
    })
  }

  const onChangeExplanationMessage = (value) => {
    form.setFieldsValue({ [FORM_FIELD.PATIENT_EXPLANATION_MESSAGE]: value });
    if (!value.includes(form.getFieldValue(FORM_FIELD.CASE_TEMPLATE))) {
      form.setFieldsValue({ [FORM_FIELD.CASE_TEMPLATE]: "" });
    }
    if (!value.includes(form.getFieldValue(FORM_FIELD.EXTRA_MESSAGE_TEMPLATE))) {
      form.setFieldsValue({ [FORM_FIELD.EXTRA_MESSAGE_TEMPLATE]: "" });
    }
    setIsExplanationMessageEmpty(!value);
  };

  const onChangeRequireInformationStatus = (value) => {
    setRequireInformationStatus(value);
  };

  const onChangeCaseTemplateSelect = (message) => {
    form.setFieldsValue({
      [FORM_FIELD.CASE_TEMPLATE]: message,
      [FORM_FIELD.PATIENT_EXPLANATION_MESSAGE]: message,
      [FORM_FIELD.EXTRA_MESSAGE_TEMPLATE]: "",
    });
  };

  const toggleIsExtraMessageTemplate = (event) => {
    if (!event.target.checked) {
      const patientExplanationField = form.getFieldValue(FORM_FIELD.PATIENT_EXPLANATION_MESSAGE)
      const clearedPatientExplanationField = clearFromAllExtraMessageTemplates(patientExplanationField, extraMessageTemplates)
      form.setFieldsValue({ [FORM_FIELD.PATIENT_EXPLANATION_MESSAGE]: clearedPatientExplanationField })
    }
    setIsExtraMessageTemplate(event.target.checked)
  }

  const onChangeExtraMessageTemplateSelect = (message) => {
    const patientExplanationField = form.getFieldValue(FORM_FIELD.PATIENT_EXPLANATION_MESSAGE)
    const clearedPatientExplanationField = clearFromAllExtraMessageTemplates(patientExplanationField, extraMessageTemplates)
    const patientExplanationFieldWithExtraMessage = clearedPatientExplanationField + '\n<p>&nbsp;</p>' + message
    form.setFieldsValue({ [FORM_FIELD.PATIENT_EXPLANATION_MESSAGE]: patientExplanationFieldWithExtraMessage })
  };

  const handleCancel = () => {
    setModalVisibility(false);
  };

  const handleStatusUpdate = () => {
    const statusData = {
      patientExplanation: form.getFieldValue(FORM_FIELD.PATIENT_EXPLANATION_MESSAGE),
      sendToPatient: sendInformationCheckBox,
      status: requireInformationStatus,
    };

    if (isRefer) {
      statusData.rejectionReason = rejectionReason;
    }

    setIsLoadingUpdateCaseStatus(true);
    PhysicianAPI.updateCaseStatus(caseId, statusData)
      .then(({ data }) => {
        if (!data?.success) {
          throw Error(data?.message || null)
        }
        message.success("Successfully updated case status");
        fetchCaseDetails(caseId);
        actions.getIncompleteCases()
        setModalVisibility(false)
      })
      .catch((error) => {
        message.error(error.response?.data?.message || "Unable to update case status. Please try again later!");
      })
      .finally(() => {
        fetchCaseDetails()
        setIsLoadingUpdateCaseStatus(false)
    });
  };

  const onChangeTemplateFilter = (e) => {
    setTemplateFilter(e.target.value);
    form.setFieldsValue({
      [FORM_FIELD.CASE_TEMPLATE]: "",
      [FORM_FIELD.EXTRA_MESSAGE_TEMPLATE]: "",
    });
  }

  const okButtonProps = {
    disabled: (isRefer && !rejectionReason) || isExplanationMessageEmpty || !requireInformationStatus,
  }
  const okText = physicianNeedsReview && isRefer ? "Refer" : "Update Status"

  const isApiLoading = isLoadingTemplates || caseDetailsLoading || isLoadingUpdateCaseStatus
  const isDisabled = !requireInformationStatus || isApiLoading

  return (
    <Modal
      visible
      onOk={handleStatusUpdate}
      onCancel={handleCancel}
      width='35vw'
      okButtonProps={okButtonProps}
      okText={okText}
    >
      <Spin spinning={isApiLoading} indicator={<LoadingOutlined />}>
        <Form form={form} name="update case status modal">
          {REQUIRE_INFO_STATUS_NAMES.includes(requireInformationStatus) ? (
            <Form.Item
              label="Case Status"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Select
                size="large"
                style={{ textTransform: "capitalize" }}
                value={requireInformationStatus}
                placeholder="Select Case Status"
                onChange={onChangeRequireInformationStatus}
                defaultOpen
                autoFocus
              >
                {REQUIRE_INFO_STATUS_NAMES.map((status) => (
                    <Option
                      key={status}
                      value={status}
                      style={{ textTransform: "capitalize" }}
                    >
                      {status}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Case Status"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Text style={{ textTransform: "capitalize", fontWeight: "bolder" }}>
                {requireInformationStatus}
              </Text>
            </Form.Item>
          )}

          <Form.Item
            label="Select a template message as patient explanation"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group
              value={templateFilter}
              style={{ width: "100%" }}
              onChange={onChangeTemplateFilter}
              disabled={isDisabled}
            >
              {Object.values(FILTER).map((filter) =>
                <Radio.Button style={{ textTransform: "capitalize", width: "25%", textAlign: "center" }} value={filter}>{filter}</Radio.Button>
              )}
            </Radio.Group>
          </Form.Item>
          <Form.Item name={FORM_FIELD.CASE_TEMPLATE}>
            <TemplateSelect
              placeholder="Add template message as explanation to patient"
              onChange={onChangeCaseTemplateSelect}
              disabled={isDisabled}
              templates={filteredCaseTemplates}
              customMessageText="Custom Message"
              compileHandlebars={compileHandlebars}
            />
          </Form.Item>
          <Form.Item onChange={toggleIsExtraMessageTemplate}>
            <Checkbox
              checked={isExtraMessageTemplate}
              disabled={isDisabled}
            >
                Add extra message to explanation
            </Checkbox>
          </Form.Item>
          {isExtraMessageTemplate && (
            <Form.Item name={FORM_FIELD.EXTRA_MESSAGE_TEMPLATE}>
              <TemplateSelect
                placeholder="Add extra message template"
                onChange={onChangeExtraMessageTemplateSelect}
                disabled={isDisabled}
                templates={filteredExtraMessageTemplates}
                customMessageText="No Extra Message"
                compileHandlebars={compileHandlebars}
              />
            </Form.Item>
          )}
          <Form.Item
            labelCol={{ span: 24 }}
            label={
              <span
                style={{ color: isDisabled ? "#7c7c7c" : '#7bad7b', fontWeight: 'bold' }}
              >Explanation To Patient</span>
            }
          >
            <Checkbox
              checked={sendInformationCheckBox}
              onChange={(event) => setSendInformationCheckBox(event.target.checked)}
              disabled={isDisabled}

            >
              Send explanation to patient
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={FORM_FIELD.PATIENT_EXPLANATION_MESSAGE}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true }]}
          >

            <AntTinyWrapper
              onChange={onChangeExplanationMessage}
              disabled={isDisabled}
            />
          </Form.Item>
          {
            isRefer && (
              <Form.Item
                name="Rejection Reason"
                label={<span style={{ color: "#7bad7e", fontWeight: 'bold' }}>Rejection Reason (to be provided to reviewing physician)</span>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true }]}
              >
                <AntTinyWrapper
                  value={rejectionReason}
                  onChange={setRejectionReason}
                  disabled={isDisabled}
                />
              </Form.Item>
            )
          }
        </Form>
      </Spin>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  const { getIncompleteCases } = incompleteCases.actions

  return {
    actions: bindActionCreators(
      {
        getIncompleteCases,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(CaseUpdateStatus)
