import styled from 'styled-components'

import { 
    Menu,
    Badge,
} from 'antd';

export const StyledMenu = styled(Menu)`

    height: 100vh;
    paddingTop: 20px;

    & .ant-menu-submenu {
        padding-left: 10px;
    }

    & .ant-menu-submenu-selected {
        color: #7c7c7c !important;
        background-color: #ffffff !important;
    }
`;

export const StyledMenuItem = styled(Menu.Item)`
    margin: 0 !important;

    & a {
        display: flex !important;
        align-items: center !important;
    }
`

export const StyledBadge = styled(Badge)`
    margin-left: 10px;
`

export const StyledSubMenu = styled(Menu.SubMenu)`
    paddingLeft: 10px;

    & .ant-menu-submenu-title:hover {
        color: #7c7c7c;
    }
`

export const StyledSubMenuItem = styled(Menu.Item)`
    padding: 4px 16px !important;
`