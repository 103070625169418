import React, { useEffect, useState } from 'react'
import { Radio, Select, Tabs, Modal, Spin, message } from 'antd'
import moment from 'moment-timezone'

import {
    CustomRadioGroup,
    StyledTabs
} from './LabOrder.Modal.styles'

import { useCaseViewContext } from '../../../../../../context/CaseViewContext'

import { RequestStatusEnum, LabResourceTypeEnum } from '../../../../../../../../types/enums'

import LabsApi from '../../../../../../../../axios/labs/LabsApi'

const NoDocumentMessage = ({
    selectedConsult,
    resourceId,
    resourceType
}) => {
    if (!selectedConsult?.reportId && !selectedConsult?.orderId) return <p>No documents available for this consultation</p>
    if (!resourceId) return <p>No {resourceType} available for this consultation</p>
    return null
}

const OrderIframe = ({ caseDetails, latestRequest }) => {
    const [labOrder, setLabOrder] = useState({
        url: null,
        loading: false
    })

    useEffect(() => {
        if (caseDetails) {
            LabsApi.fetchLabOrderIframe(caseDetails?.caseId)
                .then(({ data }) => setLabOrder({ url: data.url, loading: false }))
                .catch(e => {
                    message.error(e.response?.data?.message || 'Error fetching lab order UI')
                    setLabOrder({ url: null, loading: false })
                })
        }
       
    }, [caseDetails])

    return (
        <Spin spinning={labOrder.loading}>
            <div style={{ display: 'flex', flexDirection: 'column', color: '#000' }}>
                <p><strong>Requested Labs Company: {latestRequest.requestedLabsCompany}</strong></p>
                {labOrder.url && <iframe src={labOrder.url} title="Order Labs" width="100%" height="600px" />}
            </div>
        </Spin>
    )
}

const LabDocs = ({ requests = [] }) => {

    const [consults, setConsults] = useState({
        selected: null,
        all: []
    })

    const [resource, setResource] = useState({
        type: LabResourceTypeEnum.REPORT,
        id: null
    })

    const [documentUrl, setDocumentUrl] = useState(null)

    const getDocumentURL = async (config) => {
        const { data } = await LabsApi.fetchResourcePdf(config)
        setDocumentUrl(data)
    }

    useEffect(() => {
        const mappedAndSortedRequests = requests
            .map(({
                _id,
                createdAt,
                labResults,
                requestStatusHistory
            }) => {
                const labOrderDate = requestStatusHistory?.find(requestStatus => requestStatus.status === RequestStatusEnum.PENDING_LAB_RESULTS)?.createdAt

                return {
                    _id,
                    date: labOrderDate || createdAt,
                    reportId: labResults?.reportId,
                    orderId: labResults?.orderId
                }

            })
            .sort((a, b) => new Date(b.date) - new Date(a.date))

        const latestConsult = mappedAndSortedRequests?.[0]

        setConsults({
            all: mappedAndSortedRequests,
            selected: latestConsult
        })

        setResource({
            id: latestConsult?.reportId || latestConsult?.orderId,
            type: latestConsult?.reportId ? LabResourceTypeEnum.REPORT : LabResourceTypeEnum.ORDER
        })
    }, [requests])

    useEffect(() => {
        if (consults.selected) {

            const {
                reportId,
                orderId
            } = consults.selected

            const documentId = resource.type === LabResourceTypeEnum.REPORT ? reportId : orderId
            setResource(prev => ({ ...prev, id: documentId }))

            if (documentId) {
                getDocumentURL({ resourceType: resource.type, resourceId: documentId })
            }
        }
    }, [consults.selected, resource.type])

    const handleChangeResourceType = e => setResource(prev => ({ ...prev, type: e.target.value }))

    const handleChangeConsult = val => setConsults(prev => ({ ...prev, selected: consults.all?.find(consult => consult._id === val) }))

    const options = consults.all.map(({ date, _id }) => ({
        label: moment(date).format("MM/DD/YYYY"),
        value: _id
    }))

    if (!requests.length) return null

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <span>Consultation Date ({options.length} available)</span>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Select
                    style={{ width: '200px', margin: '10px 0', textTransform: 'capitalize' }}
                    options={options}
                    value={consults.selected?._id}
                    onChange={handleChangeConsult}
                />
                <CustomRadioGroup
                    value={resource.type}
                    onChange={handleChangeResourceType}
                    type='button'
                >
                    {!!consults.selected?.reportId && <Radio.Button value={LabResourceTypeEnum.REPORT}>{LabResourceTypeEnum.REPORT}</Radio.Button>}
                    {!!consults.selected?.orderId && <Radio.Button value={LabResourceTypeEnum.ORDER}>{LabResourceTypeEnum.ORDER}</Radio.Button>}
                </CustomRadioGroup>
            </div>
            <NoDocumentMessage selectedConsult={consults.selected} resourceId={resource.id} resourceType={resource.type} />
            {!!resource.id && <iframe id='doc-iframe' src={documentUrl} title={`Lab ${resource.type}`} width="100%" height="600px" />}
        </div>
    )
}


export const LabOrderModal = ({ closeLabOrderModal }) => {
    const [activeKey, setActiveKey] = useState("1")
    const { caseDetails, activeRequest } = useCaseViewContext()
    const labAlreadyOrdered = activeRequest.status !== RequestStatusEnum.PENDING_LAB_ORDER

    useEffect(() => {
        if (labAlreadyOrdered) {
            setActiveKey("2")
        }
    }, [caseDetails])

    return (
        <Modal
            visible
            onCancel={closeLabOrderModal}
            width={1000}
            footer={null}
        >
            <StyledTabs
                alignLeft
                activeKey={activeKey}
                onTabClick={(key) => setActiveKey(key)}
            >   
                { !labAlreadyOrdered && 
                    <Tabs.TabPane activeKey tab="Lab Order" key="1">
                        <OrderIframe
                            caseDetails={caseDetails}
                            latestRequest={activeRequest}
                        />
                    </Tabs.TabPane>
                }
                <Tabs.TabPane tab="Lab Documents" key="2">
                    <LabDocs requests={caseDetails?.requests} />
                </Tabs.TabPane>
            </StyledTabs>
        </Modal>
    )
}