import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Select, Radio } from 'antd'

import { CustomRadioGroup } from './styles'

import LabsApi from '../../../../../axios/labs/LabsApi'
import { LabResourceTypeEnum, RequestStatusEnum } from '../../../../../types/enums'

import { NoDocumentMessage } from '../NoDocumentMessage'

export const LabDocs = ({ requests = [] }) => {

    const [consults, setConsults] = useState({
        selected: null,
        all: []
    })

    const [resource, setResource] = useState({
        type: LabResourceTypeEnum.REPORT,
        id: null
    })

    const [documentUrl, setDocumentUrl] = useState(null)

    const getDocumentURL = async (config) => {
        const { data } = await LabsApi.fetchResourcePdf(config)
        setDocumentUrl(data)
    }

    useEffect(() => {
        const mappedAndSortedRequests = requests
            .map(({
                _id,
                createdAt,
                labResults,
                requestStatusHistory
            }) => {
                const labOrderDate = requestStatusHistory?.find(requestStatus => requestStatus.status === RequestStatusEnum.PENDING_LAB_RESULTS)?.createdAt

                return {
                    _id,
                    date: labOrderDate || createdAt,
                    reportId: labResults?.reportId,
                    orderId: labResults?.orderId
                }
                
            })
            .sort((a, b) => new Date(b.date) - new Date(a.date))

        const latestConsult = mappedAndSortedRequests?.[0]

        setConsults({
            all: mappedAndSortedRequests,
            selected: latestConsult
        })
        
        setResource({ 
            id: latestConsult?.reportId || latestConsult?.orderId, 
            type: latestConsult?.reportId ? LabResourceTypeEnum.REPORT : LabResourceTypeEnum.ORDER
        })
    }, [requests])

    useEffect(() => {
        if (consults.selected) {

            const {
                reportId,
                orderId
            } = consults.selected
            
            const documentId = resource.type === LabResourceTypeEnum.REPORT ? reportId : orderId
            setResource(prev => ({ ...prev, id: documentId }))
            getDocumentURL({ resourceType: resource.type, resourceId: documentId })
        }
    }, [consults.selected, resource.type])

    const handleChangeResourceType = e => setResource(prev => ({ ...prev, type: e.target.value }))

    const handleChangeConsult = val => setConsults(prev => ({ ...prev, selected: consults.all?.find(consult => consult._id === val)}))

    const options = consults.all.map(({ date, _id }) => ({
        label: moment(date).format("MM/DD/YYYY"),
        value: _id
    }))

    if (!requests.length) return null
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <span>Consultation Date ({options.length} available)</span>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
                <Select 
                    style={{ width: '200px', margin: '10px 0', textTransform: 'capitalize'}}
                    options={options} 
                    value={consults.selected?._id}
                    onChange={handleChangeConsult}
                />
                <CustomRadioGroup
                    value={resource.type}  
                    onChange={handleChangeResourceType}
                    type='button'
                >
                    { !!consults.selected?.reportId && <Radio.Button value={LabResourceTypeEnum.REPORT}>{LabResourceTypeEnum.REPORT}</Radio.Button> }
                    { !!consults.selected?.orderId && <Radio.Button value={LabResourceTypeEnum.ORDER}>{LabResourceTypeEnum.ORDER}</Radio.Button> }
                </CustomRadioGroup>
            </div>
            <NoDocumentMessage selectedConsult={consults.selected} resourceId={resource.id} resourceType={resource.type} />
            { !!resource.id && <iframe id='doc-iframe' src={documentUrl} title={`Lab ${resource.type}`} width="100%" height="600px" /> }
        </div>            
    )   
}