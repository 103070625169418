import React, { useState, useEffect } from "react"

import {
  Module
} from "../../Styles"

import { Toggle } from "./components/Toggle"
import { MessageList } from "./components/MessageList"
import { Controls } from "./components/Controls"
import { ImageUploadModal } from "./components/ImageUploadModal"

import { useTickets } from './hooks/useTickets'
import { useMessaging } from "./hooks/useMessaging";
import { useCaseViewContext } from "../../context/CaseViewContext"

export const Messaging = () => {
  const [chatContext, setChatContext] = useState('patient')
  const [imageUploadVisibility, setImageUploadVisibility] = useState(false);
  const { caseDetails, provider } = useCaseViewContext()

  const [messages, setMessages] = useState({
    loading: false,
    list: [],
  });

  const {
    adminMessages,
    sendAdminChatMessage
  } = useTickets({
    caseDetails,
    setMessages
  })

  const {
    patientMessages,
    fetchPatientMessageList,
    sendPatientMessage
  } = useMessaging({
    caseDetails,
    provider,
    setMessages
  })

  const handleSendMessage = message => {
    const sendFn = chatContext === 'patient'
      ? sendPatientMessage
      : sendAdminChatMessage

    sendFn(message)
  };

  const onImageUploadModalVisibilityChange = () => {
    setImageUploadVisibility(
      (previousVisibilityStatus) => !previousVisibilityStatus
    );
    fetchPatientMessageList()
  }

  useEffect(() => {
      if (chatContext === 'patient') {
        setMessages(patientMessages)
      } else if (chatContext === 'admin') {
        setMessages(adminMessages)
    }
  }, [chatContext, patientMessages, adminMessages])

  return (
    <Module
      width="30%"
      border="none"
      padding="0"
      gap="0"
      style={{
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Toggle
        chatContext={chatContext}
        setChatContext={setChatContext}
        adminMessageNotificationCount={adminMessages?.count}
        patientMessageNotificationCount={patientMessages?.count}
      />
      <MessageList
        chatContext={chatContext}
        messages={messages}
        handleSendMessage={handleSendMessage}
      />
      <Controls
        chatContext={chatContext}
        handleSendMessage={handleSendMessage}
        showModal={onImageUploadModalVisibilityChange}
      />
      { imageUploadVisibility && (
        <ImageUploadModal
          closeModal={onImageUploadModalVisibilityChange}
        /> )
      }
    </Module>
  )
}