import React, { createContext, useContext } from 'react';

const CaseViewContext = createContext();

export const useCaseViewContext = () => {
    const context = useContext(CaseViewContext);
    if (!context) {
        throw new Error("useCaseViewContext must be used within a CaseVeiwProvider");
    }
    return context;
}

export const CaseViewProvider = ({ children, value }) => {
    return (
        <CaseViewContext.Provider value={value}>
            {children}
        </CaseViewContext.Provider>
    );
};
