import React, { useState, useEffect } from "react";
import {
  Collapse,
  Spin,
  Typography,
  message
} from "antd";
import {
  RightCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// import APIs
import PhysicianApi from "../../axios/physician/PhysicianApi";

//import components
import CaseDetails from "../../pages/caselist/CaseDetails";
import CasePatientDetails from "../../pages/caselist/CasePatientDetails";
import MIFView from "../../pages/caselist/MIFView";
import DocumentView from "../../pages/caselist/DocumentView";
import { useCaseDetails } from "../../hooks/useCaseDetails";
import socket from "../../axios/socket";

const { Panel } = Collapse;
const { Text, Title } = Typography;

const CaseDetailsAccordion = ({ caseId, defaultActiveKey }) => {
  const { caseDetails, caseDetailsLoading } = useCaseDetails(caseId)
  const [_case, setCase] = useState(caseDetails)

  const attachRxRequestNotes = async (requests) => {
    const rxRequestNotes = (await PhysicianApi.getRxRequestNotes(caseId)).data.payload;
    const rxRequestNotesMap = new Map(rxRequestNotes.map(note => [`${note.rxRequest.requestType}+${note.rxRequest.createdAt}`, note]));

      return requests.map(request => {
        request.note = rxRequestNotesMap.get(`${request.requestType}+${request.createdAt}`)?.content || null;
        return request;
      });
  }

  useEffect(() => {
    socket.connect();

    socket.on('newNote', async ({ caseId }) => {
      if (caseId !== _case.caseId) return;
      const requests = await attachRxRequestNotes([..._case.requests]);
      message.info('note from admin received')
      
      setCase({ ..._case, requests });
    })

    socket.on('updatedNote', async ({ caseId }) => {
      if (caseId !== caseDetails.caseId) return;
      const requests = await attachRxRequestNotes([..._case.requests]);
      message.info('note from admin updated')
      
      setCase({ ..._case, requests });
    })

    return () => {
      socket.removeAllListeners(["newNote"]);
      socket.removeAllListeners(["updatedNote"]);
    }
  // eslint-disable-next-line
  }, [_case])

  return (
      <Spin
        size="large"
        spinning={caseDetailsLoading}
        indicator={<LoadingOutlined spin />}
      >
        {caseDetails ? (
          <Collapse
            className="case-details-collapse"
            accordion
            expandIcon={({ isActive }) => (
              <RightCircleOutlined
                className="case-details-collapse-icon"
                rotate={isActive ? 90 : 0}
              />
            )}
            expandIconPosition="right"
            defaultActiveKey={defaultActiveKey}
          >
            <Panel header={<Title level={4}>Case Details</Title>} key="1">
              <CaseDetails
                caseDetails={caseDetails}
                chatView={true}
                commonTemplateMessages={[]}
                statusUpdateRequired={false}
                updateCaseStatus={() => {
                  console.log(
                    "==================== case status update click ======================================"
                  );
                }}
              />
            </Panel>
            <Panel
              header={<Title level={4}>Medical Intake Form</Title>}
              key="2"
            >
              <CasePatientDetails caseDetails={caseDetails} />
              <MIFView answeredQuestions={caseDetails.mif} />
            </Panel>
            <Panel header={<Title level={4}>Patient Documents</Title>} key="3">
              {caseDetails && <DocumentView caseDetails={caseDetails} />}
            </Panel>
          </Collapse>
        ) : (
          <div className="message-no-chat-selection">
            <Text strong>Please wait. We are loading the case details</Text>
          </div>
        )}
      </Spin>
  )
}

export default CaseDetailsAccordion;