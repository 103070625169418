import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Stopwatch = ({ pause }) => {
  const [milliseconds, setMilliseconds] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMilliseconds(prevMilliseconds => pause ? prevMilliseconds : prevMilliseconds + 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [pause]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <span>{formatTime(milliseconds)}</span>
  );
};

Stopwatch.defaultProps = {
  pause: false,
};

Stopwatch.propTypes = {
  pause: PropTypes.bool,
};

export default Stopwatch;
