import React from 'react'
import { Radio, Badge } from 'antd'

export const Toggle = ({
    chatContext,
    setChatContext,
    adminMessageNotificationCount,
    patientMessageNotificationCount
}) => {
    return (
        <div style={{ 
            width: '100%', 
            height: '100px', 
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '10px',
            position: 'absolute',
            zIndex: 2
        }}>
            <Radio.Group
                onChange={({ target}) => setChatContext(target.value)}
                value={chatContext}
            >
                <Badge count={patientMessageNotificationCount}>
                    <Radio.Button value={'patient'}>Patient</Radio.Button>
                </Badge>
                <Badge count={adminMessageNotificationCount}>
                    <Radio.Button button value={'admin'}>Admin</Radio.Button>
                </Badge>
            </Radio.Group>
        </div>
    )
}