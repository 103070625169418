import React from 'react'
import { Route, useRouteMatch } from "react-router-dom";

// import components
import CaseList from "../../CasesList/CaseList";
import PhysicianDashboard from "../../Dashboard/PhysicianDashboard/PhysicianDashboard";
import LegacyCaseView from "../../caselist/CaseView";
import { CaseView } from '../../CaseView'
import WaitingRoom from "../../WaitingRoom";
import MyAvailability from "../../schedule/MyAvailability";
import RxChangeRequests from "../../RxChangeRequests/RxChangeRequests";
import AdditionalInfoRequired from "../../AdditionalInfoRequired/AdditionalInfoRequired";
import OtherPhysiciansCases from "../../OtherPhysiciansCases";
import CaseQueue from "../../CaseQueue"
import Messages from "../../Messages";
import Templates from "../../Templates";
import ProfileView from "../../Account/ProfileView";
import CompletedConsults from "../../CompletedConsults/CompletedConsults.container";
import ConsultCompletedNoRx from "../../ConsultCompletedNoRx/ConsultCompletedNoRx.container";
import Articles from "../../Articles";
import ArticleView from "../../Articles/components/ArticleView";
import CaseTicket from "../../CaseTicket/CaseTicket";
import AllCases from "../../Cases/AllCases";
import SyncCases from "../../Cases/SyncCases";
import Reports from "../../Reports"
import InternalRxRequests from "../../InternalRxRequests";
import IncompleteCases from "../../IncompleteCases"
import Referrals from "../../Referrals"
import HeisenbergRequests from "../../HeisenbergRequests"
import PrescribeMedicine from "../../PrescribeMedicine";
import History from "../../History"
import { Reassigned } from '../../Reassigned';

import { userConstants } from "../../../constants";
import { PhysicianTagEnum } from "../../../types/enums"
import { useUserConfig } from '../../../hooks/useUserConfig';

export const AppRouter = ({
    loggedInPhysicianDetails
}) => {
    const { path } = useRouteMatch();
    const [config] = useUserConfig();

    const physicianTags = loggedInPhysicianDetails?.tags?.map(tag => tag.toLowerCase())
    const physicianNeedsCasesReviewed = physicianTags?.includes(PhysicianTagEnum.NEEDS_REVIEW)
    const isReviewingPhysician = physicianTags?.includes(PhysicianTagEnum.REVIEWER)

    const handleCallStartFromPhysician = (caseId) => { window.location.href = `/consultation/${caseId}`; };

    return (
        <>
            <Route exact path={path}>
                <PhysicianDashboard />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/myaccount`}
            >
                <ProfileView />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/case-queue`}
            >
                <CaseQueue />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/sync-cases`}
            >
                <SyncCases />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/caselist`}
            >
                <CaseList />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/cases`}
            >
                <AllCases
                    title='Cases' type='cases'
                />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/reassigned-cases`}
            >
                <Reassigned />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/caseview/:id`}
            > {
                config.useExperimentalFeatures
                    ? <CaseView statusUpdateRequired physicianDetails={loggedInPhysicianDetails} />
                    : <LegacyCaseView statusUpdateRequired physicianDetails={loggedInPhysicianDetails} />
            }
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/rx-requests`}
            >
                <HeisenbergRequests provider={loggedInPhysicianDetails} />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/prescription/:id`}
            >
                <PrescribeMedicine provider={loggedInPhysicianDetails} />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/additional-info-required`}
            >
                <AdditionalInfoRequired />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/other-physicians-cases`}
            >
                <OtherPhysiciansCases />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/history`}
            >
                <History />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/consultedcase`}
            >
                <CompletedConsults
                    title={"Completed Consults"}
                    type={"consult complete"}
                />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/consultedcase-noRX`}
            >
                <ConsultCompletedNoRx
                    title={"Consult Complete, No Rx"}
                    type={"consult complete no rx"}
                />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/waiting-room`}
            >
                <WaitingRoom
                    handleCallStart={handleCallStartFromPhysician}
                />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/availability`}
            >
                <MyAvailability />
            </Route>

            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/updateprescribe`}
            >
                <RxChangeRequests />
            </Route>

            <Route path={`${path}/${userConstants.USER_PHYSICIAN}/rx-change-requests`}>
                <InternalRxRequests />
            </Route>

            {
                (physicianNeedsCasesReviewed || isReviewingPhysician) &&
                <Route path={`${path}/${userConstants.USER_PHYSICIAN}/referrals`}>
                    <Referrals />
                </Route>
            }

            <Route path={`${path}/${userConstants.USER_PHYSICIAN}/incomplete-cases`}>
                <IncompleteCases />
            </Route>

            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/messages`}
            >
                <Messages />
            </Route>

            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/templates`}
            >
                <Templates />
            </Route>

            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/case-tickets`}
            >
                <CaseTicket />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/articles`}
            >
                <Articles />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/article-view/:id`}
            >
                <ArticleView />
            </Route>
            <Route
                path={`${path}/${userConstants.USER_PHYSICIAN}/reports`}>
                <Reports />
            </Route>
        </>

    )
}