import React, { useEffect } from 'react'
import { Form, Input, message } from 'antd'

import {
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons'

import {
    StyledModal,
    ModalTitle,
} from './styles'

import {
    StyledForm,
    ButtonContainer,
} from './ProviderNote.Modal.styles'

import { VeritasSpinner } from '../../../../../../components/VeritasSpinner'

import { StyledButton } from '../../../Button';

import { useCaseViewContext } from '../../../../context/CaseViewContext';

export const ProviderNoteModal = ({
    selectedItem,
    modalMode,
    onCancel,
    notesState,
    addProviderNote,
    editProviderNote
}) => {
    const [form] = Form.useForm()
    const { caseDetails } = useCaseViewContext()

    const handleSubmit = async params => {
        const submitFn = !!selectedItem
            ? editProviderNote
            : addProviderNote

        const payload = params

        if (!!selectedItem) {
            payload._id = selectedItem._id
        } else {
            payload.caseId = caseDetails.caseId
        }

        try {
            await submitFn(payload)
            form.resetFields()
        } catch (e) {
            message.error(e.message)
        }
    }

    const handleCancel = () => {
        form.resetFields()
        onCancel()
    }

    useEffect(() => {
        form.setFieldsValue(selectedItem || {})
    }, [selectedItem])

    return (
        <StyledModal
            visible={!!modalMode}
            title={!!modalMode && <ModalTitle>{modalMode} Provider Note</ModalTitle>}
            closable={false}
            footer={null}
        >
            <VeritasSpinner spinning={notesState.loading}>
                <StyledForm
                    form={form}
                    layout={"vertical"}
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Note"
                        name="content"
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea autoSize={{ minRows: 6, maxRows: 10 }} />
                    </Form.Item>
                    <ButtonContainer>
                        <StyledButton
                            type="button"
                            size="medium"
                            Icon={CloseCircleOutlined}
                            tooltip="Cancel"
                            color="red"
                            onClick={handleCancel}
                        />
                        <StyledButton
                            type="submit"
                            size="medium"
                            Icon={CheckCircleOutlined}
                            tooltip="Submit"
                        />
                    </ButtonContainer>
                    <Form.Item hidden name='_id' />
                </StyledForm>
            </VeritasSpinner>
        </StyledModal>
    )
}