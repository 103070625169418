import React from 'react'
import { Typography } from 'antd'

import {
    TemplatesContainer,
    StyledSelect
} from '../Treatment.Form.styles'

export const TemplateSelect = ({
    templates,
    selectedTemplate,
    handleSelectTemplate,
}) => {

    const _handleSelectTemplate = val => {
        const template = templates?.find(template => template._id === val)
        handleSelectTemplate(template)
    }

    return (
            <TemplatesContainer>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginBottom: '10px'
                }}> 
                    <Typography.Text>
                        <strong>Template Options</strong>
                    </Typography.Text>
                </div>
                <StyledSelect
                    value={selectedTemplate?.title || (templates.length ? 'Templates available' : 'None Selected')}
                    options={templates?.map(template => ({ label: template.title, value: template._id}))} 
                    onSelect={_handleSelectTemplate}
                />
            </TemplatesContainer>
    )
}
