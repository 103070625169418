import React from 'react'
import { useSelector } from 'react-redux'
import { Form, Input } from 'antd'

import { required, isMismatch, isPositive } from '../validation'

import { useCaseViewContext } from '../../../../../../../../context/CaseViewContext'
import { useTreatmentFormContext } from '../TreatmentFormContext'


export const RefillsInput = ({
    requestedMedication,
}) => {
    const { caseDetails } = useCaseViewContext()
    const { form } = useTreatmentFormContext()
    const { APMedicineDictionary } = useSelector(state => state.prescribeMedicine)

    const controlledSubstanceValidation = {

        validator: (_, value) => {
            const schedule = form.getFieldValue('schedule');
        
            if (schedule) {
                if (Number(schedule) === 2 && Number(value) !== 0) return Promise.reject(new Error('Schedule 2 drugs cannot be refilled'));
                if (Number(schedule) > 2 && Number(value) > 5)  return Promise.reject(new Error(`Schedule ${schedule} drugs cannot be refilled more than 5 times`));
            }

            return Promise.resolve()
        }
    }


    return (
        <Form.Item
            required
            label="Refills"
            name="refills"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isPositive,
                isMismatch(APMedicineDictionary, requestedMedication, caseDetails.telemedicineCategoryId),
                controlledSubstanceValidation
            ]}
        >
            <Input type='number' />
        </Form.Item>
    )
}