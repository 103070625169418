import { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { Device, Call } from "@twilio/voice-sdk";
import { message } from "antd";
import AudioCallModal from "../components/AudioCallModal";
import PhysicianApi from "../axios/physician/PhysicianApi";
import CaseRegisterApi from "../axios/case/CaseRegisterApi";
import { useWatch } from '../hooks/useWatch';

const AudioCallContext = createContext(null);

export const useAudioCallContext = () => {
  const context = useContext(AudioCallContext);
  if (!context) {
    throw new Error("useAudioCallContext must be used within a AudioCallProvider");
  }
  return context;
}

export const AudioCallProvider = ({ children }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [currentCall, setCurrentCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [patient, setPatient] = useState(null);

  const stopUseWatch = (stopWatch, newValue) => {
    if ([Call.State.Closed].includes(newValue)) {
      stopWatch();
    }
  }

  const [callStatus] = useWatch({ object: currentCall, getter: "status", defaultValue: "idle", stop: stopUseWatch });

  const startCall = useCallback(async () => {
    try {
      if (!currentDevice) {
        throw Error("Device not initialized");
      }
      const params = { To: patient.phone };
      const newCall = await currentDevice.connect({ params });
      setCurrentCall(newCall);
    } catch (err) {
      console.log("Error starting call. ", err);
      message.error("Error starting call. " + (err?.message || "Try again later."));
    }
  }, [patient, currentDevice]);

  const finishCall = useCallback(() => {
    setIsMuted(false);
    currentDevice.disconnectAll();
  }, [currentDevice]);

  const onClose = useCallback(() => {
    setPatient(null);
    setIsOpenModal(false);
    currentDevice.destroy();
  }, [currentDevice]);

  const initializeCallDevice = useCallback(async (caseId) => {
    if (!caseId) {
      return;
    }

    setIsOpenModal(true);

    try {
      const [caseRes, tokenRes] = await Promise.all([
          CaseRegisterApi.getCaseDetails(caseId),
          PhysicianApi.getPhoneCallToken(),
      ]);
      const caseDetails = caseRes.data.payload?.[0];
      const callToken = tokenRes.data?.token;
      const patient = caseDetails?.patient;

      if (!callToken || !patient) {
        throw Error("Token or patient not found");
      }

      const newDevice = new Device(callToken)

      setCurrentDevice(newDevice);
      setPatient(patient);
    } catch (err) {
      console.log("Error initializing call device", err);
      message.error(err.message || "Call error. Try again later.");
    }
  }, []);

  const toggleMuteCall = useCallback(() => {
    if (!currentCall) {
      console.log("No call to mute");
      return;
    }
    currentCall.mute(!isMuted)
      const newMuttedConnectionStatus = currentCall.isMuted()
      setIsMuted(newMuttedConnectionStatus);
  }, [currentCall, isMuted]);

  const contextData = useMemo(() => ({
    initiate: initializeCallDevice,
  }), [initializeCallDevice])

  const showModal = isOpenModal && patient;

  return (
    <AudioCallContext.Provider value={contextData}>
      {showModal && (
        <AudioCallModal
          onClose={onClose}
          finishCall={finishCall}
          startCall={startCall}
          patient={patient}
          isMuted={isMuted}
          callStatus={callStatus}
          toggleMuteCall={toggleMuteCall}
        />
      )}
      {children}
    </AudioCallContext.Provider>
  )
}
