const types = {
    SET_PHARMACY_LOADING: "SET_PHARMACY_LOADING",
    SET_PHARMACY: "SET_PHARMACY",
    SET_SHOW_CHANGE_PHARMACY_MODAL: "SET_SHOW_CHANGE_PHARMACY_MODAL",
    SET_PHARMACY_SEARCH_RESULTS_LOADING: "SET_PHARMACY_SEARCH_RESULTS_LOADING",
    SET_PHARMACY_SPECIALTY_TYPES: "SET_PHARMACY_SPECIALTY_TYPES",
    SET_PHARMACY_SEARCH_RESULTS: "SET_PHARMACY_SEARCH_RESULTS",
    SET_IS_NEW_DEFAULT_PHARMACY: "SET_IS_NEW_DEFAULT_PHARMACY",
    SET_DISPENSE_UNITS: "SET_DISPENSE_UNITS",
    SET_CONSULTATION_COMPLETED: "SET_CONSULTATION_COMPLETED",
    ADD_PRESCRIPTION_TO_QUEUE: "ADD_PRESCRIPTION_TO_QUEUE",
    SAVE_QUEUED_PRESCRIPTION: "SAVE_QUEUED_PRESCRIPTION",
    REMOVE_PRESCRIPTION_FROM_QUEUE: "REMOVE_PRESCRIPTION_FROM_QUEUE",
    CLEAR_QUEUED_PRESCRIPTION: "CLEAR_QUEUED_PRESCRIPTION",
    RESET_PRESCRIPTION_QUEUE: "RESET_PRESCRIPTION_QUEUE",
    IS_RX_MISMATCH: "IS_RX_MISMATCH",
    SET_ALL_PRESCRIPTIONS_VALID: "SET_ALL_PRESCRIPTIONS_VALID",
    INIT_PRESCRIPTION_QUEUE: "INIT_PRESCRIPTION_QUEUE",
    SET_PRESCRIPTION_QUEUE: "SET_PRESCRIPTION_QUEUE",
    SET_AP_MEDICINE_DICTIONARY: "SET_AP_MEDICINE_DICTIONARY",
    SET_PROVIDER_PIN: "SET_PROVIDER_PIN",
    SET_PROVIDER_OTP_CODE: "SET_PROVIDER_OTP_CODE",
    SET_USE_TFA: "SET_USE_TFA",
    EVALUATE_READY_TO_COMPLETE: "EVALUATE_READY_TO_COMPLETE",
    SET_VISIT_NOTE: "SET_VISIT_NOTE",
    REGISTER_PDMP_VIEW: "REGISTER_PDMP_VIEW",
    SELECT_PRESCRIPTION: "SELECT_PRESCRIPTION",
  };
  
  export default types;