import React from 'react';
import { CopyOutlined } from '@ant-design/icons';

const CopyStringComponent = ({ text }) => {

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    }).catch((error) => {
      console.error('Error copying text: ', error);
    });
  };

  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
      <p style={{ margin: 0 }}>{text}</p>
      <button style={{ border: 'none', padding: '0', background: 'none', color: 'grey'}}onClick={copyToClipboard}><CopyOutlined /></button>
    </div>
  );
};

export default CopyStringComponent;