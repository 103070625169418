import React, { useState } from "react";
import { Menu } from "antd";
import { connect } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import ScheduleDayIcon from "../../../assets/icons/ScheduleDayIcon";

import {
    FileDoneOutlined,
    FileUnknownOutlined,
    MessageOutlined,
    ReadOutlined,
    DatabaseOutlined,
    PieChartOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    ReloadOutlined,
    SwapOutlined,
    TeamOutlined,
    UnorderedListOutlined,
    SearchOutlined,
    UserSwitchOutlined,
    HistoryOutlined,
} from "@ant-design/icons";

import { userConstants } from "../../../constants";
import { PhysicianTagEnum } from "../../../types/enums"
import DashBoardIcon from "../../../assets/icons/DashBoardIcon";
import CaseIcon from "../../../assets/icons/CaseIcon";
import ConsultedCaseIcon from "../../../assets/icons/ConsultedCaseIcon";

import {
    StyledMenu,
    StyledMenuItem,
    StyledBadge,
    StyledSubMenu,
    StyledSubMenuItem,
} from './MainMenu.Styles'

const MainMenu = (props) => {
    let { url } = useRouteMatch();
    const [openKeys, setOpenKeys] = useState([]);

    const {
        rxChangeCaseCount,
        rxRenewalCaseCount,
        incompleteCasesCount,
        MessageCount,
        additionalInfoCaseUpdateCount,
        referralCasesCount,
        caseTicketNotificationCount,
        pendingPrescriptionCaseNotification,
        physicianDetails,
        caseQueue
    } = props;

    const physicianTags = physicianDetails?.tags?.map(tag => tag.toLowerCase())
    const physicianNeedsCasesReviewed = physicianTags?.includes(PhysicianTagEnum.NEEDS_REVIEW)
    const isReviewingPhysician = physicianTags?.includes(PhysicianTagEnum.REVIEWER)
    const isLeadPhysician = physicianTags?.map(tag => tag.toLowerCase()).includes(PhysicianTagEnum.LEAD_PHYSICIAN)
    
    const handleOpenChange = (keys) => {
        setOpenKeys(keys);
    };

    return (
        <StyledMenu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
        >
            <StyledMenuItem key="/dashboard">
                <Link to={`${url}`}>
                    <DashBoardIcon />
                    Dashboard
                </Link>
            </StyledMenuItem>
            <Menu.Item key="/dashboard/physician/cases" >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/cases`}>
                    <SearchOutlined />
                    Cases
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/case-queue">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/case-queue`}>
                    <UnorderedListOutlined/>
                    Case Queue
                    <StyledBadge count={caseQueue?.length}/>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/caselist" >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/caselist`}>
                    <CaseIcon />
                    Pending Prescription
                    <StyledBadge count={pendingPrescriptionCaseNotification}/>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/sync-cases" >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/sync-cases`}>
                    <CaseIcon />
                    Sync Cases
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/additional-info-required" >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/additional-info-required`}>
                    <FileUnknownOutlined />
                    Additional Info Required
                    <StyledBadge count={additionalInfoCaseUpdateCount}/>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/consultedcase" >
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/consultedcase`}>
                    <FileDoneOutlined />
                    Consulted Cases
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/consultedcase-noRX">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/consultedcase-noRX`}>
                    <ConsultedCaseIcon />
                    Consulted Cases (No RX)
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/updateprescribe">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/updateprescribe`}>
                    <SwapOutlined  />
                    Rx Change Requests
                    <StyledBadge count={rxChangeCaseCount}/>
                </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/physician/rx-requests">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/rx-change-requests`}>
                    <ReloadOutlined  />
                    Rx Renewal Requests
                    <StyledBadge count={rxRenewalCaseCount}/>
                </Link>
            </Menu.Item>
            {
                (isReviewingPhysician || physicianNeedsCasesReviewed) && (
                    <Menu.Item key="/dashboard/physician/referrals">
                        <Link to={`${url}/${userConstants.USER_PHYSICIAN}/referrals`}>
                            <EyeOutlined  />
                            {(physicianNeedsCasesReviewed ? 'In Review' : 'Needs Review')}
                            <StyledBadge count={referralCasesCount}/>
                        </Link>
                    </Menu.Item>
                )
            }
            <Menu.Item key="/dashboard/physician/reassigned-cases">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/reassigned-cases`}>
                    <UserSwitchOutlined  />
                    Reassigned Cases
                </Link>
            </Menu.Item >
            <StyledMenuItem key="/dashboard/physician/incomplete-cases">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/incomplete-cases`}>
                    <ExclamationCircleOutlined  />
                    Incomplete Cases
                    <StyledBadge count={incompleteCasesCount}/>
                </Link>
            </StyledMenuItem >
            {
                isLeadPhysician && (
                    <Menu.Item key="/dashboard/physician/other-physicians-cases">
                        <Link to={`${url}/${userConstants.USER_PHYSICIAN}/other-physicians-cases`}>
                            <TeamOutlined  />
                            Other Physicians' Cases
                        </Link>
                    </Menu.Item >
                )
            }
            <Menu.Item
                key="/dashboard/physician/history">
                <Link to={`${url}/${userConstants.USER_PHYSICIAN}/history`}>
                    <HistoryOutlined  />
                    History
                </Link>
            </Menu.Item>

            <StyledSubMenu
                key="submenu"
                mode="vertical"
                title={
                    <span>
                        <ReadOutlined />
                        More Options
                    </span>
                }
            >
                <StyledSubMenuItem key="/dashboard/physician/availability" >
                    <Link to={`${url}/${userConstants.USER_PHYSICIAN}/availability`}>
                        <ScheduleDayIcon />
                        My Availability
                    </Link>
                </StyledSubMenuItem>
                <StyledSubMenuItem key="/dashboard/physician/messages" >
                    <Link to={`${url}/${userConstants.USER_PHYSICIAN}/messages`}>
                        <MessageOutlined />
                        Messages
                        <StyledBadge count={MessageCount}/>
                    </Link>
                </StyledSubMenuItem>
                <StyledSubMenuItem key="/dashboard/physician/templates">
                    <Link to={`${url}/${userConstants.USER_PHYSICIAN}/templates`}>
                        <ReadOutlined />
                        Templates
                    </Link>
                </StyledSubMenuItem>
                <StyledSubMenuItem key="/dashboard/physician/articles" >
                    <Link to={`${url}/${userConstants.USER_PHYSICIAN}/articles`}>
                        <ReadOutlined/>
                        Articles
                    </Link>
                </StyledSubMenuItem>
                <StyledSubMenuItem key="/dashboard/physician/reports" >
                    <Link to={`${url}/${userConstants.USER_PHYSICIAN}/reports`}>
                        <PieChartOutlined />
                        Reports
                    </Link>
                </StyledSubMenuItem>
                <StyledSubMenuItem key="/dashboard/physician/case-tickets" >
                    <Link to={`${url}/${userConstants.USER_PHYSICIAN}/case-tickets`}>
                        <DatabaseOutlined />
                        Case Tickets
                        <StyledBadge count={caseTicketNotificationCount}/>
                    </Link>
                </StyledSubMenuItem>
            </StyledSubMenu>
        </StyledMenu >
    );
};

function mapStateToProps(state) {
    const { waitingCasesCount } = state.waitingRoom;
    const { MessageCount } = state.message;
    const { additionalInfoCaseUpdateCount } = state.additionalInfoRequired;
    const { referralCasesCount } = state.referrals
    const { rxChangeCaseCount } = state.rxChange;
    const { rxRenewalCaseCount } = state.rxRenewal;
    const { incompleteCasesCount } = state.incompleteCases;
    const { caseTicketNotificationCount } = state.caseTickets;
    const { pendingPrescriptionCaseNotification } = state.notifications;
    const { physicianDetails } = state.physicianDetails
    const { caseQueue } = state.caseQueue
    return {
        waitingCasesCount,
        MessageCount,
        additionalInfoCaseUpdateCount,
        referralCasesCount,
        rxChangeCaseCount,
        rxRenewalCaseCount,
        incompleteCasesCount,
        caseTicketNotificationCount,
        pendingPrescriptionCaseNotification,
        physicianDetails,
        caseQueue
    };
}

export default connect(mapStateToProps)(MainMenu);
