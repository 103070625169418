import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd'

import ChatRoomAPI from '../../../../../axios/ChatRoomAPI'

export const useMessaging = ({
    caseDetails,
    provider,
    setMessages
}) => {

    const [patientMessages, setPatientMessages] = useState({
        list: [],
        count: 0,
    });

    const fetchPatientMessageList = useCallback(async () => {
        if (!caseDetails) return

        setPatientMessages({
            list: [],
            count: 0
        })

        setMessages({
            list: [],
            loading: true
        })

        try {
            const { data } = await ChatRoomAPI.getChatRoomMessages(caseDetails?.caseId)

            if (!data?.success) {
                throw new Error("Error fetching patient messages")
            }

            setPatientMessages({
                list: data.payload.messages,
                count: data.payload.notificationCount,
            })
        } catch (e) {
            message.error(e)
        }
    }, [caseDetails?.caseId, caseDetails?.status])

    const sendPatientMessage = async message => {

        const payload = {
            chatRoomId: caseDetails?.caseId,
            author: provider.userId,
            isPatient: false,
            message,
            type: 'text',
            key: null
        }

        try {
            const { data } = await ChatRoomAPI.addMessagesToChatRoom(payload)
            if (!data.success) {
                throw new Error('Error sending message')
            }

            payload._id = payload.chatRoomId

            setPatientMessages(prev => ({
                ...prev,
                list: [...prev.list, { ...payload, created: new Date()}]
            }))

            setMessages(prev => ({
                ...prev,
                list: [...prev.list, { ...payload, created: new Date()}]
            }))
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
      };

    const clearPatientChatNotfications = useCallback(async () => {
        if (!caseDetails?.caseId) return
        try {
            const { data } = await ChatRoomAPI.clearChatRoomNotifications(caseDetails.caseId)
            if (!data.success) {
                throw new Error('Error clearinging patient chat notifications')
            }
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
    }, [caseDetails?.caseId]);

    useEffect(() => {
        fetchPatientMessageList()
        clearPatientChatNotfications()
    }, [caseDetails?.caseId, caseDetails?.status])

    return {
        fetchPatientMessageList,
        sendPatientMessage,
        patientMessages
    }
}