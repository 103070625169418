import styled from 'styled-components'

export const View = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background: #fff;
`

export const Module = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    padding: ${props => props.padding || '20px 30px'};
    gap: ${props => props.gap || '25px'};
    overflow-y: scroll;
    background: ${props => props.gradient || 'none'};
    border-right: ${props => props.border || '3px solid #eee'};
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 2px solid #eee;
`

export const Header = styled.p`
    text-transform: capitalize;
    line-height: 34px;
    font-weight: bold;
    font-size: 34px;
    color: #000;
    margin-bottom: 0px;
`

export const SubHeader = styled.p`
    font-weight: bold;
    line-height: 24px;
    font-size: 20px;
    color: ${ props => props.color || '#999' };
    text-transform: capitalize;
`

export const Header2 = styled.p`
    font-weight: bold;
    font-size: 24px;
    color: #000;
    margin-bottom: 0;
`

export const AttachmentsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;
`

export const ThumbnailContainer = styled.div`
    position: relative;
    display: inline-block;
`

export const ThumbnailImage = styled.img`
    width: 150px;
    height: auto;
    cursor: pointer;
`

export const Button = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 40px; /* Fixed width to make it square */
    height: 40px; /* Fixed height to make it square */
    border: none;
    border-radius: 10px; /* Rounded corners */
    background-color: #7BAD7E;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    ${ props => props.stringifiedstyle }

    &:active {
        transform: scale(0.95);
    }
`

export const FullSizeImage = styled.img`
    position: absolute;
    top: ${props => props.top || 0};
    left: ${props => props.left || 0};
    width: auto;
    height: auto;
    max-width: 500px;
    z-index: 10;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

export const HorizontalButtonGroup = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
`

