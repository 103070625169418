import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import s from './Modal.module.css';

const Modal = ({ onClose, hasBackDrop, closeOnBackDrop, backdropStyle, contentStyle, children }) => {
  useEffect(() => {
      const keydownCloseModal = ({ code }) => {
        code === "Escape" && onClose();
      };
      window.addEventListener('keydown', keydownCloseModal);
      return () => window.removeEventListener('keydown', keydownCloseModal);
  }, [onClose])

  const backdropCloseModal = ({ target, currentTarget }) => {
    if (closeOnBackDrop && target === currentTarget) {
      onClose();
    };
  }

  if (hasBackDrop) {
    return createPortal(
      <div
        className={s.backdrop}
        style={backdropStyle}
        onClick={backdropCloseModal}
      >
        <div
          className={s.content}
          style={contentStyle}
        >
          {children}
        </div>
      </div>
    , document.querySelector('#modal-root'))
  }

  return createPortal(
      <div
        className={s.content}
        style={contentStyle}
      >
        {children}
      </div>
  , document.querySelector('#modal-root'))
}

Modal.defaultProps = {
  hasBackDrop: true,
  closeOnBackDrop: true,
  backdropStyle: {},
  contentStyle: {},
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  hasBackDrop: PropTypes.bool,
  closeOnBackDrop: PropTypes.bool,
  backdropStyle: PropTypes.object,
  contentStyle: PropTypes.object,
};

export default Modal;