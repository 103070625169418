import React from 'react'
import { Button, Typography } from 'antd'

const { Text } = Typography

export const PDMPResource = ({
    pdmpResource,
    registerPDMPView
}) => {

    return pdmpResource
        ? <Button style={{ border: 'none', background: 'none', padding: '0', marginLeft: '3px'}} onClick={registerPDMPView}>{pdmpResource}</Button>
        : <Text>Missing PDMP Config for state</Text>
}