import React from "react"

import { Metadata } from "./components/Metadata"
import { Attachments } from "./components/Attachments"
import { MIF } from "./components/MIF"
import { Pharmacy } from "./components/Pharmacy"
import { Treatments } from "./components/Treatments"
import { PDMP } from './components/PDMP'

import { Module } from "../../Styles"

export const Request = ({ requestRef }) => {
    return (
        <Module width="40%" ref={requestRef}>
            <Metadata/>
            <Attachments/>
            <MIF/>
            <Pharmacy/>
            <PDMP />
            <Treatments/>
        </Module>
    )
}