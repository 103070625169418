import styled from 'styled-components'

export const RequestHistoryItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 10px;

    background-color: ${props => props.backgroundColor};

    ${props => !props.isActiveRequest && `
        &:hover {
            cursor: pointer;
            background-color: #eee;
        }
    `}
`

export const Category = styled.div`
    color: ${props => props.color};
    font-weight: bold;
`

export const RequestType = styled.div``

export const RequestStatus = styled.div`
    text-transform: capitalize;
    font-weight: bold;
`