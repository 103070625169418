import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { history } from "./helpers/history";
import { Router } from "react-router-dom";
import { message } from "antd";
import { socket_close } from "./axios/socket";
import Axios from "./axios/Axios";
import { store } from "./redux";
import { MarkerProvider } from './context/MarkerProvider';
import { AudioCallProvider } from "./context/AudioCallProvider";
import { MenuProvider } from "./context/MenuProvider";
import actions from "./redux/authentication/actions"

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <MarkerProvider>
        <AudioCallProvider>
          <MenuProvider>
            <App />
          </MenuProvider>
        </AudioCallProvider>
      </MarkerProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// get count of unathorized requests, to show message properly.
let countUnauthorized = 0;
const successHandler = (response) => {
  if (countUnauthorized >= 1) {
    countUnauthorized = 0;
  }
  return response;
};



// moving the axios inteceptor attachment to global place where the fuction will have access to redux store
// Its done to trigger the logout action and clean the redux store, when any of the API respond with 401 or 403 response

Axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => {
    // whatever you want to do with the error
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      let url = "";

      countUnauthorized = countUnauthorized + 1;

      if (error.response.config) {
        url = error.response.config.url;
      }

      if (url !== "/api/user/login") {
        if (url !== "/api/case/login/") {
          window.localStorage.clear();
          if (countUnauthorized === 1) {
            socket_close();
            store.dispatch(actions.setUserLogout());
            message.error("Login session expired. Please login again!");
            history.push("/");
          }
        }
      }
    }
    throw error;
  }
);
