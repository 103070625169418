import React, { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Select } from 'antd'

import prescribeMedicine from '../../../../../../../../../../redux/prescribeMedicine'
import { useTreatmentFormContext } from '../TreatmentFormContext'
import { useDispenseUnitSelector } from '../hooks/useDispenseUnitSelector'
import { useDispenseUnitContext } from '../../../../context/DispenseUniteContext'

export const DispenseUnitSelector = memo(() => {
    const dispatch = useDispatch()
    const { handleFieldsChange } = useTreatmentFormContext()
    const { selectedPrescription } = useSelector(state => state.prescribeMedicine)
    const { dispenseUnits } = useDispenseUnitContext()
    const { options } = useDispenseUnitSelector({ prescription: selectedPrescription })

    const handleChange = quantityUnits => {
        const dispenseUnit = dispenseUnits.find(unit => unit.StandardDispenseUnitTypeID.toString() === quantityUnits.toString())

        dispatch(prescribeMedicine.actions.saveQueuedPrescription({ 
            _id: selectedPrescription._id, 
            quantityUnits, 
            doseForm: dispenseUnit.Name, 
            dispenseUnitId: dispenseUnit.StandardDispenseUnitTypeID 
        }))
        
        handleFieldsChange()
    }

    return (
        <Form.Item
            required
            label="Dispense Unit"
            name="quantityUnits"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[{ required: true, message: 'Required' }]}
        >
            <Select
                showSearch
                placeholder='Dispense unit'
                options={options}
                onSelect={handleChange}
            />
         </Form.Item>
    )
})