import { useState, useEffect } from 'react'
import API from "../../../../../../../axios/apiHelper"

export function useClientLogo({ clientId }) {
    const [clientLogo, setClientLogo] = useState(null)
    
    const fetchClientLogo = () => {
        API.fetchLookUpDetails("client", clientId)
        .then((response) => {
            const { data } = response;
            if (data && data.success) {
            const { payload } = data;
            if (payload && payload.logoImage) {
                setClientLogo(payload.logoImage);
            }
            }
        })
    };

    useEffect(() => {
        if (clientId) {
            fetchClientLogo()
        }
    }, [clientId])

    return {
        clientLogo
    };
}