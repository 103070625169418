import React, { useState, useEffect, forwardRef } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Select,
  message,
  Checkbox
} from "antd";
import { DeleteFilled, LockFilled, UnlockFilled } from '@ant-design/icons';
import { AntTinyWrapper } from '../../components/AntTinyWrapper';
import { showVariableList } from './showVariableList';
import PhysicianAPI from '../../axios/physician/PhysicianApi';
import api from "../../axios/apiHelper";
import { TemplateTypeEnum, MODE, EXTRA_MESSAGE_CASE_STATUS_NAMES } from '../../types/enums';

const { Option } = Select;
const { TextArea } = Input;

const { CASE, MESSAGE } = TemplateTypeEnum

const NotClickableWrapper = ({ viewMode, children }) => (
  <div style={{ pointerEvents: viewMode ? "none" : "auto" }}>{children}</div>
)

const HtmlViewer = forwardRef(({ value, ...props }, ref) => {
  const style = {
    whiteSpace: 'pre-wrap',
    border: '1px solid #d9d9d9',
    padding: '4px',
    minHeight: '32px',
    borderRadius: '2px',
    overflow: 'auto',
    ...props.style,
  }
  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{ __html: value }}
      style={style}
      {...props}
    />
  )
});

const TelemedicineCategorySelect = ({ form, options, viewMode, required }) => {
  const onChange = (e) => form.setFieldsValue({
    teleMedicineCategoryIds: e.target.checked ? options.map(c => c._id) : [],
  });

  return (
    <NotClickableWrapper viewMode={viewMode}>
      <Form.Item
        name="teleMedicineCategoryIds"
        label={
          <div style={{ display: 'flex' }}>
            <span style={{ marginRight: 15 }}>Telemedicine Categories</span>
            <Checkbox onChange={onChange}>Select All</Checkbox>
          </div>
        }
        rules={[{ required }]}
      >
          <Select
            size="large"
            placeholder="Select Telemedicine Category"
            mode='multiple'
          >
            {options.map(({ _id, name }) => <Option value={_id} key={_id}>{name}</Option>)}
          </Select>
      </Form.Item>
    </NotClickableWrapper>
  )
}

const CaseStatusSelect = ({ options, viewMode, required }) => (
  <NotClickableWrapper viewMode={viewMode}>
    <Form.Item
      name="statusId"
      label="Case Status"
      rules={[{ required }]}
    >
      <Select
        size="large"
        placeholder="Select Case Status"
      >
        {options.map(({ _id, name }) => <Option value={_id} key={_id}>{name}</Option>)}
      </Select>
    </Form.Item>
  </NotClickableWrapper>
)

const TemplateForm = ({ mode, type, template, handleFinish, searchTemplates, isOwner }) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [caseStatuses, setCaseStatuses] = useState([]);

  const title = `${mode} ${type[0].toUpperCase()}${type.slice(1)} Template`;
  const isViewMode = (mode === MODE.VIEW);
  const isCaseType = (type === CASE);

  const onFinish = (values) => {
    const RequestByMode = {
      [MODE.ADD]: () => PhysicianAPI.addTemplate(type, values),
      [MODE.EDIT]: () => PhysicianAPI.updateTemplate(template._id, values),
    }
    RequestByMode[mode] && RequestByMode[mode]()
      .then(handleFinish)
      .then(form.resetFields)
      .then(searchTemplates)
      .catch(err => message.error({ content: err.response?.data?.message }))
  }

  const handleDelete = () => {
    return PhysicianAPI
      .deleteTemplate(template._id)
      .then(handleFinish)
      .then(searchTemplates)
      .catch(err => message.error({ content: err.response?.data?.message }))
  }

  useEffect(() => {
    api.fetchLookUp('tele-medicine-category')
      .then(res => setCategories(res.data.payload))
      .catch(() => message.error('Error loading telemedicine categories'));
    api.fetchLookUp('case-status')
      .then(res => setCaseStatuses(res.data.payload))
      .catch(() => message.error('Error loading case statuses'));
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      title: template?.title,
      teleMedicineCategoryIds: template?.teleMedicineCategoryIds?.map(category => category._id),
      statusId: template?.statusId?._id,
      message: template?.message,
    });
  }, [template, form])

  const caseStatusSelectOptionsByTemplateType = {
    [CASE]: caseStatuses,
    [MESSAGE]: [
      { _id: undefined, name: 'All' },
      ...caseStatuses.filter(({ name }) => EXTRA_MESSAGE_CASE_STATUS_NAMES.includes(name)),
    ],
  }

  const caseStatusSelectOptions = caseStatusSelectOptionsByTemplateType[type] || [];

  return (
    <Form
      form={form}
      layout={"vertical"}
      onFinish={onFinish}
    >
      <Row justify='space-between'>
        <Col>
          <Typography.Title level={2}>{title}</Typography.Title>
        </Col>
        {
          mode === MODE.EDIT &&
          <Col>
            {isOwner && <Button onClick={
              () => PhysicianAPI.togglePrivateTemplate(template?._id)
            } icon={template.isPrivate ? <UnlockFilled /> : <LockFilled />} style={{ marginRight: 15 }}>Set {template.isPrivate ? 'Public' : 'Private' }</Button> }
            <Button onClick={handleDelete} type='danger' icon={<DeleteFilled />}>Delete</Button>
          </Col>
        }
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true }]}
          >
            <TextArea
              placeholder="Enter the title for Template Message"
              readOnly={isViewMode}
              autoSize
              style={{ resize: 'none' }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <TelemedicineCategorySelect
            form={form}
            options={categories}
            viewMode={isViewMode}
            required={isCaseType}
          />
        </Col>
        <Col span={24}>
          <CaseStatusSelect
            options={caseStatusSelectOptions}
            viewMode={isViewMode}
            required={isCaseType}
          />
        </Col>
        <Col span={24}>
            <Form.Item
              name="message"
              label="Message"
              rules={[{ required: true }]}
              >
                {isViewMode ?  <HtmlViewer /> : <AntTinyWrapper />}
            </Form.Item>
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col>
          <Button onClick={showVariableList}>Template Variables</Button>
        </Col>
        <Col>
          <Row justify='end'>
            {
              isViewMode
                ? <Col>
                  <Button type='primary' onClick={handleFinish}>Close</Button>
                </Col>
                : <>
                  <Col style={{ marginRight: 10 }}>
                    <Button onClick={handleFinish}>Cancel</Button>
                  </Col>
                  <Col>
                    <Button type='primary' onClick={form.submit} >{mode === 'Add' ? 'Create' : 'Save'}</Button>
                  </Col>
                </>
            }
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default TemplateForm;
