import React from "react";

// import CSS
import "./App.css";

import { Route, Switch } from "react-router-dom";

import PhysicianLogin from "./pages/Login/PhysicianLogin";

// import Ant D CSS
import "antd/dist/antd.css";

// import components
import HomePage from "./pages/HomePage/HomePage";
import VideoCall from "./pages/VideoCall/VideoCall";
import PrescribeMedicine from "./pages/PrescribeMedicine";
import ResetPasswordComponent from "./components/ResetPasswordComponent/ResetPasswordComponent";
import VerificationOTP from "./pages/Login/PhysicianLogin/components/VerificationOTP";
import { useVersionUpdater } from './hooks/useVersionUpdater';


function App() {
  useVersionUpdater();

  return (
    <div className="App">
      <Switch>
        <Route
        path="/verify-otp"
        render={(props)=><VerificationOTP {...props}/>}
        />

        <Route path="/dashboard" render={(props) => <HomePage {...props} />} />
        <Route
          path="/login-physician"
          render={(props) => <PhysicianLogin {...props} />}
        />
        
        <Route
          path="/reset-password"
          render={(props) => <ResetPasswordComponent {...props} />}
        />

        {window.localStorage.getItem("token") !== null && (
          <Route
            path="/consultation/:id"
            render={(props) => <VideoCall {...props} />}
          ></Route>
        )}

        <Route
          path="/prescribe-medicine/:id"
          render={(props) => <PrescribeMedicine {...props} />}
        />

        <Route
          exact
          path="/"
          render={(props) => <PhysicianLogin {...props} />}
        />
      </Switch>
    </div>
  );
}

export default App;
