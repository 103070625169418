import styled from 'styled-components';

export const TagsContainer = styled.div`
    margin: 10px 0;
`

export const FooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 3px;
`