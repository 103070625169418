import React from "react";
import { Card, Typography, Modal, Button, Row, Col, Upload } from "antd";
import { useState } from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { FilePdfOutlined, PlusCircleOutlined } from "@ant-design/icons";
import PhysicianApi from '../../axios/physician/PhysicianApi';

//import common functions
import isValidUrl from "../../utils/isValidURL";
import axios from "axios";


const { Text, Title } = Typography;

const DocumentView = ({ caseDetails, fetchCaseDetails }) => {
  const [visible, setVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [uploadUrl, setUploadUrl] = useState(null);

  const modalOpen = (photo) => {
    new Promise(function (resolve, reject) {
      setTimeout(() => resolve(1), 1000); // (*)
    })
      .then(function () {
        setImageUrl(photo);
      })
      .then(function () {
        setVisible(true);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const openPdf = () => {
    isValidUrl(caseDetails.labReport) &&
      window.open(caseDetails.labReport, "_blank");
  };

  const listAdditionalDocuments = (documents={}) => {
    let results = [];
    for (const [key, value] of Object.entries(documents)) {
      if (isValidUrl(value)) {
        const fileName = new URL(value).pathname.split('/').pop();
        if (fileName.toLowerCase().endsWith('.pdf')) {
          results.push(
            <Button
              type="default"
              className="additional-documents-item"
              title={fileName}
              icon={<FilePdfOutlined style={{ fontSize: "500%", color: "#7BAD7E" }} />}
              onClick={() => window.open(value, "_blank")}
              key={key}
            />
          )
        }
        else {
          results.push(
            <img
              key={key}
              src={value}
              alt={fileName}
              title={fileName}
              onClick={() => modalOpen(value)}
              className="additional-documents-item"
            />
          );
        }
      }
    }

    results.push(
      <Upload
        listType="picture"
        key="picture"
        customRequest={async ({ file, onSuccess, onError, onProgress }) => {
          const getArrayBufferFromFile = async () => new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsArrayBuffer(file)
          })

          const data = await getArrayBufferFromFile();

          return axios.put(uploadUrl, data, {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              onProgress({ percent: Math.floor((loaded * 100) / total) })
            }
          }).then((res) => onSuccess(res.data))
            .catch((err) => onError(err, err.response?.body))
        }}
        action={uploadUrl}
        onChange={(info) => {
          if (info.file.status === 'done') {
            const url = new URL(uploadUrl);
            PhysicianApi
              .addAdditionalDocumentUrlToCase(caseDetails.caseId, `${url.origin}${url.pathname}`)
              .then(() => fetchCaseDetails(caseDetails.caseId))
          }
        }}
        beforeUpload={(file) => PhysicianApi.getSignedUploadUrl(caseDetails.caseId, file.name)
          .then(res => setUploadUrl(res.data.signedUploadUrl))
        }
      >
        <Button
          type="default"
          className="additional-documents-item"
          title="Upload additional document"
          icon={<PlusCircleOutlined style={{ fontSize: "500%", color: "#7BAD7E" }} />}
        />
      </Upload>
    );

    return results;
  };

  const documents = listAdditionalDocuments(caseDetails.documents);

  const { photo, idProof } = caseDetails?.patient || {};

  return (
    <Card bordered={false} className="document-view">
      <div className="document-view-body">
        <div className="document-view-parts">
          <Title className="sub-content-color" level={4}>
            Patient Photo & Id
          </Title>
          <Row>
            <Col className="document-view-parts-image">
              {isValidUrl(photo) ? (
                <img
                  src={photo}
                  alt="patient detail"
                  onClick={() => modalOpen(photo)}
                  className="document-view-image-icon"
                />
              ) : (
                <Text strong className="document-view-image-icon-noimage">
                  No photo Available
                </Text>
              )}
            </Col>
            <Col className="document-view-parts-image">
              {isValidUrl(idProof) ? (
                <Col>
                  <img
                    src={idProof}
                    alt="patient detail"
                    onClick={() => modalOpen(idProof)}
                    className="document-view-image-icon"
                  />{" "}
                </Col>
              ) : (
                <Text strong className="document-view-image-icon-noimage">
                  No Id Proof Available
                </Text>
              )}
            </Col>
          </Row>

          <Modal
            visible={visible}
            closable={false}
            onCancel={handleCancel}
            width="70vw"
            centered
            bodyStyle={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            footer={[
              <Button key="back" type="primary" onClick={handleCancel}>
                Close
              </Button>,
            ]}
          >
            <div className="document-view-modal-image">
              <ReactPanZoom alt="sample image" image={imageUrl} />
            </div>
          </Modal>
        </div>
        <hr></hr>
        <div className="document-view-parts">
          <Title className="sub-content-color" level={4}>
            Patient Lab Report
          </Title>

          {isValidUrl(caseDetails.labReport) ? (
            <FilePdfOutlined
              onClick={() => openPdf()}
              className="document-view-parts-pdf-icon"
            />
          ) : (
            <Text strong className="document-view-image-icon-pdf">
              No Lab report Available
            </Text>
          )}
        </div>
        <div className="document-view-parts">
          <Title className="sub-content-color" level={4}>
            Additional Documents
          </Title>
          {documents && documents.length !== 0 ? (
            <div className="additional-documents-container">{documents}</div>
          ) : (
            <Text strong className="document-view-image-icon-pdf">
              No Additional Documents Available
            </Text>
          )}
        </div>
      </div>
    </Card>
  );
};

export default DocumentView;
