import React from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

import { useStrengthSelector } from '../hooks/useStrengthSelector'
import { required, isMismatch } from '../validation'
import { StyledSelect } from '../Treatment.Form.styles'

import { useTreatmentFormContext } from '../TreatmentFormContext'
import { useCaseViewContext } from '../../../../../../../../context/CaseViewContext'

export const StrengthSelector = ({
    handleSelectStrength,
}) => {
    
    const { caseDetails } = useCaseViewContext()

    const { 
        medicationSearchState, 
    } = useTreatmentFormContext()

    const {
        options
    } = useStrengthSelector({
        medicationSearchState,
    })

    const { 
        APMedicineDictionary,
        selectedPrescription 
    } = useSelector(state => state.prescribeMedicine)

    return (
        <Form.Item
            required
            label="Strength"
            name="strength"
            value={medicationSearchState.selectedStrength?.strength}
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isMismatch(APMedicineDictionary, selectedPrescription?.requestedMedication, caseDetails.telemedicineCategoryId)
            ]}
        >
            <StyledSelect 
                options={options} 
                onSelect={handleSelectStrength}
            /> 
        </Form.Item>
    )
}
