import React from 'react'
import { Empty, Skeleton } from 'antd'

import { SectionHeader } from '../../../SectionHeader'
import { Request } from './Request'

import { Container } from "../../../../Styles"

import { useCaseHistory } from './hooks/useCaseHistory'

export const History = () => {

    const {
        requestHistory,
        navigateToCase
    } = useCaseHistory()

    return (
        <Container>
            <SectionHeader title="History" />
            <Skeleton loading={!requestHistory} paragraph={{ rows: 6 }}>
                {!requestHistory?.length && <Empty />}
                {
                    requestHistory?.map((request, index) => (
                        <Request
                            key={request._id}
                            request={request}
                            index={index}
                            navigateToCase={navigateToCase}
                        />
                    ))
                }
            </Skeleton>
        </Container>
    )
}