import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from "antd";
import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";

const MuteButton = ({ isMuted, toggleMuteCall }) => {
  const style = { fontSize: 24 }
  const title = isMuted ? "Unmute" : "Mute"
  return (
    <Tooltip title={title} onClick={toggleMuteCall}>
      {isMuted ? <AudioMutedOutlined style={style} /> : <AudioOutlined style={style} />}
    </Tooltip>
  )
}

MuteButton.propTypes = {
  isMuted: PropTypes.bool.isRequired,
  toggleMuteCall: PropTypes.func.isRequired,
}

export default MuteButton;
