import React from 'react'

export const NoDocumentMessage = ({
    selectedConsult,
    resourceId,
    resourceType
}) => {
    if (!selectedConsult?.reportId && !selectedConsult?.orderId) return <p>No documents available for this consultation</p>
    if (!resourceId) return <p>No {resourceType} available for this consultation</p> 
    return null
}