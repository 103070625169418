import styled from 'styled-components'

export const MessageListContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100% - 240px);
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
    flex-direction: column-reverse;
`

export const Messages = styled.div``