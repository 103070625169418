import styled from 'styled-components';

export const ChartingEntryNote = styled.div`
    text-transform: none;
`

export const ChartingEntryNoteFooter = styled.div`
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
`

export const ButtonGroup = styled.div`
    display: flex;
    gap: 3px;
`
