import { 
    useState, 
    useEffect, 
    useCallback 
} from 'react'

import { message } from 'antd'

import PrescriptionsApi from '../../../../../../../axios/prescription/PrescriptionsApi'

export const useDispenseUnits = () => {
    const [dispenseUnitState, setDispenseUnitState] = useState({
        dispenseUnits: [],
        dispenseUnitsLoading: false
    })

    const fetchDispensUnits = useCallback(() => {
        setDispenseUnitState({ 
            ...dispenseUnitState, 
            dispenseUnitsLoading: true 
        })

        PrescriptionsApi.getAllAvailableDispenseUnits()
            .then(({ data }) => {
                setDispenseUnitState(prev => ({ 
                    ...prev, 
                    dispenseUnits: data?.units || [] 
                }))
            })
            .catch(e => message.error(e.message))
            .finally(() => {
                    setDispenseUnitState(prev => ({ 
                        ...prev, 
                        dispenseUnitsLoading: false 
                    }))
            })
    }, [])

    const getDispenseUnitById = unitId => dispenseUnitState.dispenseUnits.find(unit => unit.StandardDispenseUnitTypeID?.toString() === unitId?.toString())

    useEffect(() => {
        fetchDispensUnits()
    }, [])

    return {
        ...dispenseUnitState,
        getDispenseUnitById,
    }
}