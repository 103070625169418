import { useState } from 'react'
import { message } from 'antd'

import { useDispenseUnitContext } from '../../../../context/DispenseUniteContext'
import PhysicianAPI from '../../../../../../../../../../axios/physician/PhysicianApi'

function removeStrengthFromMedicationName(medicationName) {
    const pattern = /\s?\d+\s?(mg|ml|mg\/ml)\b/;
    return medicationName.replace(pattern, '').trimStart();
}

function removeStrengthFromQuantity(inputStr) {
    const numericStr = inputStr.replace(/[^\d.]/g, '');
    const numericStrTrimmed = numericStr.trim();
    const numericValue = parseFloat(numericStrTrimmed);
    return numericValue;
}

export function usePrescriptionTemplates() {
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, selectTemplate] = useState(null)

    const { getDispenseUnitById } = useDispenseUnitContext()

    const searchTemplates = async ({ ownerId, absoluteFilters = {}, rankedFilters = {} }) => {
        
        try {
            const { data: { templates } } = await PhysicianAPI.getTemplatesByCloseness({
                type: 'prescription',
                ownerId,
                absoluteFilters,
                rankedFilters
            })
            setTemplates(templates)
            return templates
        } catch (e) {
            message.error("Error fetching prescription templates")
            console.log(e)
        } 
    }

    const templatizePrescription = async (prescription) => {
        const { requestedMedication } = prescription
            
        const templates = await searchTemplates({
            ownerId: null,
            absoluteFilters: {
                drugName: removeStrengthFromMedicationName(requestedMedication.drugName),
                pseudonyms: requestedMedication.drugName,
            },
            rankedFilters: { 
                pillCount: requestedMedication.pillCount,
                refills: requestedMedication.refills,
                strength: requestedMedication.strength
            }
        })

        if (!templates?.[0]) {
            return null
        }

        const dispenseUnit = getDispenseUnitById(templates[0].medication.dispenseUnitId)

        const medication = {
            ...templates[0],
            ...templates[0].medication,
            ndc: templates[0].medication.ndc,
            refills: Number(requestedMedication.refills) || Number(templates[0].medication.refills),
            daysSupply:Number(requestedMedication.daysSupply) ||  Number(templates[0].medication.daysSupply),
            schedule: Number(templates[0].schedule || 0),
            name: templates[0].medication.drugName,
            quantity: removeStrengthFromQuantity(requestedMedication.pillCount) || removeStrengthFromQuantity(templates[0].medication.pillCount),
            quantityUnits: dispenseUnit?.Name,
            providePharmacyNotes: !!templates[0].pharmacyNotes,
            ...(templates[0].medication.ingredients && { ingredients: templates[0].medication.ingredients }),
            _id: prescription._id,
        }

        return { ...prescription, ...medication }
    }

    const populateTemplateOptions = async (drugName, strength) => {
        const searchParams = {
            ownerId: null,
            absoluteFilters: {
                drugName: removeStrengthFromMedicationName(drugName),
                pseudonyms: drugName,
            },
        }

        if (strength) {
            searchParams.rankedFilters = { 
                strength
            }
        }

        const templates = await searchTemplates(searchParams)
        setTemplates(templates)
    }

    const resetTemplateSearch = () => {
        setTemplates([])
        selectTemplate(null)
    }

    return { 
        templates, 
        selectedTemplate,
        searchTemplates,
        selectTemplate,
        resetTemplateSearch,
        templatizePrescription,
        populateTemplateOptions,
    };
}
