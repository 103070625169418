import React, { useState, useEffect} from 'react'
import { connect } from "react-redux";
import { Select, Tag } from "antd";
import caseTags from "../../redux/caseTags"
import { TagColorCodeEnum } from '../../types/enums';

const { Option } = Select;

const CaseTagsComponent = ({ 
    currentTags,
    caseId,
    getCaseTags,
    addCaseTag,
    removeCaseTag,
    availableTags
}) => {

    const [tags, setTags] = useState(currentTags)

    useEffect(() => {
        setTags(currentTags)
        getCaseTags()
    }, [setTags, getCaseTags, currentTags])

    const colorTag = tag => {
      if (TagColorCodeEnum[tag]) 
        return TagColorCodeEnum[tag]
      if (tag[0] === '*') 
        return 'green'
      return 'grey'
    }

    function tagRender({ label, onClose }) {
        const onPreventMouseDown = event => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={colorTag(label)}
            onMouseDown={onPreventMouseDown}
            closable={label[0] !== '*'}
            onClose={onClose}
            style={{ marginRight: 3 }}
          >
            {label[0] === '*' ? label.substring(1) : label}
          </Tag>
        );
      }

    function handleTagChange(newCaseTagList) {
        const immutable = tags.filter(tag => tag[0] === '*')
        const added = newCaseTagList.filter(tag => !tags.includes(tag) && tag[0] !== '*')
        const removed = tags.filter(tag => !newCaseTagList.includes(tag) && tag[0] !== '*')
        added.length && added.forEach(addedTag => addCaseTag(caseId, addedTag))
        removed.length && removed.forEach(removedTag => {
            // TAGS PREFIXED WITH '*' ARE IMMUTABLE
            if (removedTag[0] !== '*') removeCaseTag(caseId, removedTag)
        })
        setTags(Array.from(new Set([...newCaseTagList, ...immutable])))
      }
    
    return (
        <Select
            mode="tags"
            style={{ width: "100%"}}
            placeholder="Add a tag..."
            onChange={handleTagChange}
            value={tags}
            tagRender={tagRender}
        >
            {availableTags.length && availableTags
              .filter(tag => tag[0] !== '*')
              .map(tag => <Option key={tag}>{tag}</Option>)}
        </Select>
    )
}

const mapStateToProps = state => ({
  availableTags: state.caseTags.tags
})

const mapDispatchToProps = {
  getCaseTags: caseTags.actions.getCaseTags,
  addCaseTag: caseTags.actions.addCaseTag,
  removeCaseTag: caseTags.actions.removeCaseTag
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseTagsComponent)