import React from 'react';
import HTMLReactParser from "html-react-parser";
import { UserOutlined } from "@ant-design/icons";
import { displayTimestamp } from '../../../../../utils/displayTimestamp';
import { useCaseViewContext } from '../../../context/CaseViewContext';

import {
    Msg,
    StyledAvatar,
    MsgBubble,
    MsgInfo,
    MsgInfoName,
    MsgInfoTime,
    MsgText,
    ImageWrapper,
    Image
} from './ChatMessage.styles';

function renderAvatar(avatar, isOwnMessage) {
    const margin = isOwnMessage ? 'left' : 'right';
    if (avatar) {
        return <StyledAvatar size="large" src={avatar} margin={margin} />
    }

    return <StyledAvatar size="large" icon={<UserOutlined />} margin={margin} />
}

export const ChatMessage = ({ avatar, timestamp, messageObj }) => {
    const { provider } = useCaseViewContext()
    const messageAuthor = messageObj.author || messageObj.chatOwnerDetails?._id
    const isOwnMessage = messageObj.isOwnMessage || messageAuthor === provider.userId

    const authorName = isOwnMessage
        ? 'You'
        : messageObj.chatOwnerDetails 
            ? `${messageObj.chatOwnerDetails.firstName} ${messageObj.chatOwnerDetails.lastName}`
            : messageObj.authorName || 'Patient'

    return (
        <Msg 
            align={isOwnMessage ? 'right' : 'left'}
            key={timestamp}>
            {renderAvatar(avatar, isOwnMessage)}
            <MsgBubble isOwnMessage={isOwnMessage}>
                <MsgInfo>
                    <MsgInfoName>{authorName}</MsgInfoName>
                    <MsgInfoTime>{displayTimestamp(timestamp)}</MsgInfoTime>
                </MsgInfo>
                {
                    messageObj.key && (
                        <ImageWrapper>
                            <Image src={messageObj.key} alt="uploaded-content-view" />
                        </ImageWrapper>
                    )
                }
                {
                    messageObj.message && (
                        <MsgText>{HTMLReactParser(messageObj.message)}</MsgText>
                    )
                }
            </MsgBubble>
        </Msg>
    );
};