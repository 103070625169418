import React, { useState } from 'react'

import {
    Typography,
    Form,
    Checkbox,
    Select,
    Spin,
} from 'antd'

import { AntTinyWrapper } from "../../../../../../../components/AntTinyWrapper";
import { CaseStatusEnum, FILTER } from '../../../../../../../types/enums';
import { useCaseContext } from '../../../../../context/CaseContext';
import { clearFromAllExtraMessageTemplates } from '../../../../../../../utils/clearFromAllExtraMessageTemplates';
import { useGetFilteredTemplates } from '../../../../../../../hooks/useGetFilteredTemplates';

const { Text } = Typography

const FORM_FIELD = {
  CASE_TEMPLATE: "Case Template",
  EXTRA_MESSAGE_TEMPLATE: "Extra Message Template",
  PATIENT_EXPLANATION_MESSAGE: "Patient Explanation Message",
}

const PatientMessage = ({ form }) => {
    const { _case } = useCaseContext()
    const [sendPatientMessage, setSendPatientMessage] = useState(true)
    const [isExtraMessageTemplate, setIsExtraMessageTemplate] = useState(true)

    const {
        isLoading,
        templateFilter,
        setTemplateFilter,
        extraMessageTemplates,
        filteredCaseTemplates,
        filteredExtraMessageTemplates,
      } = useGetFilteredTemplates({ caseCategoryId: _case?.categoryId, caseStatus: CaseStatusEnum.CONSULT_COMPLETE })

    const handleTemplateSelect = (message) => {
        form.setFieldsValue({
            [FORM_FIELD.PATIENT_EXPLANATION_MESSAGE]: message,
            [FORM_FIELD.EXTRA_MESSAGE_TEMPLATE]: '',
        })
    }

    const onChangeExtraMessageTemplateSelect = message => {
        const patientExplanationField = form.getFieldValue(FORM_FIELD.PATIENT_EXPLANATION_MESSAGE)
        const clearedPatientExplanationField = clearFromAllExtraMessageTemplates(patientExplanationField, extraMessageTemplates)
        const patientExplanationFieldWithExtraMessage = clearedPatientExplanationField + '\n<p>&nbsp;</p>' + message
        form.setFieldsValue({ [FORM_FIELD.PATIENT_EXPLANATION_MESSAGE]: patientExplanationFieldWithExtraMessage })
    }

    const toggleSendPatientMessage = event => {
        !event.target.checked && form.setFieldsValue({
            [FORM_FIELD.PATIENT_EXPLANATION_MESSAGE]: '',
            [FORM_FIELD.CASE_TEMPLATE]: '',
            [FORM_FIELD.EXTRA_MESSAGE_TEMPLATE]: '',
        })
        setSendPatientMessage(event.target.checked)
    }

    const toggleIsExtraMessageTemplate = (event) => {
        if (!event.target.checked) {
            const patientExplanationField = form.getFieldValue(FORM_FIELD.PATIENT_EXPLANATION_MESSAGE)
            const clearedPatientExplanationField = clearFromAllExtraMessageTemplates(patientExplanationField, extraMessageTemplates)
            form.setFieldsValue({
                [FORM_FIELD.PATIENT_EXPLANATION_MESSAGE]: clearedPatientExplanationField,
                [FORM_FIELD.EXTRA_MESSAGE_TEMPLATE]: '',
            })
        }
        setIsExtraMessageTemplate(event.target.checked)
    }

    const onChangeTemplateFilter = (value) => {
        setTemplateFilter(value);
        form.setFieldsValue({
          [FORM_FIELD.CASE_TEMPLATE]: "",
          [FORM_FIELD.EXTRA_MESSAGE_TEMPLATE]: "",
        });
      }

    const caseTemplateSelectOptions = [
        { label: 'None', value: '' },
        ...filteredCaseTemplates.map(template => ({ label: template.title, value: template.message })),
    ]
    const extraMessageTemplateSelectOptions = [
        { label: 'None', value: '' },
        ...filteredExtraMessageTemplates.map(template => ({ label: template.title, value: template.message })),
    ]

    return (
        <Spin spinning={isLoading}>
            <Text
                strong
                style={{ display: 'block', marginBottom: '10px'}}
            >
                Patient Message
            </Text>
            <Form.Item onChange={toggleSendPatientMessage}>
                <Checkbox checked={sendPatientMessage}>
                    Send message to patient
                </Checkbox>
            </Form.Item>
            {
                sendPatientMessage && (
                    <>
                        <Form.Item label='Select a patient message template'>
                            <Form.Item>
                                <Select value={templateFilter} onChange={onChangeTemplateFilter}>
                                    <Select.Option value={FILTER.ALL}>Freeform Message</Select.Option>
                                    <Select.Option value={FILTER.SHARED}>Shared Templates</Select.Option>
                                    <Select.Option value={FILTER.MY}>My Templates</Select.Option>
                                    <Select.Option value={FILTER.ADMIN}>Admin Templates</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name={FORM_FIELD.CASE_TEMPLATE}>
                                <Select
                                    placeholder="Select a template"
                                    disabled={templateFilter === FILTER.ALL}
                                    onChange={handleTemplateSelect}
                                    options={caseTemplateSelectOptions} //TODO: add filter logic by filtertype FILTER.ALL etc
                                    defaultOpen
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item onChange={toggleIsExtraMessageTemplate}>
                            <Checkbox checked={isExtraMessageTemplate}>
                                Add extra message
                            </Checkbox>
                        </Form.Item>
                        {isExtraMessageTemplate && (
                            <Form.Item name={FORM_FIELD.EXTRA_MESSAGE_TEMPLATE}>
                                <Select
                                    placeholder="Select an extra message template"
                                    disabled={!isExtraMessageTemplate}
                                    onChange={onChangeExtraMessageTemplateSelect}
                                    options={extraMessageTemplateSelectOptions}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            name={FORM_FIELD.PATIENT_EXPLANATION_MESSAGE}
                            label="Patient explanation"
                            required
                        >
                            <AntTinyWrapper />
                        </Form.Item>
                    </>
                )
            }
        </Spin>
    )
}

export default PatientMessage
