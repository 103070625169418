/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect, createContext } from "react";
import {
  Button,
  Card,
  message,
  Typography,
  Modal,
  Tabs,
  Spin,
  Badge,
} from "antd";
import {
  LeftOutlined,
  LoadingOutlined,
  MessageOutlined,
  PhoneFilled,
  CloseCircleOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { useParams, useHistory } from "react-router-dom";
import styled from 'styled-components'
import "./CaseView.css";
import CasePatientDetails from "./CasePatientDetails";
import CaseReviewRejectionModal from "./components/CaseReviewRejectionModal"
import MIFView from "./MIFView";
import CaseDetails from "./CaseDetails";
import CasePatientHistory from "./CasePatientHistory"
import CasePhysicianNotes from "./components/CasePhysicianNotes/CasePhysicianNotes";
import DocumentView from "./DocumentView";
import CasePerformActions from "./CasePerformActions";
import { userConstants } from "../../constants";
import { Labs } from './Labs'

import {
  PhysicianTagEnum,
  CaseStatusEnum,
  CaseTagEnum,
  RxRenewalStatusEnum,
  ConsultTypeEnum,
  TelemedicineCategoryEnum,
  LabOrderEventEnum
} from "../../types/enums"

import { dateOfBirthFormatter } from "../../utils/dateFormatter";

// import APIs
import PhysicianApi from "../../axios/physician/PhysicianApi";
import ChatRoomAPI from "../../axios/ChatRoomAPI";

import socket from "../../axios/socket";

// import components
import CasePhysicianChat from "./CasePhysicianChat";
import messageStore from "../../redux/message";
import CaseDetailsDraw from "../Messages/CaseDetailsDraw";
import referrals from "../../redux/referrals";
import { useUpdateMarkerMetadataWithUrlParams } from "../../hooks/useUpdateMarkerMetadataWithUrlParams";
import { useCaseDetails } from "../../hooks/useCaseDetails"
import { useAudioCallContext } from "../../context/AudioCallProvider";

const Container = styled.div`
  margin: 0 50px;
  padding: 46px;
`

const ReferButtonGroup = styled.div`
  margin-right: auto;
  margin-left: 20px;
  display: flex;
  gap: 5px;

  * {
    border: none;
  }
`

const { Text } = Typography;
const { TabPane } = Tabs;

const TAB_KEYS = {
  CASE_DETAILS: "1",
  MIF: "2",
  PATIENT_DOCUMENTS: "3",
  PATIENT_HISTORY: "4",
  PHYSICIAN_NOTES: "5",
  ADMIN_SUPPORT: "6",
  LABS: "7",
}

export const CaseContext = createContext({
  caseDetails: {},
  renewals: [],
  latestRxRenewalRequest: null
});

const CaseView = ({
  statusUpdateRequired,
  fetchAssignedCases,
  physicianDetails,
  actions
}) => {
  message.config({
    duration: 3,
  });

  const history = useHistory();
  const { id } = useParams();
  const { caseDetails, setCaseDetails, caseDetailsLoading, fetchCaseDetails } = useCaseDetails(id)

  const audioCall = useAudioCallContext()

  const [caseDetailsDrawCollapse, setCaseDetailsDrawCollapse] = useState(false);

  const [unreadMifCount, setUnreadMifCount] = useState(0)
  const [activeKey, setActiveKey] = useState(TAB_KEYS.CASE_DETAILS);
  const [caseId, setCaseId] = useState(id)
  const [consultStartConfirmationModal, setConsultStartConfirmationModal] = useState(false);
  const [consultationAccepted, setConsultationAccepted] = useState(false)
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [caseChatCount, setCaseChatCount] = useState(0);
  const [unreadNotesCount, setUnreadNotesCount] = useState(0);
  const [physicianCaseOverride, setPhysicianCaseOverride] = useState(false)
  const [renewals, setRenewals] = useState([]);
  const latestRxRenewalRequest = useMemo(() => renewals[0], [renewals]);

  const physicianTags = physicianDetails?.tags?.map(tag => tag.toLowerCase())
  const isReviewerPhysician =  physicianTags?.includes(PhysicianTagEnum.REVIEWER)
  const isAssignedPhysician = physicianDetails?.userId === caseDetails?.assignedPhysician
  const isInternalPhysician = physicianDetails?.employmentStatus === 'internal physicians'
  const otherPhysiciansCase = history?.location?.state?.from === 'other-physicians-cases'
  const latestRequest = caseDetails?.requests?.at(-1)

  const handleConsultStartCancellation = (event) => {
    event.stopPropagation();
    message.warn("Sorry, Please start consultation to move to the case details");
    returnToCases();
  };

  const handleConsultationStartError = (messageString) => {
    message.error(messageString);
    returnToCases();
  };

  const handleCaseAlreadyAccepted = () => {
    let path = '/caselist'

    if (otherPhysiciansCase) {
      path = '/other-physicians-cases'
    } else if (isInternalPhysician) {
      path ='/case-queue'
    }

    history.push(`/dashboard/${userConstants.USER_PHYSICIAN}${path}`)
  }

  const handleConsultStartAccept = (event) => {
    event.stopPropagation();
    const data = {
      caseId: caseDetails.caseId,
      consultationStart: true,
    };
    setModalButtonLoading(true);
    PhysicianApi.startConsultation(data)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          message.success("Successfully started consultation!");
          setConsultStartConfirmationModal(false);
          setModalButtonLoading(false);
          if (fetchAssignedCases && typeof fetchAssignedCases === "function") {
            fetchAssignedCases();
          }
        } else {
          if (data && data.message) {
            handleConsultationStartError(data.message);
          } else {
            handleConsultationStartError("Unable to Start Consult. Please try again later!");
          }
        }
      }).catch((error) => {
        if (error && error.response) {
          const { response } = error;
          if (response && response.data) {
            const { data } = response;
            if (data && data.message) {
              handleConsultationStartError(data.message);
            } else {
              handleConsultationStartError("Error occurred while Starting Consultation. Please try again later");
            }
          } else {
            handleConsultationStartError(
              "Error occured while Starting Consultation. Please try again later"
            );
          }
        } else {
          handleConsultationStartError(
            "Error occured while Starting Consultation. Please try again later"
          );
        }
      });
  };

  const attachRxRequestNotes = async (requests) => {
    const rxRequestNotes = (await PhysicianApi.getRxRequestNotes(caseId)).data.payload;
    const rxRequestNotesMap = new Map(rxRequestNotes.map(note => [`${note.rxRequest.requestType}+${note.rxRequest.createdAt}`, note]));

      return requests?.map(request => {
        request.note = rxRequestNotesMap.get(`${request.requestType}+${request.createdAt}`)?.content || null;
        return request;
      }) || []
  }

  useEffect(() => {
    if (caseDetails && physicianDetails) {
      setRenewals((caseDetails.rxRenewalRequests || []).filter(renewal => renewal.status !== RxRenewalStatusEnum.CANCELED).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      const caseIsCompleted = [CaseStatusEnum.CONSULT_COMPLETE, CaseStatusEnum.CONSULT_COMPLETE_NO_RX].includes(caseDetails.status)
      if (caseDetails.consultationType === "store and forward" && !caseIsCompleted) {
        if (!caseDetails.consultationStart) {
          !otherPhysiciansCase  && setConsultStartConfirmationModal(true);
        } else if (caseDetails.provider._id !== physicianDetails.userId) {
            const caseNeedsReview = caseDetails.status === CaseStatusEnum.REFER && physicianDetails.tags.includes('reviewer')
           !caseNeedsReview && setConsultationAccepted(true)
        }
      }
    }
  }, [caseDetails, physicianDetails])


  const fetchCaseChatCount = (data) => {
    if (data && data.caseId) {
      ChatRoomAPI.getChatList(data).then((res) => {
        if (res.data.payload.chat) {
          const { chat } = res.data.payload;
            chat[0] && chat[0].count && setCaseChatCount(chat[0].count);
        }
      });
    }
  };

  const fetchUnreadNotesCount = async (caseId) => {
    return PhysicianApi.getUnreadNotesCount(caseId).then((res) => setUnreadNotesCount(res.data.count));
  }

  const clearUnreadNotesCount = (caseId) => {
    PhysicianApi.clearUnreadNotesNotification(caseId).then((res) => setUnreadNotesCount(0));
  }

  const goToLabs = () =>setActiveKey(TAB_KEYS.LABS)

  useEffect(() => {
    socket.connect();

    socket.on("physicianCaseOverride", ({ caseId, physicianId } ) => {
      id === caseId && physicianId !== physicianDetails?.userId && setPhysicianCaseOverride(true)
    });

    socket.on('newNote', async ({ caseId }) => {
      if (caseId !== caseDetails.caseId) return;
      const requests = await attachRxRequestNotes([...caseDetails.requests]);
      await fetchUnreadNotesCount(caseId);
      message.info('note from admin received')

      setCaseDetails({ ...caseDetails, requests });
    })

    socket.on('updatedNote', async ({ caseId }) => {
      if (caseId !== caseDetails.caseId) return;
      const requests = await attachRxRequestNotes([...caseDetails.requests]);
      message.info('note from admin updated')

      setCaseDetails({ ...caseDetails, requests });
    })

    socket.on(LabOrderEventEnum.ORDER_PLACED, async ({ caseId }) => {
      if (caseId === id) {
        message.success('Lab order created')
        fetchCaseDetails(caseId)
        setActiveKey(TAB_KEYS.CASE_DETAILS)
      }
    })

    return () => {
      socket.removeAllListeners(["physicianCaseOverride"]);
      socket.removeAllListeners(["newNote"]);
      socket.removeAllListeners(["updatedNote"]);
      socket.removeAllListeners([LabOrderEventEnum.ORDER_PLACED]);
    }
  }, [physicianDetails, caseDetails])

  useEffect(() => {
    if (caseDetails?.caseId) {
      fetchCaseChatCount({ caseId: caseDetails.caseId });
      fetchUnreadNotesCount(caseDetails.caseId);
    }
  }, [caseDetails]);

  useEffect(() => {
    if (caseDetails) {
      const value = window.localStorage.getItem("adminChatKey");
      if (value) {
        setActiveKey("6");
        window.localStorage.removeItem("adminChatKey");
      }
    }
  }, [caseDetails]);

  useEffect(() => {
    if (caseDetails) {
        if (latestRxRenewalRequest) setUnreadMifCount(1);
    }
  }, [caseDetails, unreadMifCount]);

  useUpdateMarkerMetadataWithUrlParams();

  const messageIconClick = (caseId) => {
    history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/messages`);
    actions.setChatCaseId(caseId);
  };

  const returnToCases = () => {
    history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/case-queue`);
  };

  const handleApproveReview = async () => {
    try {
      await actions.approveReferralCase(caseId, physicianDetails)
      message.success("Successfully submitted review to assigned physician");
      fetchCaseDetails(caseId)
    } catch (e) {
      message.error(e.message);
    }
  }

  const handleSelfAssignCase = () => {
    PhysicianApi.selfAssignCase({ caseId: caseDetails.caseId })
      .then(() => fetchCaseDetails(caseDetails.caseId))
      .catch(e =>  message.error(e.response?.data?.message))
  }

    const MIFList = () => {
        if (latestRxRenewalRequest) {
          setUnreadMifCount(1);
        }

        return (
            <Tabs>
                {renewals.map(((renewal, index) => (
                  <TabPane tab={`Renewal MIF: ${renewal.status} (${dateOfBirthFormatter(renewal.createdAt)})`} key={index}>
                      <MIFView answeredQuestions={renewal.mif} />
                  </TabPane>
                )))}
                <TabPane tab={`MIF (${dateOfBirthFormatter(caseDetails.createdDate)})`} key={renewals.length}>
                    <MIFView answeredQuestions={caseDetails.mif} />
                </TabPane>
            </Tabs>
        )
    }

    const handleCaseDetailsView = (caseId) => {
      setCaseDetailsDrawCollapse((previousState) => !previousState);
    };

    const handleCaseDetailsSidebarCollapseChange = () => {
      setCaseDetailsDrawCollapse((previousState) => !previousState);
    };

    const renderLabsTab = caseDetails?.category.name.toLowerCase() === TelemedicineCategoryEnum.TESTOSTERONE

  return (
    <CaseContext.Provider value={{ caseDetails, renewals, latestRxRenewalRequest }}>
    <Container>
      <div className="case-details-case-status-wrapper flex-spacing">
        <Button
          size="large"
          onClick={() => returnToCases(caseDetails?.status)}
        >
          {" "}
          <LeftOutlined />
          Back to Queue
        </Button>
      </div>
      <Spin
        size="large"
        spinning={caseDetailsLoading}
        indicator={<LoadingOutlined spin />}
      >
        {caseDetails ? (
          <>
            <div className="case-card-wrapper">
              <Card
                className="case-view-patient-card-details"
                style={{ width: "100%", border: "unset" }}
              >
                <Tabs
                  activeKey={activeKey}
                  onTabClick={(key) => setActiveKey(key)}
                  className="case-view-patient-card-details-tabs"
                >
                  <TabPane tab="Case Details" key={TAB_KEYS.CASE_DETAILS}>
                    <CaseDetails
                      caseId={caseId}
                      caseDetails={caseDetails}
                      goToLabs={goToLabs}
                      statusUpdateRequired={statusUpdateRequired}
                      fetchCaseDetails={() => fetchCaseDetails(caseId)}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                        <>
                            <span style={{ marginRight: "5px" }}>Medical Intake Form</span>
                            {(unreadMifCount !== 0 && caseDetails.status === 'rx renewal') && <Badge count={1} />}
                        </>
                    }
                    key={TAB_KEYS.MIF}
                   >
                    <div className="case-detailed-view-part">
                      <CasePatientDetails caseDetails={caseDetails} />
                      <hr></hr>
                      <MIFList />
                    </div>
                  </TabPane>
                  <TabPane tab="Patient Documents" key={TAB_KEYS.PATIENT_DOCUMENTS}>
                    <div className="case-detailed-view-part">
                      {caseDetails && (
                        <DocumentView caseDetails={caseDetails} fetchCaseDetails={() => fetchCaseDetails(caseId)} />
                      )}
                    </div>
                  </TabPane>
                  <TabPane tab="Patient History" key={TAB_KEYS.PATIENT_HISTORY}>
                    <div className="case-detailed-view-part">
                      {!!caseDetails?.caseId && (
                        <CasePatientHistory
                          currentCaseId={caseDetails.caseId}
                          patientId={caseDetails.patientId}
                          resetTabs={setActiveKey}
                          setCaseId={setCaseId}
                          setActiveKey={setActiveKey}
                      />
                      )}
                    </div>
                  </TabPane>
                  <TabPane tab={<Badge count={unreadNotesCount} offset={[10, -5]}><span>Physician Notes</span></Badge>} key={TAB_KEYS.PHYSICIAN_NOTES}>
                    <div className="case-detailed-view-part">

                      <CasePhysicianNotes
                        caseId={caseId}
                        clearUnreadNotesCount={clearUnreadNotesCount}
                        caseDetails={caseDetails}
                        fetchCaseDetails={fetchCaseDetails}
                        physicianDetails={physicianDetails}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Admin Support Chat" key={TAB_KEYS.ADMIN_SUPPORT}>
                    <div className="case-detailed-view-part">
                      <CaseDetailsDraw
                        caseId={caseId}
                        caseDetailsDrawCollapse={caseDetailsDrawCollapse}
                        handleCaseDetailsDrawClose={handleCaseDetailsSidebarCollapseChange}
                        />
                      <CasePhysicianChat
                        caseDetails={caseDetails}
                        enableCaseDetailsView={handleCaseDetailsView}
                      />
                    </div>
                  </TabPane>
                  { renderLabsTab && (
                    <TabPane tab="Labs" key={TAB_KEYS.LABS}>
                      <div className="case-detailed-view-part">
                        <Labs caseDetails={caseDetails}/>
                      </div>
                    </TabPane>
                  )}
                </Tabs>
                <div className="case-view-patient-message-icon">
                  { isAssignedPhysician && caseDetails?.consultationType !== ConsultTypeEnum.STORE_AND_FORWARD &&  (
                    <>
                      <Button
                        type="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => Modal.confirm({
                          title: 'Mark as Missed',
                          content: 'Would you like to mark the consultation as missed?',
                          onOk: () => { PhysicianApi.updateAppointmentStatus(caseDetails.caseId, 'missed').then(() => fetchCaseDetails(caseId)) }
                        })}
                      >
                      Mark as Missed
                      </Button>
                      <Button
                        type="primary"
                        icon={<PhoneFilled rotate={180} />}
                        style={{ cursor: "pointer"}}
                        onClick={() => audioCall.initiate(caseDetails.caseId)}
                      >
                        Initiate Call
                      </Button>
                    </>
                  )}
                  {
                    caseDetails.status === CaseStatusEnum.REFER &&
                    caseDetails.tags?.includes(CaseTagEnum.NEEDS_REVIEW) &&
                    isReviewerPhysician &&
                    !isAssignedPhysician && (
                      <ReferButtonGroup>
                        <Button
                          onClick={handleApproveReview}
                          type="primary"
                          style={{ cursor: "pointer"}}
                          icon={<CheckCircleOutlined />}
                        >
                          Confirm Rejection
                        </Button>
                        <Button
                          type="danger"
                          icon={<CloseCircleOutlined />}
                          style={{ cursor: "pointer"}}
                          onClick={() => actions.setCaseReviewModalVisible(true)}
                        >
                          Return to Physician
                        </Button>
                      </ReferButtonGroup>
                    )
                  }
                  {
                    (
                      physicianTags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN)
                      && caseDetails.assignedPhysician !== physicianDetails.userId &&
                      <Button
                        type="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => Modal.confirm({
                          title: 'Take Over Case',
                          content: 'Would you like to take over this case?',
                          onOk: handleSelfAssignCase
                        })}
                      >
                        Take Over Case
                      </Button>
                    )
                  }
                  <Badge count={caseChatCount}>
                    <MessageOutlined
                      style={{ fontSize: "24px", cursor: "pointer", margin: '5px 20px' }}
                      onClick={() => {
                        messageIconClick(caseDetails && caseDetails.caseId);
                      }}
                    />
                  </Badge>
                  <CaseReviewRejectionModal
                    caseId={caseId}
                    fetchCaseDetails={() => fetchCaseDetails(caseId)}
                  />
                </div>
              </Card>
              {
                caseDetails?.provider?._id === physicianDetails?.userId &&
                activeKey !== TAB_KEYS.LABS &&

                  <CasePerformActions
                    caseId={caseDetails?.id}
                    caseTags={caseDetails?.tags}
                    caseStatus={caseDetails?.status}
                    requestStatus={latestRequest?.status}
                    caseCategoryId={caseDetails?.categoryId}
                    caseClientId={caseDetails.clientId}
                    prescriptionStatus={caseDetails?.prescriptionStatus}
                    goToLabs={goToLabs}
                    activeTab={activeKey}
                  />
              }
            </div>
            <Modal
              title="Accept Case"
              okText="Ok"
              closable={false}
              maskClosable={false}
              centered
              visible={consultStartConfirmationModal}
              okButtonProps={{
                loading: modalButtonLoading,
              }}
              cancelButtonProps={{
                loading: modalButtonLoading,
              }}
              onOk={(event) => {
                handleConsultStartAccept(event);
              }}
              onCancel={(event) => {
                handleConsultStartCancellation(event);
              }}
            >
              <Text strong>
                Please confirm that you are accepting this case
              </Text>
            </Modal>
            <Modal
              title="Case Already Accepted"
              okText={() => {
                let text = "Return to case list"
                if (otherPhysiciansCase) {
                  text = "Return to Other Physicians Cases list"
                } else if (isInternalPhysician) {
                  text = "Return to queue"
                }
                return text
              }}
              closable={false}
              maskClosable={false}
              centered
              visible={consultationAccepted}
              cancelButtonProps={{ style: { display: 'none' }}}
              okButtonProps={{ loading: modalButtonLoading }}
              onOk={handleCaseAlreadyAccepted}
            >
              <Text strong>
                This case has already been accepted by another physician.
              </Text>
            </Modal>
            <Modal
              title="Case reassigned"
              okText={(() => {
                let text = "Return To Case List"
                if (otherPhysiciansCase) {
                  text = "Return to Other Physicians Cases List"
                } else if (isInternalPhysician) {
                  text = "Return To Queue"
                }
                return text
              })()}
              closable={false}
              maskClosable={false}
              centered
              visible={physicianCaseOverride}
              cancelButtonProps={{ style: { display: 'none' }}}
              okButtonProps={{ loading: modalButtonLoading }}
              onOk={handleCaseAlreadyAccepted}
            >
              <Text strong>
                This has been reassigned. Taking you back to case queue.
              </Text>
            </Modal>
          </>
        ) : (
          <div className="case-card-wrapper">
            <Card
              className="case-view-patient-card-details"
              style={{ width: "100%", border: "unset" }}
            >
              <div className="align-text-center">
                <Text strong>Please wait! We are loading the Case Details</Text>
              </div>
            </Card>
          </div>
        )}
      </Spin>
    </Container>
    </CaseContext.Provider>
  );
};

const mapStateToProps = state => {
  const {
    caseReviewModalVisible
  } = state.referrals
  const { physicianDetails } = state.physicianDetails

  return {
    caseReviewModalVisible,
    physicianDetails
  }
}

const mapDispatchToProps = dispatch => {
  const {
    approveReferralCase,
    rejectReferralCase,
    setCaseReviewModalVisible
  } = referrals.actions

  const { setChatCaseId } = messageStore.actions

  return {
    actions: bindActionCreators(
      {
        approveReferralCase,
        rejectReferralCase,
        setChatCaseId,
        setCaseReviewModalVisible,
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseView);
