import React from 'react'
import { Skeleton } from 'antd'

import MIFView from '../../../../../caselist/MIFView'
import { SectionHeader } from '../../../SectionHeader'

import { useCaseViewContext } from '../../../../context/CaseViewContext'
import { useMifSelection } from './useMifSelection'

import { Container } from "../../../../Styles"

export const MIF = () => {
    const { caseDetails, activeRequest } = useCaseViewContext()
    const activeMIF = useMifSelection(caseDetails, activeRequest); // Use the custom hook

    return (
        <Container>
            <SectionHeader title="MIF" />
            <Skeleton loading={!caseDetails} paragraph={{ rows: 6 }}>
                <MIFView answeredQuestions={activeMIF} />
            </Skeleton>
        </Container>
    )
}