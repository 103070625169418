import { createContext, useContext } from 'react';

const DispenseUnitContext = createContext();

export const useDispenseUnitContext = () => {
    const context = useContext(DispenseUnitContext);
    if (!context) {
        throw new Error("useDispenseUnitContext must be used within a telemedicineCategoryProvider");
    }
    return context;
}

export const DispenseUnitProvider = ({ children, value }) => {
    return (
        <>
            <DispenseUnitContext.Provider value={{  ...value }}>
                {children}
            </DispenseUnitContext.Provider>
        </>
    );
};
