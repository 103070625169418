import React, { createContext, useContext, useState } from 'react';

export const MenuContext = createContext(null);

export const useToggleMenu = () => {
  return useContext(MenuContext);
}


export const MenuProvider = ({ children }) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <MenuContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  )
}