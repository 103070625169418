import React, { useState } from "react"
import { Tabs } from "antd"

import { LabDocs } from "./components/LabDocs"
import { LabOrderIframe } from "./components/LabOrderIframe"

import {
    RequestStatusEnum,
} from '../../../types/enums'

import { StyledTabs } from './styles'

export const Labs = ({ caseDetails }) => {
    const latestRequest = caseDetails?.requests?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
    const [activeKey, setActiveKey] = useState(latestRequest.status === RequestStatusEnum.PENDING_LAB_ORDER ? "1" : "2")

    return (
        <StyledTabs 
            alignLeft
            activeKey={activeKey}
            onTabClick={(key) => setActiveKey(key)}
        >
            { latestRequest?.status === RequestStatusEnum.PENDING_LAB_ORDER && (
                <Tabs.TabPane tab="Lab Order" key="1">
                    <LabOrderIframe 
                        caseDetails={caseDetails} 
                        latestRequest={latestRequest}
                    /> 
                </Tabs.TabPane>
            )}
            <Tabs.TabPane tab="Lab Documents" key="2">
                <LabDocs requests={caseDetails.requests} />
            </Tabs.TabPane>
        </StyledTabs>
    )
}