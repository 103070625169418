import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd'

import TicketAPI from '../../../../../axios/TicketApi'
import caseTickets from '../../../../../redux/caseTickets'

import { useCaseViewContext } from '../../../context/CaseViewContext'
export const useTickets = ({
    caseDetails,
    setMessages
}) => {
    const dispatch = useDispatch()
    const { provider } = useCaseViewContext()

    const [adminMessages, setAdminMessages] = useState({
        list: [],
        count: 0,
    });

    const fetchMessages = async () => {
        if (!caseDetails) return

        setAdminMessages({
            list: [],
            count: 0
        })

        try {
            const { data } = await TicketAPI.getTicketChat({
                caseId: caseDetails.caseId
            })

            if (!data.success || !data.payload) {
                throw new Error('Error fetching admin support chat')
            }

            const { payload } = data;

            const unreadCount = payload.data?.filter(message => !message.readBy?.includes('physician')).length
            const timestampMessageMapping = payload.data.map(message => ({ ...message, created: message.createAt }))

            setAdminMessages({
                list: timestampMessageMapping.reverse(),
                count: unreadCount
            })
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
    };

    const sendAdminChatMessage = async message => {

        if (!caseDetails) return

        try {
            const payload = {
                caseId: caseDetails.caseId,
                message,
            }

            const { data } = await TicketAPI.sendChatMessage(payload)
            
            if (!data.success) {
                throw new Error("Error sending new admin support message")
            }
            
            payload._id = payload.caseId
            payload.chatOwnerDetails = {}
            payload.chatOwnerDetails._id = provider.userId
            payload.chatOwnerDetails.created = new Date()

            setAdminMessages(prev => ({
                list: [...prev.list, { ...payload, createdAt: new Date() }],
            }))

            setMessages(prev => ({
                ...prev,
                list: [...prev.list, { ...payload, createdAt: new Date() }],
            }))

            dispatch(caseTickets.actions.getCaseTicketNotificationCount())
        } catch (e) {
            message.error(e.reponse?.data?.message || e.message)
        }      
    }

    useEffect(() => {
        if (caseDetails) {
            fetchMessages()
        }
    }, [caseDetails?.caseId, caseDetails?.status])

    return {
        adminMessages, 
        fetchMessages,
        sendAdminChatMessage
    }
}