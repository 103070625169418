import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { message } from 'antd'
import _ from 'lodash'

import { useCaseViewContext } from '../../../../../context/CaseViewContext';

import PhysicianApi from '../../../../../../../axios/physician/PhysicianApi';

export const useBiometrics = ({
    resetAllergies,
    newAllergyQueue,
    getPatientAllergies,
    setEditModalVisible,
}) => {
    const { caseDetails, fetchCaseDetails } = useCaseViewContext()
    const [form] = Form.useForm();
    const [intialValues, setIntialValues] = useState();
    const [loading, setLoading] = useState(false);

    const onSubmitMedicalInformation = () => {
        const value = form.getFieldsValue()
        value.newAllergyQueue = newAllergyQueue

        if (!_.isEqual(value, intialValues)) {
            setLoading(true);
            PhysicianApi.updateMedicalInformation(value, caseDetails.id)
                .then((res) => {
                    const { data } = res;
                    if (data.success) {
                        message.success("Medical information updated successfully !");
                        form.resetFields();
                        setEditModalVisible(false);
                        setLoading(false);
                        resetAllergies()
                        getPatientAllergies(caseDetails.patientId)
                        fetchCaseDetails();
                    } else {
                        message.error(data.payload);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    const { response } = error;
                    setLoading(false);
                    if (response) {
                        const { data } = response;
                        if (data && data.message) {
                            message.error(data.message);
                        } else {
                            message.error(
                                "Unable to update Medical information, Please try again!"
                            );
                        }
                    } else {
                        message.error(
                            "Unable to update Medical information, Please try again!"
                        );
                    }
                });
        } else {
            message.info("Please update value before you submit!");
        }
    };

    const handleCancel = () => {
        resetAllergies()
        setEditModalVisible(false)
    }

    useEffect(() => {
        if (caseDetails) {
            const formData = {
                height: caseDetails?.heightFt * 12 + (Number(caseDetails.heightIn) || 0),
                weight: caseDetails.weight,
                "blood-pressure": caseDetails.bloodPressure,
                medications: caseDetails.medications,
                allergies: caseDetails.allergies
            };
            setIntialValues(formData);
            form.setFieldsValue(formData);
        }

        // eslint-disable-next-line
    }, [caseDetails])

    return {
        form,
        loading,
        handleCancel,
        onSubmitMedicalInformation
    }
}