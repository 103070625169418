import styled from 'styled-components';
import { Avatar } from 'antd';

export const Msger = styled.section`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: 2px solid #ddd;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
`;

export const MsgerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  background: #eee;
  color: #666;
`;

export const MsgerHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const MsgerHeaderOptions = styled.div`
  display: flex;
  align-items: center;
`;

export const MsgerChat = styled.main`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #fcfcfe;
  background-image: url('https://via.placeholder.com/260');
`;

export const Msg = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  &:last-of-type {
    margin: 0;
  }

  ${props => {
      if (props.align === 'right') {
        return `
          flex-direction: row-reverse;
        `
      } 
  }}
`;

export const StyledAvatar = styled(Avatar)`
  width: 40px;
  margin-${props => props.margin}: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`;

export const MsgBubble = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isOwnMessage',
})`
  max-width: calc(90% - 50px);
  padding: 15px;
  border-radius: 15px;
  background: #ececec;

  ${props => {
    if (props.isOwnMessage) {
      return `
        background: #7BAD7E;
        color: #fff;
        border-bottom-right-radius: 0;
      `
    }

    return `
      background: #ececec;
      border-bottom-left-radius: 0;
    `
  }}
`;

export const MsgInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const MsgInfoName = styled.div`
  margin-right: 10px;
  font-weight: bold;
`;

export const MsgInfoTime = styled.div`
  font-size: 0.85em;
`;

export const MsgText = styled.div`
  hyphens: auto;
  & p {
    margin: 0;
  }
`;

export const MsgerInputArea = styled.form`
  display: flex;
  padding: 10px;
  border-top: 2px solid #ddd;
  background: #eee;
`;

export const MsgerInput = styled.input`
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background: #ddd;
  font-size: 1em;
`;

export const MsgerSendBtn = styled.button`
  margin-left: 10px;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;

  &:hover {
    background: rgb(0, 180, 50);
  }
`;

export const ImageWrapper = styled.div`
  width: inherit;
  text-align: center;
  padding: 8px 8px;
`;

export const Image = styled.img`
  width: 68%;
  height: auto;
  cursor: pointer;
`;