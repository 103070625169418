import React from 'react';
import PropTypes from 'prop-types';
import { Call } from "@twilio/voice-sdk";
import { Button, Avatar } from "antd";
import {
  PhoneFilled,
  UserOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Modal from '../Modal';
import MuteButton from './MuteButton';
import Stopwatch from './StopWatch';
import s from './AudioCallModal.module.css';

const join = (...args) => args.join(" ");

const CallButtonBlock = ({ isPausedStopWatch, isLoading, isMuted, toggleMuteCall, onClickEndCall }) => {
  return (
    <>
      <MuteButton isMuted={isMuted} toggleMuteCall={toggleMuteCall} />
      <div className={join(s.centered, s.col)}>
        <Stopwatch pause={isPausedStopWatch} />
      </div>
      <Button
        className={join(s.endCallButton, s.centered)}
        onClick={onClickEndCall}
      >
        {isLoading
          ? <LoadingOutlined style={{ color: 'white', fontSize: 16 }} />
          : <PhoneFilled style={{ color: 'white', fontSize: 18 }} rotate={225} />
        }
        <span>End</span>
      </Button>
    </>
  )
}

const IdleButtonBlock = ({ onClickCall, onClickCancel }) => {
  return (
    <>
      <Button
        className={join(s.cancelButton, s.centered)}
        onClick={onClickCancel}
      >
        Cancel
      </Button>
      <Button
        className={join(s.startCallButton, s.centered)}
        onClick={onClickCall}
      >
        <PhoneFilled style={{ color: 'white', fontSize: 18 }} />
        <span>Call</span>
      </Button>
    </>
  )
}

const PatientInfo = ({ patient }) => {
  const { firstName, lastName, phone, photo } = patient;
  return (
    <>
      <div className={s.patientIcon}>
        {photo
          ? <Avatar src={photo} size={50} />
          : <UserOutlined style={{ color: 'white', fontSize: 30 }} />
        }
      </div>
      <div>
        <h3 className={s.text}>
          {firstName + " " + lastName}
        </h3>
        <p className={s.text}>{phone}</p>
      </div>
    </>
  )
}

const AudioCallModal = ({ onClose, startCall, finishCall, patient, callStatus, isMuted, toggleMuteCall }) => {
  if (!patient) {
    return null;
  }

  const { Pending, Reconnecting, Connecting, Ringing, Open } = Call.State
  const isOnCall = [Pending, Reconnecting, Connecting, Ringing, Open].includes(callStatus);
  const isLoading = [Connecting, Reconnecting, Pending].includes(callStatus);
  const isPausedStopWatch = (callStatus !== Open);

  const onCloseModal = () => {
    onClose();
    finishCall();
  }

  return (
    <Modal
      onClose={onCloseModal}
      contentStyle={{ bottom: "20px", right: "20px" }}
      hasBackDrop={false}
    >
      <div className={join(s.gap, s.centered)}>
        <PatientInfo patient={patient} />
        <div className={join(s.centered, s.gap, s.actionButtonBlock)}>
          {isOnCall ? (
            <CallButtonBlock
              isPausedStopWatch={isPausedStopWatch}
              isLoading={isLoading}
              isMuted={isMuted}
              toggleMuteCall={toggleMuteCall}
              onClickEndCall={finishCall}

            />
          ) : (
            <IdleButtonBlock
              onClickCall={startCall}
              onClickCancel={onCloseModal}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

AudioCallModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  startCall: PropTypes.func.isRequired,
  finishCall: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    photo: PropTypes.string,
  }).isRequired,
  callStatus: PropTypes.oneOf([...Object.values(Call.State), "idle"]).isRequired,
  isMuted: PropTypes.bool.isRequired,
  toggleMuteCall: PropTypes.func.isRequired,
};

export default AudioCallModal;