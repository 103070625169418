import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'

import { useProviderContext } from '../context/ProviderContext'

import TemplatesAPI from "../../../axios/templates/TemplateAPI"
import PhysicianAPI from "../../../axios/physician/PhysicianApi"

export function usePrescriptionTemplates({
    form,
    prescription,
    clearQueuedPrescription,
    saveQueuedPrescription,
    dispenseUnits,
    getDispenseUnitById,
    validateRx,
    setShowPharmacyNotesTextArea,
}) {
    const [templatesLoading, setTemplatesLoading] = useState(false)
    const [selectedTemplate, selectTemplate] = useState(null)
    const [templateOwnerType, selectTemplateOwnerType] = useState('admin')
    const [templates, setTemplates] = useState(null)
    const { provider } = useProviderContext()

    const searchTemplates = useCallback(async ({ ownerId, absoluteFilters = {}, rankedFilters = {} }) => {
        selectTemplate(null)
        setTemplates([])
        setTemplatesLoading(true)

        try {
            const { data: { templates } } = await PhysicianAPI.getTemplatesByCloseness({
                type: 'prescription',
                ownerId,
                absoluteFilters,
                rankedFilters
            })

            const validTemplates = templates.filter(template => {
                return !!template.medication.dispensableDrugId || template.medication.strength?.toLowerCase() === 'compound'
            })

            message.success(`${validTemplates.length} template matches found.`)
            setTemplates(validTemplates)
            return validTemplates
        } catch (e) {
            message.error("Error fetching prescription templates")
            console.log(e)
        } finally {
            setTemplatesLoading(false)
        }
    }, [])

    const preloadTemplate = async () => {
        if (prescription?.requestedMedication) {  
            const { requestedMedication } = prescription
            
            function removeStrengthFromMedicationName(medicationName) {
                const pattern = /\s?\d+\s?(mg|ml|mg\/ml)\b/;
                return medicationName.replace(pattern, '').trimStart();
            }

            function removeStrengthFromQuantity(inputStr) {
                const numericStr = inputStr.replace(/[^\d.]/g, '');
                const numericStrTrimmed = numericStr.trim();
                const numericValue = parseFloat(numericStrTrimmed);
                return numericValue;
            }
            
            const templates = await searchTemplates({
                ownerId: templateOwnerType === 'personal' ? provider.userId : null,
                absoluteFilters: {
                    drugName: removeStrengthFromMedicationName(requestedMedication.drugName),
                    pseudonyms: requestedMedication.drugName,
                },
                rankedFilters: { 
                    pillCount: requestedMedication.pillCount,
                    refills: requestedMedication.refills,
                    strength: requestedMedication.strength
                }
            })

            if (templates?.[0] && clearQueuedPrescription && saveQueuedPrescription) {
                const dispenseUnit = getDispenseUnitById(templates[0].medication.dispenseUnitId)

                const medication = {
                    ...templates[0],
                    ...templates[0].medication,
                    ndc: templates[0].medication.ndc,
                    refills: Number(requestedMedication.refills) || Number(templates[0].medication.refills),
                    daysSupply:Number(requestedMedication.daysSupply) ||  Number(templates[0].medication.daysSupply),
                    schedule: Number(templates[0].schedule || 0),
                    name: templates[0].medication.drugName,
                    quantity: removeStrengthFromQuantity(requestedMedication.pillCount) || removeStrengthFromQuantity(templates[0].medication.pillCount),
                    quantityUnits: dispenseUnit?.Name,
                    providePharmacyNotes: !!templates[0].pharmacyNotes,
                    ...(templates[0].medication.ingredients && { ingredients: templates[0].medication.ingredients })
                }

                form.setFieldsValue(medication)


                saveQueuedPrescription({
                    ...medication,
                    _id: prescription._id,
                    ...(templates[0].medication.ingredients && { ingredients: templates[0].medication.ingredients })
                })

                validateRx()
            } else {
                form.setFieldsValue({
                    refills: Number(requestedMedication.refills),
                    daysSupply: Number(requestedMedication.daysSupply),
                    quantity: removeStrengthFromQuantity(requestedMedication.pillCount),
                })
            }
        }
    }

    const handleSelectTemplate = ({
        template,
        prescriptionId
    }) => {
        if (prescriptionId) {
            clearQueuedPrescription && clearQueuedPrescription(prescriptionId)
            const dispenseUnit = getDispenseUnitById(templates[0].medication.dispenseUnitId)

            const medication = {
                ...template,
                ...template.medication,
                name: template.medication.drugName,
                quantity: template.medication.pillCount,
                quantityUnits: dispenseUnit?.Name,
                providePharmacyNotes: !!template.pharmacyNotes
            }

            selectTemplate(template)
            form.setFieldsValue(medication)
            setShowPharmacyNotesTextArea(medication.providePharmacyNotes)
            saveQueuedPrescription({ ...medication, _id: prescriptionId })
            validateRx()
        }
    }

    const createTemplate = async ({
        title,
        pharmacyNotes,
        directions,
        teleMedicineCategoryIds,
        ...medication
    }) => {
        try {
            const payload = {
                title,
                pharmacyNotes,
                directions,
                teleMedicineCategoryIds,
                medication
            }

            await TemplatesAPI.create('prescription', payload);
            message.success('Template successfully created')
        } catch (e) {
            message.error('Error creating template')
            throw new Error(e)
        }
    }

    useEffect(() => {
        preloadTemplate()
    }, [dispenseUnits, templateOwnerType])

    return {
        selectTemplate,
        selectedTemplate,
        templatesLoading,
        templates,
        templateOwnerType,
        handleSelectTemplate,
        createTemplate,
        selectTemplateOwnerType,
    };
}
