import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TicketApi from "../../axios/TicketApi";

// import components
import AdminPhysicianChatListingComponent from "../../components/AdminPhysicianChatListingComponent/AdminPhysicianChatListingComponent";
import MessageHeaderComponent from "../../components/MessageHeaderComponent/MessageHeaderComponent";
import caseTickets from "../../redux/caseTickets";
import API from "../../axios/physician/PhysicianApi";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CasePhysicianChat = ({ 
  caseDetails, 
  actions,
  enableCaseDetailsView 
}) => {
  const [messageListLoading, setMessageListLoading] = useState(false);
  const [messageList, setMessageList] = useState({data: Array(0), totalCount: 0});
  const [chatRoomId, setChatRoomId] = useState();

  const userEmail = window.localStorage.getItem("useremail");

  const fetchMessageList = (params) => {
    setMessageListLoading(true);
    TicketApi.getTicketChat({ caseId: caseDetails.caseId })
      .then((res) => {
        const { data } = res;
        if (data.success && data.payload) {
          const { payload } = data;
          const messageListData = payload;
          payload.data = payload.data.reverse();
          setMessageList(messageListData);
        }
        setMessageListLoading(false);
      })
      .catch(() => {
        setMessageListLoading(false);
      });
  };

  function getCaseTicketClearNotification(chatRoomId) {
    const params = { ticketId: chatRoomId };
    API.getCaseTicketClearNotifications(params)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          actions.getCaseTicketNotificationCount();
        } else {
          console.log("Unable to clear Notification Count");
        }
      })
      .catch((error) => {
        console.log("Unable to clear Notification Count");
      });
  }

  // handling message sending
  const handleMessageSending = (data) => {

    const messageData = {
      ...data,
      caseId: data.chatRoomId,
      ticketId: data.chatRoomId,
      message: data.message,
    };
      
    TicketApi.sendChatMessage(messageData)
      .then((res) => {
        if (res.data.success) {
          actions.getCaseTicketNotificationCount()        
        }
      })
      .then(() => {
        fetchMessageList()
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          if (response && response.data.message) {
            message.error(response.data.message);
          } else {
            message.error("unable to send Message");
          }
        } else {
          message.error("Unable to send message");
        }
        console.log(
          "=================== Chat Room Error :: Add messages =====================",
          error
        );
      });
  };

  const clearTicketNotifications = () => {
    getCaseTicketClearNotification(chatRoomId);
  };

  useEffect(() => {
    if (messageList) {
      if (messageList.data?.length === 0) {
        setChatRoomId(caseDetails.caseId);
      } else {
        const ticketMessage = messageList.data[0];
        setChatRoomId(ticketMessage.ticketId);
      }
    }// eslint-disable-next-line
  }, [messageList]);

  useEffect(() => {
    if (caseDetails) {
      const params = {
        caseId: caseDetails.caseId,
      };
      fetchMessageList(params);
    }// eslint-disable-next-line
  }, [caseDetails]);

  useEffect(() => {
    if (caseDetails.caseId) {
      fetchMessageList();
    }// eslint-disable-next-line
  }, []);

  const handleHeaderOptionsClick = (option) => {
    enableCaseDetailsView(caseDetails.caseId);
  };

  const subTitle = "Use this feature to chat with the Clinical and Admin support staff for technical issues or Clinical questions related to this case."

  return (
    <>
      <Spin
        size="large"
        wrapperClassName="height-hundred"
        spinning={messageListLoading}
        indicator={<LoadingOutlined spin />}
      >
        <MessageHeaderComponent
          title={caseDetails.name}
          subTitle={subTitle}
          onOptionsClick={handleHeaderOptionsClick}
        />
        <AdminPhysicianChatListingComponent
          roomId={chatRoomId && chatRoomId}
          authorId={userEmail}
          messages={messageList}
          allowImageUpload
          sendMessage={handleMessageSending}
          clearTicketNotifications={clearTicketNotifications}
          className="message-list-height-background"
        />
      </Spin>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  const { getCaseTicketNotificationCount } = caseTickets.actions;

  return {
    actions: bindActionCreators(
      {
        getCaseTicketNotificationCount,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(CasePhysicianChat);