export const formatTenDigitPhoneNumber = phoneNumber => {
    if (!phoneNumber) return null
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
    if (cleaned.length === 10) {
        cleaned = '1' + cleaned
    }

    const match = cleaned.match(/^(\d{1,3})?(\d{3})?(\d{3})(\d{4})$/);

    if (match) {
        const intlCode = (match[1] ? '+' + match[1] + ' ' : '');
        const areaCode = (match[2] ? '(' + match[2] + ') ' : '');
        return [intlCode, areaCode, match[3], '-', match[4]].join('');
    }

    return null;
}