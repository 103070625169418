import React from "react";
import { Button } from "../Styles"

export const StyledButton = ({ 
    size, 
    color, 
    textColor,
    borderColor,
    Icon, 
    style: propStyle,
    children,
    ...rest
}) => {

    function buttonSizer(size) {
        switch(size) {
            case 'small':
                return {
                    height: '25px',
                    width: '25px',
                    'font-size': '16px',
                    padding: '13px',
                    'border-radius': '5px',
                }
            case 'large':
                return {
                    height: '40px',
                    width: '40px',
                    'font-size': '20px',
                    padding: '5px',
                }
            default:
                return {
                    height: '30px',
                    width: '30px',
                    'font-size': '18px',
                    padding: '5px',
                }
        }
    }

    const style = {
        padding: '10px',
        background: color || '#7BAD7E',
        color: textColor || 'white',
        border: borderColor ? `4px solid ${borderColor}` : 'none',
        ...buttonSizer(size)
    }

    const stringifiedstyle = Object.entries(style).map(([key, value]) => `${key}:${value}`).join(';')

    return (
            <Button 
                stringifiedstyle={stringifiedstyle} 
                style={propStyle}
                {...rest}
            >
                { Icon && <Icon style={{ margin: 0, fontSize: style.fontSize }}/>}
                {children}
            </Button>
    )
};