import React, { useEffect, useState } from 'react'
import { Spin, message, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import LabsApi from '../../../../axios/labs/LabsApi'
import CasesAPI from '../../../../axios/case/CasesApi'

import { PDMPResource } from './PDMPResource'

export const LabOrderIframe = ({ caseDetails, latestRequest }) => {
    const [labOrderState, setLabOrderState] = useState({
        url: null,
        loading: false,
        pdmpViewed: false
    })

    const registerPDMPView = () => {
        window.open(caseDetails?.pdmpResource, '_blank');

        CasesAPI.registerPDMPView(caseDetails?.caseId, caseDetails?.pdmpResource)
            .then(() => setLabOrderState(prev => ({ ...prev, pdmpViewed: true })))
            .catch(e => {
                let errorMessage = "Error registering PDMP view"
                if (e.response?.data?.message) {
                    errorMessage += `: ${e.response?.data?.message}`
                }
                message.error(errorMessage)
            })
    }

    useEffect(() => {
        LabsApi.fetchLabOrderIframe(caseDetails?.caseId)
            .then(({ data }) => setLabOrderState({ url: data.url, loading: false }))
            .catch(e => {
                message.error(e.response?.data?.message || 'Error fetching lab order UI')
                setLabOrderState({ url: null, loading: false })
            })
    }, [])

    return (
        <Spin spinning={labOrderState.loading}>
            <div style={{ display: 'flex', flexDirection: 'column', color: '#000' }}>
                <strong>Requested Labs Company: {latestRequest.requestedLabsCompany}</strong>
                {labOrderState.url && <iframe src={labOrderState.url} title="Order Labs" width="100%" height="600px" />}
            </div>
        </Spin>
    )
}