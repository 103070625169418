/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd'

import PrescriptionsApi from '../../../../../../axios/prescription/PrescriptionsApi'

import prescribeMedicine from '../../../../../../redux/prescribeMedicine';
import { useCaseViewContext } from '../../../../context/CaseViewContext';
export const usePharmacy = () => {
    const dispatch = useDispatch()
    const { caseDetails } = useCaseViewContext()

    const { 
        pharmacySearchResults,
        pharmacySpecialtyTypes 
    } = useSelector(state => state.prescribeMedicine)

    const abortController = useRef(null)

    const getPharmacyDetails = async pharmacyId => {
        try {
            dispatch(prescribeMedicine.actions.setPharmacyLoading(true))
            const { data: { pharmacy } } = await PrescriptionsApi.getPharmacybyId(pharmacyId)
            dispatch(prescribeMedicine.actions.setPharmacy(pharmacy))
        } catch (e) {
            message.error('Error fetching pharmacy')
        } finally {
            dispatch(prescribeMedicine.actions.setPharmacyLoading(false))
        }
    }

    const getPharmacySpecialtyTypes = async()  => {
        try {
            const results = (await PrescriptionsApi.getPharmacySpecialtyTypes()).data?.pharmacies
            dispatch(prescribeMedicine.actions.setPharmacySpecialtyTypes(results))
        } catch (e) {
            message.error('Error fetching pharmacy specialtyTypes')
        }
    }

    const searchPharmacies = useCallback(async params => {
        dispatch(prescribeMedicine.actions.setPharmacySearchResultsLoading(true))
        dispatch(prescribeMedicine.actions.setPharmacySearchResults([]))

        abortController.current?.abort()
        abortController.current = new AbortController()

        try {
            const { 
                data: { pharmacies }, 
                config: { body } 
            } = await PrescriptionsApi.searchPharmacies(params)

            if (!abortController.current?.signal || !body?.signal.aborted) {
                dispatch(prescribeMedicine.actions.setPharmacySearchResults(pharmacies))
            }
        } catch (e) {
            console.log(e)
            message.error('Error searching pharmacies')
        } finally {
            dispatch(prescribeMedicine.actions.setPharmacySearchResultsLoading(false))
        }
    }, [])

    const selectPharmacy = useCallback(async name => {
        const newPharmacy = pharmacySearchResults.find(pharmacy => pharmacy.name === name)
        dispatch(prescribeMedicine.actions.setPharmacy(newPharmacy))
        dispatch(prescribeMedicine.actions.setPharmacySearchResults([]))
        dispatch(prescribeMedicine.actions.setShowChangePharmacyModal(false))
    }, [pharmacySearchResults])

    const handleCancelSearch = useCallback(() => {
        dispatch(prescribeMedicine.actions.setPharmacySearchResults([]))
        dispatch(prescribeMedicine.actions.setShowChangePharmacyModal(false))
    }, [])

    useEffect(() => {
        getPharmacySpecialtyTypes()
    }, [])

    useEffect(() => {
        if (caseDetails) {    
            getPharmacyDetails(caseDetails.pharmacyId)
        }
    }, [caseDetails]) 

    return { 
        pharmacySearchResults,
        pharmacySpecialtyTypes,
        searchPharmacies,
        selectPharmacy,
        handleCancelSearch
    }
}