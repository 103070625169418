import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useTreatmentFormContext } from '../TreatmentFormContext'
import { useDispenseUnitContext } from '../../../../context/DispenseUniteContext'

import prescribeMedicine from '../../../../../../../../../../redux/prescribeMedicine'
export function useDispenseUnitSelector({
    prescription,
}) {
    const dispatch = useDispatch()
    const [options, setOptions] = useState([])
    const { form } = useTreatmentFormContext()
    const { dispenseUnits } = useDispenseUnitContext()
    const { medicationSearchState } = useTreatmentFormContext()

    useEffect(() => {
        if (dispenseUnits?.length) {
            setOptions(dispenseUnits.map(unit => (
                {
                    value: unit.StandardDispenseUnitTypeID,
                    label: unit.Name,
                }
            )))
        }
    }, [dispenseUnits])

    useEffect(() => {
        if (medicationSearchState.selectedStrength && prescription?._id) {
            const dispenseUnitId = medicationSearchState.selectedStrength.ingredients
                ? medicationSearchState.selectedStrength.prescribingDefaults?.dispenseUnitId
                : medicationSearchState.selectedStrength.dispenseUnitId

            form.setFieldsValue({ dispenseUnitId })
            
            dispatch(prescribeMedicine.actions.saveQueuedPrescription({ 
                _id: prescription._id, 
                dispenseUnitId 
            }))
        }
    }, [form, medicationSearchState.selectedStrength])

    return { options }
}