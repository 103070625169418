import { useReferrals } from "./hooks/useReferrals";

import { Modal, Input } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

export const CaseReviewRejectionModal = ({
    visible,
    onClose,
}) => {

    const { 
        reviewNotes,
        setReviewNotes,
        rejectReview 
    } = useReferrals()

    return (
        <Modal
            title={<><CloseCircleOutlined style={{ color: 'red', marginRight: '5px'}}/>Return Case To Physician</>}
            visible={visible}
            onOk={rejectReview}
            okText="Return to Physician"
            okType="danger"
            okButtonProps={{ disabled: reviewNotes.length === 0 }}
            onCancel={onClose}
        >
            <Input.TextArea 
                value={reviewNotes}
                placeholder="Reasoning as to why this case should be Rx'd..."
                onChange={({ target }) => setReviewNotes(target.value)}
            />
        </Modal>     
    )
}