import { useState, useEffect } from 'react'

export function useStrengthSelector({
    medicationSearchState,
}) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (medicationSearchState.selectedMedication) {
            const compileOptions = selectedMedication => {
                if (!selectedMedication) return []
                if (selectedMedication.ingredients) return [{ label: 'Compound', value: 'Compound' }]
                return selectedMedication.strengths?.map(strength => ({ label: strength.strength, value: strength.strength }))
            }

            setOptions(compileOptions(medicationSearchState.selectedMedication))
            
        } else {
            setOptions([])
        }
    }, [medicationSearchState.selectedMedication]);

    return { options }
}