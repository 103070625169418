import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

export const useMifSelection = (caseDetails, activeRequest) => {
  const [activeMIF, setActiveMIF] = useState(null);

  useEffect(() => {
    if (!caseDetails || !activeRequest) return;

    const isRxRenewal = activeRequest.requestType?.toLowerCase() === 'rx renewal';
    const isRxChange = activeRequest.requestType?.toLowerCase() === 'rx change';

    const dateOfActiveRequestCreation = moment(activeRequest.createdAt).format('MM/DD/YYYY');

    let requestMIF = activeRequest?.mif;

    if (!requestMIF) {
      if (isRxRenewal) {
        const rxRenewalRequest = caseDetails.rxRenewalRequests?.find(request => {
          const dateRequestWasCreated = moment(request.createdAt).format('MM/DD/YYYY');
          return dateRequestWasCreated === dateOfActiveRequestCreation;
        });

        if (rxRenewalRequest) {
          requestMIF = rxRenewalRequest.mif;
        }
      } else if (isRxChange) {
        const activeRequestIndex = (caseDetails.requests || [])
          .map(request => request._id?.toString())
          .indexOf(activeRequest?._id?.toString());

        const previousRequest = caseDetails?.requests[activeRequestIndex - 1];

        if (previousRequest?.mif) {
          requestMIF = previousRequest.mif;
        }
      } else {
        requestMIF = caseDetails?.mif;
      }
    }

    setActiveMIF(requestMIF);
  }, [caseDetails, activeRequest]);

  return activeMIF;
};