import styled from 'styled-components'
import { Timeline } from "antd";
import { HistoryOutlined } from "@ant-design/icons";

export const StyledClientLogo = styled.img`
    height: 40px;
`;

export const StyledReasonNote = styled.section`
  height: 200px;
  padding: 15px;
  margin: -10px 0px 30px;
  overflow-y: scroll;
  border: 1px solid lightgrey;
`

export const StyledButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  color: #000;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;

  :hover {
    opacity: .7;
  }
`

export const StatusHistoryIcon = styled(HistoryOutlined)`
  margin: 0 5px;
  & :hover {
    cursor: pointer;
  }
`

export const StyledTimeline = styled(Timeline)`
  width: 400px;
  padding-top: 10px !important;
  & .ant-timeline-item-tail {
    border-left: 2px solid rgba(0,0,0,.15) ;
  }

  & .ant-timeline-item:last-of-type {
    padding-bottom: 0;
  }
`

