import React from 'react'
import { Modal, Button, Typography } from 'antd'

const { Text } = Typography

const Detail = ({ header, children, style={} }) => (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between', ...style }}>
        {header && <Text style={{ marginRight: '10px', fontSize: '.75rem', textTransform: 'capitalize' }}>{header}</Text>}
        {children}
    </div>
)

export const CancelPrescriptionModal = ({
    prescription,
    cancelPrescription,
    deselectRx
}) => {

    return (
            <Modal
                title="Cancel Prescription?"
                visible
                width={280}
                onCancel={deselectRx}
                footer={[
                    <Button key="submit" type="danger" onClick={() => cancelPrescription(prescription.prescriptionId)}>Cancel Rx</Button>
                ]}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <Detail header='Drug Name'>
                            <div style={{ width: '100%', fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.name}</div>
                        </Detail>
                        <Detail header='Strength' style={{ flexDirection: 'row'}}>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.strength || 'See above'}</div>
                        </Detail>
                        <Detail header='Quantity' style={{ flexDirection: 'row'}}>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.quantity}</div>
                        </Detail>
                        <Detail header='Refills' style={{ flexDirection: 'row'}}>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.refills}</div>
                        </Detail>
                        <Detail header='Days Supply' style={{ flexDirection: 'row'}}>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.daysSupply}</div>
                        </Detail>
                        <Detail header='Directions'>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.directions}</div>
                        </Detail>
                    </div>
                </div>
            </Modal>
    )
}