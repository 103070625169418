import { createContext, useContext } from 'react';

const TreatmentFormContext = createContext();

export const useTreatmentFormContext = () => {
    const context = useContext(TreatmentFormContext);
    if (!context) {
        throw new Error("useTreatmentFormContext must be used within a TreatmentFormProvider");
    }
    return context;
}

export const TreatmentFormProvider = ({ children, value }) => {
    return (
        <TreatmentFormContext.Provider value={value}>
            {children}
        </TreatmentFormContext.Provider>
    );
};
