import React from 'react'
import moment from 'moment-timezone'

import { StyledTimeline } from './Metadata.styles'

import { CaseStatusColorCodeEnum } from '../../../../../../types/enums'

export const StatusHistoryPopover = props => {
    let { statusHistory } = props
    return (
      <StyledTimeline mode='left'>
        { statusHistory?.length && statusHistory.map(item => (
          <StyledTimeline.Item
            color={CaseStatusColorCodeEnum[item.status?.toLowerCase()] || '#000'}
            style={{ textTransform: 'capitalize'}}
            label={moment(item.createdAt).format('MMM Do YYYY h:mma')}>
              <p>
                <span style={{ fontWeight: 'bold'}}>{item.status}</span>
                <br />
                { item.physicianName && (
                  <span style={{ fontSize: '.8rem'}}>Assigned to: {item.physicianName}</span>
                )}
                { item.reviewedBy && (
                  <>
                    <br />
                    <span style={{ fontSize: '.8rem'}}>Reviewed by: {item.reviewedBy}</span>
                  </> 
                )}
              </p>
          </StyledTimeline.Item>
        ))}
      </StyledTimeline>
    )
  }