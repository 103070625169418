import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { message } from 'antd';

import PatientsAPI from '../../../../../../../axios/patient/PatientApi'

import { useCaseViewContext } from '../../../../../context/CaseViewContext'

export const useCaseHistory = () => {
    const { caseDetails } = useCaseViewContext()
    const [requestHistory, setRequestHistory] = useState(null)
    const history = useHistory();

    const aggregateCaseHistory = (patientCaseHistory) => {
        let allRequests = []

        for (let _case of patientCaseHistory) {
            const mappedRequests = _case.requests.map(request => {

                const prescribed = request.submissions
                    ? request.submissions.length > 0
                        ? 'Prescribed'
                        : 'Not Prescribed'
                    : 'Unavailable'

                return {
                    ...request,
                    caseId: _case._id,
                    teleMedicineCategory: _case.teleMedicineCategory?.name || _case.category?.name || 'Category Unavailable',
                    timestamp: request.completedAt || request.createdAt,
                    status: request.status?.toLowerCase() === 'completed' ? `Completed, ${prescribed}` : _case.status,
                    prescribed: request.submissions
                        ? request.submissions.length > 0
                            ? 'Prescribed'
                            : 'Not Prescribed'
                        : 'Unavailable'
                }
            })

            allRequests.push(...mappedRequests)
        }

        return allRequests.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    }

    const getCaseHistory = async (caseDetails) => {
        try {
            const { data } = await PatientsAPI.getCaseHistory(caseDetails.patientId)
            const { patientCaseHistory } = data
            const aggregatedRequests = aggregateCaseHistory(patientCaseHistory)
            return setRequestHistory(aggregatedRequests)
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
    }

    const navigateToCase = (caseId, requestId) => {
        history.push(`/dashboard/physician/caseview/${caseId}?requestId=${requestId || 'initial'}`);
    };

    useEffect(() => {
        if (caseDetails) {
            getCaseHistory(caseDetails)
        }
    }, [caseDetails])

    return {
        requestHistory,
        navigateToCase
    }
}