import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const useWatch = ({ object, getter, stop = () => {}, interval = 500, defaultValue = "idle" }) => {
  const _defaultValue = typeof object?.[getter] === "function" ? object[getter]() : defaultValue;
  const [value, setValue] = useState(_defaultValue);
  const prevValueRef = useRef(_defaultValue);
  const intervalIdRef = useRef(null);
  const stopRef = useRef(stop);

  const stopWatch = () => {
    clearInterval(intervalIdRef.current);
  };

  useEffect(() => {
    if (typeof object?.[getter] !== "function") {
      return;
    }

    const checkForUpdates = () => {
      const newValue = object[getter]();
      if (newValue !== prevValueRef?.current) {
        prevValueRef.current = newValue;
        setValue(newValue);
      }
      if (typeof stopRef?.current === 'function'){
        stopRef.current(stopWatch, newValue);
      }
    };

    intervalIdRef.current = setInterval(checkForUpdates, interval);

    return stopWatch
  }, [object, getter, interval]);

  return [value, stopWatch];
};

useWatch.propTypes = {
  object: PropTypes.object,
  getter: PropTypes.string,
  stop: PropTypes.func,
  interval: PropTypes.number,
  defaultValue: PropTypes.any,
};
