import React from "react";

import { Header2 } from "../Styles"

export const SectionHeader = ({ title, children }) => {
    return (
        <div style={{ 
                marginBottom: '15px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Header2>{title}</Header2>
            {children}
        </div>
    )
}