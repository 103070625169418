import React from 'react'
import moment from 'moment-timezone'

import { 
    EditOutlined,
    CheckCircleOutlined,
    DeleteOutlined 
} from '@ant-design/icons'

import { Item } from '../../../Item'
import { StyledButton } from '../../../Button'
import { useCaseViewContext } from '../../../../context/CaseViewContext'

import {
    ProviderNoteItem,
    ProviderNoteTitle,
    ProviderNoteContent,
    ProviderNoteFooter
} from './ProviderNote.styles'

export const ProviderNote = ({
    item,
    onSelect,
    onEdit,
    onDelete
}) => {
    const { caseDetails, fetchCaseDetails, provider } = useCaseViewContext()
    const isAuthor = provider?.userId === item.authorId
    const needsConfirmation = item.needsConfirmationBy?.includes(provider.userId) && !item.confirmedBy.includes(provider.userId)

    const handleSelectProviderNote = () => {
        onSelect(item)
    }

    const handleConfirm = async () => {
        await onEdit({ _id: item._id, confirmed: true })
        fetchCaseDetails(caseDetails?._id)
    }

    return (
        <Item
            key={item._id}
            label={moment(new Date(item.updatedDate || item.createdDate)).format('MM/DD/YYYY')}
            value={
                <ProviderNoteItem>
                    <ProviderNoteTitle>{item.title}</ProviderNoteTitle>
                    <ProviderNoteContent>{item.content}</ProviderNoteContent>
                    <ProviderNoteFooter>
                        <strong>{item.authorName}</strong>
                        { 
                            needsConfirmation && (
                                <StyledButton
                                    Icon={CheckCircleOutlined}
                                    text="OK"
                                    key="confirm-button"
                                    note={item}
                                    onClick={handleConfirm}
                                />
                            )
                        }
                        {
                            isAuthor && (
                                <div style={{ display: 'flex', gap: '3px'}}>
                                    <StyledButton
                                        type="primary"
                                        size="small"
                                        Icon={EditOutlined}
                                        onClick={handleSelectProviderNote}
                                    />
                                    <StyledButton
                                        type="primary"
                                        size="small"
                                        color="red"
                                        Icon={DeleteOutlined}
                                        onClick={() => onDelete(item)}
                                    />
                                </div>
                            )
                        }
                    </ProviderNoteFooter>
                </ProviderNoteItem>
            }
        />
    )
}