export const clearFromAllExtraMessageTemplates = (text, templates) => {
  if (!text) {
      return '';
  }
  let textWithoutExtraMessages = text
  templates.map(t => t.message).forEach(templateValue => {
      textWithoutExtraMessages = textWithoutExtraMessages.replaceAll(templateValue, '')
  })
  const textWithoutEmptyParagraphs = textWithoutExtraMessages.replace(/(<p>&nbsp;<\/p>\s*)+$/g, '')
  return textWithoutEmptyParagraphs
}
