import { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { message } from 'antd'

import ProviderAPI from '../../../../../../../axios/physician/PhysicianApi'

import { dateTimeFormatter } from '../../../../../../../utils/dateFormatter'

import { useCaseViewContext } from '../../../../../context/CaseViewContext'

export const useProviderNotes = ({ state, setState }) => {
    const { caseDetails } = useCaseViewContext()

    const [notesState, setNotesState] = useState({
        loading: false,
        entries: null,
        unreadCount: 0,
    })

    const getAllProviderNotes = async () => {
        try {

            setNotesState(prev => ({
                ...prev,
                loading: true
            }))

            const { data } = await ProviderAPI.getNotes(caseDetails.caseId)

            if (!data.success) {
                throw new Error(data?.message || "Unable to fetch Physician notes. Please try again!")
            }

            const { payload } = data;

            const entries = payload
                .map(note => ({
                    ...note,
                    createdDate: dateTimeFormatter(note.createdDate),
                    updatedDate: dateTimeFormatter(note.updatedDate),
                }))
                .sort((a, b) => (new Date(b.updatedDate) || new Date(b.createdDate) - (new Date(a.updatedDate) || new Date(a.createdDate))))

            setNotesState(prev => ({
                ...prev,
                entries
            }))

            setState(prev => ({
                ...prev,
                selectedItem: null,
                modalMode: null
            }))
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        } finally {
            setNotesState(prev => ({
                ...prev,
                loading: false
            }))
        }
    };

    const addProviderNote = async ({ title, content }) => {
        try {
            setNotesState(prev => ({
                ...prev,
                loading: true
            }))

            const { data } = await ProviderAPI.addPhysicianNote(caseDetails.caseId, {
                title,
                content,
            })

            if (!data.success) {
                throw new Error("Unable to add note. Please try again!")
            }

            message.success("Successfully added physician note");

            const entries = [{ ...data.payload, updatedAt: new Date().toISOString() }, ...notesState.entries]

            setNotesState(prev => ({
                ...prev,
                entries,
                loading: false
            }))

            setState(prev => ({
                ...prev,
                selectedItem: null,
                modalMode: null
            }))

        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        } finally {
            setNotesState(prev => ({
                ...prev,
                loading: false
            }))
        }
    };

    const editProviderNote = async note => {
        try {
            setNotesState(prev => ({
                ...prev,
                loading: true
            }))

            const { data } = await ProviderAPI.updatePhysicianNote(note._id, note)

            if (!data.success) {
                throw new Error("Unable to update note. Please try again!")
            }

            const entries = [...notesState.entries]
            const editedNote = entries.find(entry => entry._id === note._id)

            editedNote.title = note.title
            editedNote.content = note.content
            editedNote.updatedDate = moment().format("MM/DD/YYYY hh:mm A");

            setState(prev => ({
                ...prev,
                entries,
                selectedItem: null,
                modalMode: null
            }))

            message.success("Successfully updated physician note");
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        } finally {
            setNotesState(prev => ({
                ...prev,
                loading: false
            }))
        }
    };

    const deleteProviderNote = async (note) => {
        try {
            setNotesState(prev => ({
                ...prev,
                loading: true
            }))

            const { data } = await ProviderAPI.deleteNote(note._id)

            if (!data.success) {
                throw new Error(`Unable to delete ${note.title} note. Please try again!`)
            }

            const entries = notesState.entries.filter(n => n._id !== note._id)

            setNotesState(prev => ({
                ...prev,
                entries,
            }))

            setState(prev => ({
                ...prev,
                selectedItem: null,
                modalMode: null
            }))

            message.success(`Successfully deleted ${note.title} note`);
            return entries
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        } finally {
            setNotesState(prev => ({
                ...prev,
                loading: false
            }))
        }
    };

    const fetchUnreadNotesCount = async () => {
        return ProviderAPI.getUnreadNotesCount(caseDetails.caseId).then(({ data }) => {
            setNotesState(prev => ({
                ...prev,
                unreadCount: data.count
            }))
        });
    }

    const clearUnreadNotesCount = () => {
        ProviderAPI.clearUnreadNotesNotification(caseDetails.caseId).then(() => {
            setNotesState(prev => ({
                ...prev,
                unreadCount: 0
            }))
        });
    }


    useEffect(() => {
        if (caseDetails) {
            fetchUnreadNotesCount()
            getAllProviderNotes()
        }
    }, [caseDetails])

    return {
        notesState,
        addProviderNote,
        editProviderNote,
        deleteProviderNote,
        clearUnreadNotesCount,
    }
}