import styled from 'styled-components'
import { Radio, Tabs } from 'antd'

export const CustomRadioGroup = styled(Radio.Group)`
    .ant-radio-button-wrapper-checked {
    background-color: #7BAD7E !important;
    color: #ffffff !important;
    }
`; 

export const StyledTabs = styled(Tabs)`
    & > div.ant-tabs-bar {
        padding: 0;
    }
`