import { 
    useRef, 
    useEffect, 
} from 'react'

export const useCoupledScroll = () => {

    const PatientRef = useRef(null)
    const RequestRef = useRef(null)

    useEffect(() => {
        const handleRequestScroll = () => {
            if (PatientRef.current) {
                PatientRef.current.scrollTop = RequestRef.current.scrollTop
            }
        }

        const requestRefCurrent = RequestRef.current;

        requestRefCurrent?.addEventListener('scroll', handleRequestScroll);
        
        return () => {
            requestRefCurrent?.removeEventListener('scroll', handleRequestScroll);
        }

    }, [PatientRef, RequestRef])

    return {
        PatientRef,
        RequestRef,
    }
}