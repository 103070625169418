import styled from 'styled-components'
import { Modal } from 'antd'

export const StyledModal = styled(Modal)`
     & .ant-modal-content .ant-modal-body {
        padding: 0;
     }
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`

export const ModalTitle = styled.span`
    text-transform: capitalize;
`

export const ChartingItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: rgba(123, 173, 126, .15);
`