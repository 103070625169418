import styled from 'styled-components'
import { ChartingItem } from './styles'

export const ProviderNoteItem = styled(ChartingItem)`
    background-color: rgba(123, 173, 126, .15);
`

export const ProviderNoteTitle = styled.div`
    color: rgb(123, 173, 126);
    font-weight: bold;
`

export const ProviderNoteContent = styled.div`
    text-transform: none;
`

export const ProviderNoteFooter = styled.div`
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
`