import React from 'react'

import { Metadata } from "./components/Metadata"
import { Biometrics } from "./components/Biometrics"
import { History } from "./components/History"
import { Charting } from "./components/Charting"

import { Module } from "../../Styles"

export const Patient = ({ patientRef }) => {
    return (
        <Module width="30%" ref={patientRef}>
            <Metadata/>
            <Biometrics/>
            <History/>
            <Charting/>
        </Module>
    )
}