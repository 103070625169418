import React from 'react'
import moment from 'moment-timezone'

import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons'

import { Item } from '../../../Item'
import { StyledButton } from '../../../Button'

import { useCaseViewContext } from '../../../../context/CaseViewContext'

import { ChartingItem } from './styles'

import {
    ChartingEntryNote,
    ChartingEntryNoteFooter,
    ButtonGroup
} from './ChartingEntry.styles'

export const ChartingEntry = ({
    item,
    onSelect,
    onDelete
}) => {
    const { provider } = useCaseViewContext()
    const isAuthor = provider?.userId === item.createdBy._id

    const handleDeleteChartingEntry = () => {
        onDelete(item._id)
    }

    const handleSelectChartingEntry = () => {
        onSelect(item)
    }

    return (
        <Item
            key={item._id}
            label={moment(new Date(item.updatedAt)).format('MM/DD/YYYY')}
            value={
                <ChartingItem>
                    <ChartingEntryNote>{item.note}</ChartingEntryNote>
                    <ChartingEntryNoteFooter>
                        <strong>{`${item.createdBy.name.firstName} ${item.createdBy.name.lastName}`}</strong>
                        {isAuthor && (
                            <ButtonGroup>
                                <StyledButton
                                    type="button"
                                    size="small"
                                    color="red"
                                    Icon={DeleteOutlined}
                                    onClick={handleDeleteChartingEntry}
                                />
                                <StyledButton
                                    type="button"
                                    size="small"
                                    Icon={EditOutlined}
                                    onClick={handleSelectChartingEntry}
                                />
                            </ButtonGroup>
                        )}
                    </ChartingEntryNoteFooter>
                </ChartingItem>
            }
        />
    )
}