import React from 'react'

import { StyledTag } from './AllergyTag.styles'

export const AllergyTag = ({
  allergy,
  closable,
  handleDequeueAllergy
}) => {
  return (
    <StyledTag
      key={allergy.name}
      size="large"
      closable={closable}
      onClose={(e) => {
        e.preventDefault();
        handleDequeueAllergy(allergy);
      }}
    >
      {allergy.name}
    </StyledTag>
  )
}