/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Button, Input, Row, Col, Tooltip } from "antd";
import { SendOutlined } from "@ant-design/icons";
import moment from "moment";

//import components
import MessageComponent from "../MessageComponent/MessageComponent";

import isValidUrl from "../../utils/isValidURL";

const { TextArea } = Input;

const AdminPhysicianChatListingComponent = ({
  roomId,
  authorId,
  sendMessage,
  messages,
  isDifferentPlatform,
  className,
  clearTicketNotifications,
}) => {
  const [messageEntered, setMessageEntered] = useState("");
  const messageListingWrapperRef = useRef(null);

  // handling message sending
  const handleMessageSendButtonClick = (event) => {
    event.stopPropagation();
    if (messageEntered) {
      const data = {};
      data.chatRoomId = roomId;
      data.author = authorId;
      data.isWheel = isDifferentPlatform;
      data.message = messageEntered;
      data.type = "text";
      data.key = null;
      sendMessage(data);
      setMessageEntered("");
    } else {
      return false;
    }
  };

  const handleMessageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const handleTextAreaChange = (event) => {
    event.stopPropagation();
    setMessageEntered(event.target.value);
  };

  const handleTextAreaFocus = (event) => {
    event.stopPropagation();
    clearTicketNotifications();
  };

  const scrollToBottom = () => {
    messageListingWrapperRef.current.scrollTop =
      messageListingWrapperRef.current.scrollHeight;
  };

  useEffect(() => {
      scrollToBottom();
  }, [messages]);

  return (
    <>
      <div
        ref={messageListingWrapperRef}
        className={`message-list-messaging ${className}`}
      >
        {messages.data && messages.data.length ? (
          <>
            {messages.data.map((eachMessage) => (
              <MessageComponent
                key={eachMessage._id}
                backgroundColorClass={
                  eachMessage.chatOwnerDetails?.email === authorId
                    ? "grey-background"
                    : "white-background"
                }
                allignmentClass={
                  eachMessage.chatOwnerDetails?.email === authorId
                    ? "message-item-right"
                    : "message-item-left"
                }
                avatarUrl={
                  isValidUrl(eachMessage.chatOwnerDetails.profileImage) &&
                  eachMessage.chatOwnerDetails.profileImage
                }
                name={
                  eachMessage.chatOwnerDetails?.email === authorId
                    ? "You"
                    : eachMessage.chatOwnerDetails.firstName +
                      " " +
                      eachMessage.chatOwnerDetails.lastName +
                      " : " +
                      eachMessage.chatOwnerDetails.type
                }
                time={
                  eachMessage.createAt
                    ? moment(eachMessage.createAt).format("LLL")
                    : moment().format("LLL")
                }
                messageText={eachMessage.message}
                imageUrl={eachMessage.key ? eachMessage.key : null}
                handleMessageClick={handleMessageClick}
              />
            ))}
          </>
        ) : (
          <div className="no-message-section">
            <span>No messages yet</span>
          </div>
        )}
      </div>
      <Row>
        <Col span={22}>
          <TextArea
            value={messageEntered}
            placeholder="Send a message"
            onChange={handleTextAreaChange}
            autoSize={{ minRows: 2, maxRows: 2 }}
            onFocus={handleTextAreaFocus}
          />
        </Col>
        <Col span={2}>
          <Tooltip placement="topLeft" title="Send Message">
            <Button
              type="secondary"
              className="chat-message-button"
              onClick={handleMessageSendButtonClick}
              icon={<SendOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
};

export default AdminPhysicianChatListingComponent;
