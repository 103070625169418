import styled from 'styled-components';
import { Form } from 'antd';

export const StyledForm = styled(Form)`
    padding: 10px 20px;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 5px;
`
