import React from 'react'
import { connect } from "react-redux";

import { useProviderContext } from '../../../../context/ProviderContext'

import { Form, Input } from 'antd'

import RxMismatchCheck from './components/RxMismatchCheck'
import PatientMessage from './components/PatientMessage'

const RejectionReason = () => (
    <div>
        <Form.Item
            label="Rejection Reason (to be provided to reviewing physician)"
            name="rejectionReason"
            required
        >
            <Input.TextArea
                rows={4}
                placeholder="Provide a reason for rejection..."
            />
        </Form.Item>
    </div>
)

const CompleteConsultationForm = ({
    form,
    prescriptions,
}) => {
    const { provider } = useProviderContext()

    const providerNeedsReview = provider?.tags?.includes('needs review')
    const isRxMismatch = prescriptions && !!Object.values(prescriptions)?.find(med => med.mismatch)

    return (
        <Form
            layout="vertical"
            form={form}
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }}
        >
            <RxMismatchCheck isRxMismatch={isRxMismatch}/>
            <PatientMessage form={form}/>
            {!Object.values(prescriptions)?.length && providerNeedsReview && <RejectionReason />}
        </Form>
    )
}

const mapStateToProps = state => {
    const { prescriptions } = state.prescribeMedicine;
    return { prescriptions };
};

export default connect(mapStateToProps)(CompleteConsultationForm)
