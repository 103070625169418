import {
    useEffect,
    useState,
} from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux';

import { useDispenseUnits } from './useDispenseUnits'
import { usePrescriptionTemplates } from './usePrescriptionTemplates'
import { useCaseViewContext } from '../../../../../context/CaseViewContext'

import prescribeMedicine from '../../../../../../../redux/prescribeMedicine';

export function useTreatments() {
    const dispatch = useDispatch();

    const { 
        caseDetails,
        showLabOrderModal,
        setShowLabOrderModal 
    } = useCaseViewContext()

    const { prescriptions } = useSelector(state => state.prescribeMedicine)

    const {
        dispenseUnits,
        dispenseUnitsLoading,
        getDispenseUnitById
    } = useDispenseUnits()

    const { templatizePrescription } = usePrescriptionTemplates()

    const [unTemplatizedPrescriptions, setUnTemplatizedPrescriptions] = useState([])
    const [treatmentModalState, setTreatmentModalState] = useState({ mode: null })
    const [actionCaseStatus, setActionCaseStatus] = useState(null)

    const setTreatmentModalMode = mode => setTreatmentModalState(prev => ({ ...prev, mode }))

    const selectPrescription = prescriptionId => {
        const selectedPrescription = prescriptions[prescriptionId]
        dispatch(prescribeMedicine.actions.selectPrescription(selectedPrescription))
        setTreatmentModalMode('edit')
    }

    const deselectPrescription = () => {
        dispatch(prescribeMedicine.actions.selectPrescription(null))
        setTreatmentModalMode(null)
    }
    
    const removePrescription = prescriptionId => {
        dispatch(prescribeMedicine.actions.removePrescriptionFromQueue(prescriptionId))
    }

    useEffect(() => {
        if (caseDetails) {
            dispatch(prescribeMedicine.actions.resetPrescriptionQueue())
            setUnTemplatizedPrescriptions([])

            const { requests } = caseDetails
            const activeRequest = requests.find(request => request.status?.toLowerCase() === 'pending')

            if (!['COMPLETED', 'COMPLETED_NO_RX'].includes(activeRequest?.status)) {
                const queuedPrescriptionsDict = {}

                const templatizationPromises = activeRequest?.medicines?.map(async med => {
                    const templatizedPrescription = await templatizePrescription(med)

                    if (templatizedPrescription) {
                        queuedPrescriptionsDict[med._id] = templatizedPrescription
                    } else {
                        setUnTemplatizedPrescriptions(prev => ([...prev, med]))
                    }

                    return templatizedPrescription
                })

                Promise.all(templatizationPromises || [])
                    .then(() => dispatch(prescribeMedicine.actions.setPrescriptionQueue(queuedPrescriptionsDict)))
                }
        }
        return () => dispatch(prescribeMedicine.actions.resetPrescriptionQueue());
    }, [caseDetails?._id])

    return {
        dispenseUnits,
        dispenseUnitsLoading,
        getDispenseUnitById,
        treatmentModalState,
        setTreatmentModalState,
        setTreatmentModalMode,
        setShowLabOrderModal,
        showLabOrderModal,
        actionCaseStatus,
        setActionCaseStatus,
        selectPrescription,
        deselectPrescription,
        removePrescription,
        unTemplatizedPrescriptions,
    };
}
