/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Empty, Skeleton } from "antd";
import moment from "moment-timezone";

import { ChatMessage } from "./ChatMessage"

import {
  MessageListContainer,
  Messages
} from './MessageList.styles'

import { useCaseViewContext } from "../../../context/CaseViewContext";

export const MessageList = ({
  messages,
}) => {

  const {
    caseDetails,
  } = useCaseViewContext()

  const messageContainerRef = useRef(null);

  useEffect(() => {
    const { current } = messageContainerRef;
    if (current) {
      current.scrollTop = current.scrollHeight;
    }
  }, [messages]);

  return (
    <Skeleton
      loading={!caseDetails}
      paragraph={{ rows: 50 }}
    >
      <MessageListContainer>
        {
          messages.list?.length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No messages yet"
              style={{ alignSelf: 'center', color: '#999999' }}
            />
          )
        }
        <Messages ref={messageContainerRef}>
          {
            (messages.list || []).map(messageObj => (
                <ChatMessage
                  key={messageObj._id}
                  avatarUrl={null}
                  timestamp={moment(messageObj.created).format("LLL")}
                  messageObj={messageObj}
                />
              )
            )
          }
        </Messages>
      </MessageListContainer>
    </Skeleton>
  );
};