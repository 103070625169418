import React from 'react'

import styled from 'styled-components'
import { Button, Typography } from 'antd'

const { Text } = Typography

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin-bottom: 30px;
    background: rgba(250,250,250);
    padding: 10px 14px;
    box-shadow: 1px 10px 95px -13px rgba(0,0,0,0.31);
`

export const PDMPResource = ({
    isTestosteroneCase,
    pdmpResource,
    registerPDMPView
}) => {

    const handleClick = () => {
        registerPDMPView()
    }

    if (!isTestosteroneCase) return null

    return (
        <Container>
            <Text style={{ fontWeight: 'bolder' }}>PDMP Resource</Text>
            {
                pdmpResource
                    ? <Button style={{ border: 'none', background: 'none', padding: '0' }} onClick={handleClick}>{pdmpResource}</Button>
                    : <Text>Missing PDMP Config for state</Text>
            }
        </Container>
    )
}