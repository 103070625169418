import { createContext, useContext} from 'react';

const SocketContext = createContext(null);

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocketContext must be used within a SocketContextProvider");
  }
  return context;
}

export const SocketContextProvider = ({ children, value }) => {
  return  <SocketContext.Provider value={value}>
    {children}
  </SocketContext.Provider>
}