import React from 'react'
import { AutoComplete } from 'antd'

import { useCaseViewContext } from '../../../../../../../../context/CaseViewContext'
import { required, isControlledSubstance } from '../validation'
import { StyledFormItem } from '../Treatment.Form.styles'
import { VeritasSpinner } from '../../../../../../../../../../components/VeritasSpinner'

import { useTreatmentFormContext } from '../TreatmentFormContext'

export const MedicationSearch = ({
    fetchMedications,
    handleSelectMedication,
}) => {
    const { provider } = useCaseViewContext()
    
    const { 
        medicationSearchState,
        form, 
    } = useTreatmentFormContext()

    const handleSearch = (e) => {
        e.stopPropagation()
        fetchMedications(e.target.value)
    }

    const validateProviderCanRxControlledSubstances = {
        validator: async () => {
            const schedule = form.getFieldValue('schedule');
            if (!!schedule && schedule.toString() !== "0") {
                if (!provider.idVerified) {
                    return Promise.reject(new Error("Provider not authorized to prescribe controlled substances."))
                }
                if (!provider.tfaActivated) {
                    return Promise.reject(new Error("Please activatete TFA before continuing to prescribe controlled substances."))
                }
                if (!provider?.deaNumber) {
                    return Promise.reject(new Error("Please set up a DEA Number within your profile before continuing to prescribe controlled substances."))
                }
            }

            return Promise.resolve()
        }
    };

    return (
        <VeritasSpinner spinning={medicationSearchState.loading}>
            <StyledFormItem
                label="Medication Name"
                name="name"
                required
                rules={[
                    required,
                    validateProviderCanRxControlledSubstances,
                    isControlledSubstance(medicationSearchState.selectedStrength)
                ]}
                onChange={handleSearch}
            >
                <AutoComplete
                    onSelect={handleSelectMedication}
                    placeholder="Search medication name"
                    options={medicationSearchState.matches?.map(match => ({ label: match.name, value: match.name }))}
                />
            </StyledFormItem>
        </VeritasSpinner>

    )
}