import React from 'react'
import { useDispatch } from "react-redux";

import {
    Skeleton, 
    Typography, 
} from 'antd'

import { useCaseViewContext } from '../../../../context/CaseViewContext'

import { SectionHeader } from '../../../SectionHeader'

import { TelemedicineCategoryEnum } from '../../../../../../types/enums';
import prescribeMedicine from '../../../../../../redux/prescribeMedicine';

import { Container } from '../../../../Styles'
import { PDMPLink } from './PDMP.styles'

export const PDMP = () => {
    const { caseDetails } = useCaseViewContext()
    const dispatch = useDispatch();

    const isTestosteroneCase = caseDetails?.category.name.toLowerCase() === TelemedicineCategoryEnum.TESTOSTERONE
    if (!isTestosteroneCase) return null

    const handleClick = () => dispatch(prescribeMedicine.actions.registerPDMPView(caseDetails?._id, caseDetails?.pdmpResource));

    return (
        <Container>
            <Skeleton loading={!caseDetails} paragraph={{ rows: 1 }}>
                <SectionHeader title="PDMP Resource"/>
            </Skeleton>
            <div>
                {
                    caseDetails?.pdmpResource
                        ? <PDMPLink onClick={handleClick}>{caseDetails?.pdmpResource}</PDMPLink>
                        : <Typography.Text>Missing PDMP Config for state</Typography.Text>
                }
            </div>
        </Container>
    )
}