import TicketsAPI from '../../../../../../../axios/TicketApi'
import { message } from 'antd'

export const useTickets = ({ caseId, unTemplatizedPrescriptions }) => {

    const createTickets = async () => {
        for (const medication of unTemplatizedPrescriptions) {
            const ticket = {
                caseId,
                message: `Prescription template for ${medication.drugName} ${medication.dosage} is missing. Please add a new template.`,
            }
    
            try {
                await TicketsAPI.sendChatMessage(ticket)
                message.success('Ticket created for missing Rx template')
            } catch (e) {   
                message.error('Error creating ticket for missing Rx template')
            }  
        }
         
    }
    return { createTickets };
}