import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { useCaseViewContext } from "../../../../../context/CaseViewContext";

import referrals from "../../../../../../../redux/referrals"

export const useReferrals = () => { 
    const dispatch = useDispatch()

    const { 
      caseDetails, 
      provider, 
      fetchCaseDetails 
    } = useCaseViewContext()

    const [reviewNotes, setReviewNotes] = useState('')

    const { caseReviewModalVisible } = useSelector((state) => state.referrals)

    const rejectReview = async () => {
        try {
          await dispatch(referrals.actions.rejectReferralCase(caseDetails?._id, reviewNotes))
          fetchCaseDetails()
        } catch (e) {
          message.error(e.message);
        }
    }

    const approveReview = async () => {
      try {
        await dispatch(referrals.actions.approveReferralCase(caseDetails._id, provider))
        message.success("Successfully submitted review to assigned physician");
        fetchCaseDetails()
      } catch (e) {
        message.error(e.message);
      }
    }

    const showReviewModal = () => dispatch(referrals.actions.setCaseReviewModalVisible(true))
    const hideReviewModal = () => dispatch(referrals.actions.setCaseReviewModalVisible(false))

    return {
        rejectReview,
        approveReview,
        showReviewModal,
        hideReviewModal,
        caseReviewModalVisible,
        reviewNotes,
        setReviewNotes
    }
}