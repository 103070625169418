import types from "./types";
import PrescriptionsApi from '../../axios/prescription/PrescriptionsApi'
import CasesApi from '../../axios/case/CasesApi'
import { message } from 'antd'

const setDispenseUnits = payload => ({
  type: types.SET_DISPENSE_UNITS,
  payload
})

const setPharmacySpecialtyTypes = payload => ({ 
  type: types.SET_PHARMACY_SPECIALTY_TYPES,
  payload
})

const setShowChangePharmacyModal = showChangePharmacyModal => {
  return dispatch => {
    dispatch({
      type: types.SET_SHOW_CHANGE_PHARMACY_MODAL,
      payload: showChangePharmacyModal
    })
  }
}

const setPharmacyLoading = isLoading => {
  return dispatch => {
    dispatch({
      type: types.SET_PHARMACY_LOADING,
      payload: isLoading
    })
  }
}

const setPharmacy = pharmacy => {
  return dispatch => {
    dispatch({
      type: types.SET_PHARMACY,
      payload: pharmacy
    })
  }
}

const setPharmacySearchResultsLoading = isLoading => {
  return dispatch => {
    dispatch({
      type: types.SET_PHARMACY_SEARCH_RESULTS_LOADING,
      payload: isLoading
    })
  }
}

const setPharmacySearchResults = searchResults => {
  return dispatch => {
    dispatch({
      type: types.SET_PHARMACY_SEARCH_RESULTS,
      payload: searchResults
    })
  }
}

const setIsNewDefaultPharmacy = isNewDefaultPharmacy => {
  return dispatch => {
    dispatch({
      type: types.SET_IS_NEW_DEFAULT_PHARMACY,
      payload: isNewDefaultPharmacy
    })
  }
}


const addPrescriptionToQueue = () => {
  return dispatch => {
    dispatch({
      type: types.ADD_PRESCRIPTION_TO_QUEUE
    })
  }
}


const saveQueuedPrescription = payload => {
  return dispatch => {
    dispatch({
      type: types.SAVE_QUEUED_PRESCRIPTION,
      payload,
    })
  }
}

const removePrescriptionFromQueue = payload => {
  return dispatch => {
    dispatch({
      type: types.REMOVE_PRESCRIPTION_FROM_QUEUE,
      payload,
    })
  }
}

const clearQueuedPrescription = index => {
  return dispatch => {
    dispatch({
      type: types.CLEAR_QUEUED_PRESCRIPTION,
      payload: index
    })
  }
}

const resetPrescriptionQueue = () => {
  return dispatch => {
    dispatch({
      type: types.RESET_PRESCRIPTION_QUEUE
    })
  }
}

const setIsRxMismatch = isRxMismatch => {
  return dispatch => {
    dispatch({
      type: types.IS_RX_MISMATCH,
      payload: isRxMismatch
    })
  }
}


const setConsultationCompleted = consultationIsCompleted => {
  return dispatch => {
    dispatch({
      type: types.SET_CONSULTATION_COMPLETED,
      payload: consultationIsCompleted
    })
  }
}

const setAllPrescriptionsValid = allPrescriptionsValid => {
  return dispatch => {
    dispatch({
      type: types.SET_ALL_PRESCRIPTIONS_VALID,
      payload: allPrescriptionsValid
    })
  }
}

const setPrescriptionQueue = payload => {
  return dispatch => {
    dispatch({
      type: types.SET_PRESCRIPTION_QUEUE,
      payload
    })
  }
}

const setAPLookupDictinary = payload => {
  return dispatch => {
    dispatch({
      type: types.SET_AP_MEDICINE_DICTIONARY,
      payload
    })
  }
}

const initPrescriptionQueue = (_case) => {
  return dispatch => {
    const { requests } = _case

    const latestRequest = requests[requests.length - 1] || []
    const queuedPrescriptionsDict = {}

    latestRequest?.medicines?.forEach(med => {
      queuedPrescriptionsDict[med._id] = { _id: med._id, requestedMedication: med, startDate: new Date(), valid: false }
    })

    dispatch(setPrescriptionQueue(queuedPrescriptionsDict))
  }
}

const fetchDispensUnits = () => {
  return dispatch => {
    PrescriptionsApi.getAllAvailableDispenseUnits()
      .then(({ data }) => dispatch(setDispenseUnits(data?.units)))
      .catch(e => {
        message.error('Error fetching dispense units from prescription service')
        console.log(e)
      })
  }
}

const getDispenseUnitById = (dispenseUnits, unitId) => (
  dispenseUnits.find(unit => unit.StandardDispenseUnitTypeID?.toString() === unitId?.toString())
)

const registerPDMPView = (caseId, resourceLink) => {
  return dispatch => {
    window.open(resourceLink, '_blank');

    CasesApi.registerPDMPView(caseId, resourceLink)
      .then(() => dispatch({ type: types.REGISTER_PDMP_VIEW }))
      .catch(e => {
        let errorMessage = "Error registering PDMP view"
        if (e.response?.data?.message) {  
          errorMessage += `: ${e.response?.data?.message}`
        }
        message.error(errorMessage)
      })
  }
}

const completeConsultation = (caseId, payload) => {
  return async dispatch => {
    try {
      await CasesApi.completeConsultation(caseId, payload)
      dispatch(setConsultationCompleted(true))
      dispatch(resetPrescriptionQueue())
    } catch (e) {
      console.error(e)
      throw new Error('Error completing consultation')
    }
  }
}

const getAPMedicineDictionary = () => {
  return async dispatch => {
    try {
      const { data } = await PrescriptionsApi.getRxLookupForComparison()
      dispatch(setAPLookupDictinary(data.medications))
    } catch (e) {
      console.log(e)
      message.error('Error fetching AP medication dictionary')
    }
  }
}

const setProviderPin = pin => {
  return dispatch => {
    dispatch({
      type: types.SET_PROVIDER_PIN,
      payload: pin
    })
  }
}

const setProviderOTPCode = pin => {
  return dispatch => {
    dispatch({
      type: types.SET_PROVIDER_OTP_CODE,
      payload: pin
    })
  }
}

const setUseTFA = useTFA => {
  return dispatch => {
    dispatch({
      type: types.SET_USE_TFA,
      payload: useTFA
    })
  }
}

const evaluateReadyToComplete = physicianDetails => {
  return dispatch => {
    dispatch({
      type: types.EVALUATE_READY_TO_COMPLETE,
      payload: physicianDetails
    })
  }
}
const setVisitNote = (visitNote) => {
  return dispatch => {
    dispatch({
      type: types.SET_VISIT_NOTE,
      payload: visitNote
    })
  }
}

const selectPrescription = (prescription) => {
  return dispatch => {
    dispatch({
      type: types.SELECT_PRESCRIPTION,
      payload: prescription
    })
  }
}


const actions = {
  setPharmacy,
  setPharmacySpecialtyTypes,
  setShowChangePharmacyModal,
  setPharmacyLoading,
  setPharmacySearchResults,
  setPharmacySearchResultsLoading,
  setIsNewDefaultPharmacy,
  addPrescriptionToQueue,
  saveQueuedPrescription,
  removePrescriptionFromQueue,
  clearQueuedPrescription,
  setPrescriptionQueue,
  resetPrescriptionQueue,
  fetchDispensUnits,
  getDispenseUnitById,
  setIsRxMismatch,
  completeConsultation,
  setAllPrescriptionsValid,
  initPrescriptionQueue,
  getAPMedicineDictionary,
  setConsultationCompleted,
  setProviderPin,
  setProviderOTPCode,
  setUseTFA,
  evaluateReadyToComplete,
  setVisitNote,
  registerPDMPView,
  selectPrescription
};

export default actions;
