import React from 'react'
import { useSelector } from "react-redux";

import { useCaseViewContext } from '../../../../../../../../context/CaseViewContext'

import { 
    Form, 
    Input
} from 'antd'

import RxMismatchCheck from './components/RxMismatchCheck'
import PatientMessage from './components/PatientMessage'

const RejectionReason = () => (
    <div>
        <Form.Item
            label="Rejection Reason (to be provided to reviewing physician)"
            name="rejectionReason"
            required
        >
            <Input.TextArea
                rows={4}
                placeholder="Provide a reason for rejection..."
            />
        </Form.Item>
    </div>
)

export const CompleteConsultationForm = ({
    form,
}) => {
    const { provider } = useCaseViewContext()
    const { prescriptions } = useSelector(state => state.prescribeMedicine)
    
    const providerNeedsReview = provider?.tags?.includes('needs review')
    const isRxMismatch = prescriptions && !!Object.values(prescriptions)?.find(med => med.mismatch)

    return (
        <Form
            layout="vertical"
            form={form}
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '0 47px'
            }}
        >
            <RxMismatchCheck isRxMismatch={isRxMismatch}/>
            <PatientMessage form={form}/>
            { !Object.values(prescriptions || {}).length && providerNeedsReview && <RejectionReason />}
        </Form>
    )
}

