import React from 'react'
import { Form, Input, Checkbox } from 'antd'

import { useTreatmentFormContext } from '../TreatmentFormContext'

export const PrescriptionOptions = ({
    showPharmacyNotesTextArea,
    setShowPharmacyNotesTextArea
}) => {

    const { 
        form,
    } = useTreatmentFormContext()

    const suppressFormChangeHandler = e => e.stopPropagation()

    const handlePharmacyNotesCheck = async e => {
        e.persist()
        suppressFormChangeHandler(e)
        if (!showPharmacyNotesTextArea) await form.setFieldsValue({ pharmacyNotes: null })
        setShowPharmacyNotesTextArea(e.target?.checked)
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Item 
                    name="providePharmacyNotes" 
                    valuePropName="checked"
                    onChange={handlePharmacyNotesCheck}
                >
                    <Checkbox>Notes</Checkbox>
                </Form.Item>
                <Form.Item 
                    name="noSubstitutions" 
                    valuePropName="checked"
                    onChange={suppressFormChangeHandler}
                >
                    <Checkbox >No Substitutions</Checkbox>
                </Form.Item>
                <Form.Item 
                    name="isUrgent" 
                    valuePropName="checked"
                    onChange={suppressFormChangeHandler}
                >
                    <Checkbox style={{ marginLeft: 0 }}>Urgent</Checkbox>
                </Form.Item>
                <Form.Item 
                    name="valid" hidden 
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
            </div>
            <Form.Item
                hidden={!showPharmacyNotesTextArea}
                name='pharmacyNotes'
                label='Pharmacy Notes'
            >
                <Input.TextArea
                    showCount
                    name='pharmacyNotes'
                    maxLength={210}
                    placeholder="Limited to 210 characters..."
                />
            </Form.Item>
        </>
    )
}


